import React from "react";

import { Empty, Table } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

function InvestmentSection({ data }) {
  const investmentAccountColumns = [
    {
      title: "Account status",
      dataIndex: "account_status",
    },
    {
      title: "Date opened",
      dataIndex: "date_opened",
      render: (date) => date ? moment(date).format(DATE_FORMAT.FOR_UI_FIRST_MM) : null
    },
    {
      title: "Investment account number",
      dataIndex: "investment_account_num",
    },
    {
      title: "HSBA account number",
      dataIndex: "hsba_account_num",
    },
  ];

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Table
        bordered
        columns={investmentAccountColumns}
        dataSource={data}
        pagination={false}
        scroll={data?.length ? { x: 1200 } : undefined}
        className="compact-table"
      />
    );
  }
}

export { InvestmentSection };
