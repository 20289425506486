import { Collapse as AntCollapse } from "antd";
import { planInformationEmpStep, validateFields } from "../../../../../utils";
import { PanelHeader } from "../../../employer/AddEmployer/PanelHeader";
import { useEffect, useMemo, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useRouter } from "../../../../../utils/router";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeRunoutOptions } from "../../../../../redux/actions";
import useShowSecondSection from "../../../../../hooks/useShowSection";

const { Panel } = AntCollapse;

export const PlanInformation = ({ form, formData, setFormData }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  useShowSecondSection();
  const { employee_draft_id } = useSelector((state) => state.employees);
  useEffect(() => {
    if (!employee_draft_id) {
      router.push('/admin/employee/add/1')
    }
  }, [employee_draft_id])
  const [expandedPanels, setExpandedPanels] = useState(
    planInformationEmpStep.map(({ key }) => key),
  );
  const [plansExt, setPlansExt] = useState([]);
  const plans = form.getFieldValue("plans");

  const filteredPlanInformationStep = useMemo(() => {
    return planInformationEmpStep.filter(
      ({ planKey }) => !planKey || (planKey && plans?.includes(planKey)),
    );
  }, [plans]);

  const { runOutOptions } = useSelector(
    (state) => state.employer,
  );

  const onChange = async (updatedPanels) => {
    if (updatedPanels?.length < expandedPanels.length) {
      setExpandedPanels(updatedPanels);
    } else {
      setExpandedPanels(updatedPanels);
    }
  };
  useEffect(() => {
    if (!runOutOptions.length) {
      dispatch(getEmployeeRunoutOptions())
    }
  }, [runOutOptions])

  return (
    <Collapse
      activeKey={expandedPanels}
      onChange={onChange}
      panels={filteredPlanInformationStep.map(({ content, title, key }) => (
        <Panel header={<PanelHeader label={title} />} key={key}>
          {content({ form, plansExt, setPlansExt, formData, setFormData })}
        </Panel>
      ))}
    />
  );
};
