import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useRouter } from "../../../utils/router/customRouterHook";
import { Link } from "react-router-dom";
import moment from "moment";
import { langs } from "../../../i18n";
import {
  BENEFIT_ACCOUNT_TYPES,
  CONTRIBUTION_STATUSES,
  DATE_FORMAT,
} from "../../../utils/constants";
import {
  showSuccessMessage,
  cancelContribution,
  updateContributionRun,
  getContributionValidationReport,
  getFileUploadUrl,
  uploadFileS3,
  showErrorMessage,
  getFileUploadStatus,
} from "../../../redux/actions";
import {
  capitalizeFirstLetter,
  fixAmountFormat,
  getLocalTimezone,
  renderContrFileStatusTag,
  renderContrStatusLabel,
  renderErrorMessage,
} from "../../../utils";

import { Card, Col, Row, Table, Button, Divider, Modal } from "antd";
import { SpinLoader } from "../../../components/common/SpinLoader";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import {
  ContributionSummary,
  UploadContributionModal,
} from "../../../components/admin/contribution";
import { Typography } from "../../../design-systems";

const columns = [
  {
    title: "Employee Name",
    render: (row) => {
      const fullName = `${row.first_name} ${row.last_name}`;

      return (
        <Link className="label-name" to={"#"}>
          {capitalizeFirstLetter(fullName)}
        </Link>
      );
    },
  },
  {
    title: "Emp. Id",
    dataIndex: "employee_id",
    key: "employee_id",
  },
  {
    title: "SSN",
    dataIndex: "ssn",
    key: "employee_id"
  },
  {
    title: "Tax year",
    dataIndex: "tax_year",
    key: "employee_id",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.tax_year - b.tax_year,
  },
  {
    title: "Employer $",
    dataIndex: "employer_contribution",
    key: "employee_id",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.employer_contribution - b.employer_contribution,
    render: (amount) => fixAmountFormat(amount),
  },
  {
    title: "Employee $",
    dataIndex: "employee_contribution",
    key: "employee_id",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.employee_contribution - b.employee_contribution,
    render: (amount) => fixAmountFormat(amount),
  },
  {
    title: "Total $",
    dataIndex: "total_contr_amt",
    key: "employee_id",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.total_contr_amt - b.total_contr_amt,
    render: (amount) => fixAmountFormat(amount),
  },
  {
    title: "Status",
    key: "employee_id",
    render: (row) => renderContrFileStatusTag(row),
  },
];

function ContributionValidationReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const router = useRouter();

  const [runSummary, setRunSummary] = useState(null);
  const [employeeSummary, setEmployeeSummary] = useState([]);
  const [divisionSummary, setDivisionSummary] = useState([]);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUploaderError, setFileUploaderError] = useState({
    error: false,
    msg: "",
  });

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});

  const id = router.match.params.id;
  const defaultPage = 1;
  const defaultLimit = 10;

  useEffect(() => {
    getReport(defaultPage, defaultLimit);

    // eslint-disable-next-line
  }, []);

  const getReport = (page, limit) => {
    dispatch(
      getContributionValidationReport(id, page, limit, (res) => {
        setRunSummary(res.data.contribution_run_summary);
        setDivisionSummary(res.data.contribution_run_division_summary);
        setEmployeeSummary(res.data.contribution_run_employee_summary);
      }),
    );
  };

  const submitUploadedFile = () => {
    if (!uploadedFile) return;

    const payload = {
      service: "admin-portal",
      module: "contribution",
      type: id ? "contribution_re_run" : "contribution",
      payload: {
        contribution_run_date: runSummary.contribution_run_date,
        contribution_run_name: runSummary.contribution_run_name,
        benefits_account_type: runSummary.benefits_account_type,
        sent_at: String(moment().unix()),
      },
      file_names: [
        uploadedFile?.name
      ]
    }
    if (id) {
      payload.payload.contribution_run_id = id;
    }
    setLoading(true)
    dispatch(getFileUploadUrl(payload, (res) => {
      if (!(res instanceof Error)) {
        if (res?.data?.data?.presigned_url?.length) {
          res?.data?.data?.presigned_url?.forEach(data => {
            const presignedUrl = data.url;
            if (presignedUrl) {
              dispatch(
                uploadFileS3(presignedUrl, res?.xRayTraceId, uploadedFile, (res) => {
                  if (res instanceof Error) {
                    dispatch(showErrorMessage(langs.messages.file_upload_error));
                  } else {
                    pollUploadStatus(data?.file_id, data?.file_name);
                  }
                }),
              );
            }
          });
        }
      }
    }));


    // const reqData = {
    //   contribution_run_details: {
    //     contribution_run_details: {
    //       contribution_run_date: runSummary.contribution_run_date,
    //       contribution_run_name: runSummary.contribution_run_name,
    //       benefits_account_type: runSummary.benefits_account_type,
    //       sent_at: String(moment().unix()),
    //     },
    //   },
    // };

    // dispatch(
    //   updateContributionRun(id, reqData, uploadedFile, (res) => {
    //     if (res instanceof Error) {
    //       setFileUploaderError({
    //         error: true,
    //         msg: langs.messages.please_upload_valid_CSV_file,
    //       });
    //     } else {
    //       setIsModalVisible(false);
    //       dispatch(
    //         showSuccessMessage(
    //           langs.messages.contribution_run_start_validating,
    //         ),
    //       );

    //       history.push({ pathname: "/admin/contributions/run-history" });
    //     }
    //   }),
    // );
  };

  const pollUploadStatus = (fileId, fileName) => {
    const intervalId = setInterval(() => {
      dispatch(getFileUploadStatus(fileId, (res) => {
        if (!(res instanceof Error)) {
          const { status = "", progress_percentage, message } = res?.data?.data?.data;
          setUploadProgress((prev) => ({
            ...prev,
            [fileName]: { status, progress_percentage },
          }));
          if (message?.length) {
            if (status !== "FILE_ACCEPTED") {
              dispatch(showErrorMessage(message));
            }
          }
          if (status === "ERRORED_OUT") {
            clearInterval(intervalId);
            setLoading(false);
            setFileList([])
          }
          if (status === "FILE_REJECTED") {
            clearInterval(intervalId);
            setLoading(false);
            setFileList([]);
            dispatch(showErrorMessage("File is rejected. Please use another file."));
          }
          if (progress_percentage === 100 || status === "FILE_ACCEPTED") {
            clearInterval(intervalId);
            setLoading(false)
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: (status === 'SUCCESS' || status === 'FILE_ACCEPTED') ? 'success' : 'failed',
            }));
            setIntervalId((prev) => {
              const { [fileName]: _, ...rest } = prev;
              return rest;
            });
          } else if (progress_percentage < 100) {
            setUploadStatus((prev) => ({
              ...prev,
              [fileName]: 'in-progress',
            }));
          }
        } else {
          clearInterval(intervalId);
          dispatch(showErrorMessage(langs.messages.file_upload_error));
          setUploadStatus((prev) => ({
            ...prev,
            [fileName]: 'failed',
          }));
          setIntervalId((prev) => {
            const { [fileName]: _, ...rest } = prev;
            return rest;
          });
        }
      }));
    }, 2000);

    setIntervalId((prev) => ({ ...prev, [fileName]: intervalId }));
  };

  const onEdit = () => {
    history.push({
      pathname: `/admin/update-contribution/${id}`,
      state: { runSummary: runSummary },
    });
  };

  const onCancel = () => {
    dispatch(
      cancelContribution(id, (res) => {
        dispatch(showSuccessMessage(langs.messages.contribution_canceled));

        history.push({ pathname: "/admin/contributions/run-history" });
      }),
    );

    setIsCancelModalVisible(false);
  };

  const handleCancel = () => {
    setUploadedFile(null);
    setIsModalVisible(false);
    setFileUploaderError({ error: false, msg: "" });
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const divColumns = [
    {
      title: "Division Name",
      dataIndex: "division_name",
      key: "division_name",
    },
    {
      title: "#Employees",
      dataIndex: "employee_count",
      key: "employee_count",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employee_count - b.employee_count,
    },
    {
      title: "Calculated contribution total",
      dataIndex: "div_contribution_amt_calculated",
      key: "division_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.div_contribution_amt_calculated - b.div_contribution_amt_calculated,
      render: (amount) => fixAmountFormat(amount),
    },
    {
      title: "Status",
      key: "division_id",
      render: (row) => renderContrFileStatusTag(row),
    },
  ];

  const propertyList = [
    {
      title: "Status",
      value: capitalizeFirstLetter(renderContrStatusLabel(runSummary?.status)),
      visible: true,
    },
    {
      title: "Contribution Date",
      value:
        runSummary?.contribution_run_date &&
        `${moment
          .utc(runSummary?.contribution_run_date)
          .local()
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}`,
      visible: true,
    },
    {
      title: "# Employees",
      value: runSummary?.employees_count,
      visible: true,
    },
    {
      title: "Calculated Total",
      value: fixAmountFormat(runSummary?.contribution_amt_calculated),
      visible: true,
    },
    {
      title: "# Error Found",
      value: (
        <div>
          {runSummary?.error_list?.filter((e) => e.type === "error").length}
        </div>
      ),
      visible: true,
    },
    {
      title: "Comments",
      value: (
        <div>
          {runSummary?.error_list?.length
            ? renderErrorMessage(runSummary?.error_list)
            : null}
        </div>
      ),
      visible: true,
    },
  ];

  const contributionRunDetailFilteredColumns = useMemo(() => {
    let filteredColumns = [...columns];

    if (runSummary?.benefits_account_type === BENEFIT_ACCOUNT_TYPES.DCFSA) {
      filteredColumns = filteredColumns.filter(
        (col) => col.dataIndex !== "tax_year",
      );
    }

    return filteredColumns;
  }, [runSummary?.benefits_account_type]);

  useEffect(() => {
    if (uploadStatus[uploadedFile?.name] === "success") {
      dispatch(
        showSuccessMessage(
          langs.messages.contribution_run_start_validating,
        ),
      );

      history.push({ pathname: "/admin/contributions/run-history" });
    }
  }, [uploadStatus])

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card bordered={false} className="data-grid employee-listing-v2">
            <SpinLoader showContent={!!runSummary}>
              <Typography
                label="Contribution run summary"
                variant="subhead-1"
                className="employee-listing-title section-gap"
              />
              {runSummary && (
                <ContributionSummary propertyList={propertyList} />
              )}
              <div className="confirm-contribution-btn-block">
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    {runSummary &&
                      runSummary.status !== CONTRIBUTION_STATUSES.READY && (
                        <Button
                          onClick={() => onEdit()}
                          type="default"
                          className="button-primary"
                        >
                          Edit
                        </Button>
                      )}
                    {runSummary &&
                      runSummary.status ===
                      CONTRIBUTION_STATUSES.INCOMPLETE && (
                        <Button
                          type="default"
                          className="button-primary ml-10"
                          onClick={() => setIsCancelModalVisible(true)}
                        >
                          Cancel
                        </Button>
                      )}
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="text-right">
                      <Button
                        onClick={() => setIsModalVisible(true)}
                        type="default"
                        className="button-secondary mr-10"
                      >
                        Upload File
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider />
              {!!divisionSummary?.length && (
                <h3 className="pl-space">Division Totals</h3>
              )}
              {!!divisionSummary?.length && (
                <Table
                  columns={divColumns}
                  dataSource={
                    Array.isArray(divisionSummary) ? divisionSummary : []
                  }
                  onChange={onChange}
                  bordered
                  scroll={
                    (Array.isArray(divisionSummary) ? divisionSummary : [])
                      ?.length
                      ? { x: 1200 }
                      : undefined
                  }
                  className="upload-csv-result-data division-total-grid"
                />
              )}
              <h3 className="pl-space">Contribution Run Details</h3>
              <Table
                columns={contributionRunDetailFilteredColumns}
                dataSource={
                  Array.isArray(employeeSummary) ? employeeSummary : []
                }
                onChange={onChange}
                pagination={{
                  onChange: (page, limit) => getReport(page, limit),
                  total: runSummary ? runSummary.employees_count : 0,
                  defaultCurrent: defaultPage,
                  defaultPageSize: defaultLimit,
                }}
                bordered
                scroll={
                  (Array.isArray(employeeSummary) ? employeeSummary : [])
                    ?.length
                    ? { x: 1200 }
                    : undefined
                }
                className="upload-csv-result-data contribution-run-details-grid"
              />
            </SpinLoader>
          </Card>

          {/* <UploadContributionModal
            handleCancel={handleCancel}
            submitUploadedFile={submitUploadedFile}
            isModalVisible={isModalVisible}
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            fileUploaderError={fileUploaderError}
            setFileUploaderError={setFileUploaderError}
          /> */}

          <UploadContributionModal
            handleCancel={handleCancel}
            submitUploadedFile={submitUploadedFile}
            isModalVisible={isModalVisible}
            uploadedFile={uploadedFile}
            setFileList={setFileList}
            fileList={fileList}
            loading={loading}
            uploadProgress={uploadProgress}
            setUploadedFile={setUploadedFile}
            fileUploaderError={fileUploaderError}
            setFileUploaderError={setFileUploaderError}
          />

          <Modal
            visible={isCancelModalVisible}
            closable={false}
            okText="Yes"
            cancelText="No"
            onCancel={() => setIsCancelModalVisible(false)}
            onOk={onCancel}
            className="common-modal-style contribution-cancel-modal"
          >
            <div className="mb-15">Do you wish to cancel the contribution?</div>
          </Modal>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { ContributionValidationReport };
