import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css'; // Import Ant Design styles
import { fixAmountFormat } from '../../../../utils';

const columns = [
  {
    title: 'Account type',
    dataIndex: 'accountType',
    key: 'accountType',
  },
  {
    title: 'Account number',
    dataIndex: 'accountNumber',
    key: 'accountNumber',
  },
  {
    title: 'Available balance',
    dataIndex: 'availableBalance',
    key: 'availableBalance',
    render: (amount) => fixAmountFormat(amount),
    align: "right"
  },
  {
    title: 'Total balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (amount) => fixAmountFormat(amount),
    align: "right"
  },
];

const AccountTable = ({ data }) => {
  const dataSource = Object.keys(data).map((accountType) => {
    const account = data[accountType];
    return {
      key: accountType,
      accountType,
      accountNumber: account.account_number,
      availableBalance:
        account.available_balance !== undefined
          ? account.available_balance
          : account.available_notional_balance,
      totalBalance:
        account.total_balance !== undefined
          ? account.total_balance
          : account.total_notional_balance,
    };
  });

  return <Table
    columns={columns}
    dataSource={dataSource}
    pagination={false}
  />;
};

export default AccountTable;
