import React, { useEffect, useState } from "react";

import { Empty } from "antd";
import AccountTable from "./AccountTable";

function AccountDetailsSection({ data }) {
  const [accData, setAccData] = useState(null)

  useEffect(() => {
    let arr = []
    for (let name in data) {
      arr.push({ plan_name: name, ...data[name] })
    }
    setAccData(arr)
  }, [data])


  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <AccountTable data={data} />
    );
  }
}

export { AccountDetailsSection };
