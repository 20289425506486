import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { Button, CheckBox, TextField } from "../../../../../design-systems";
import { showAdditionalContactForm } from "../../../../../redux/actions";
import { mandatory, validatePhoneNumber, validEmail } from "../../../../../utils/validations";

export const PrimaryContact = ({ setAdditionalContact, additionalContact}) => {
  const dispatch = useDispatch()
  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <TextField
          label="Full name"
          placeholder="Enter full name"
          name="full_name"
          rules={[
            mandatory({
              message: "Please enter a valid full name"
            }),
          ]}
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Job title"
          placeholder="Enter job title"
          name="job_title"
          rules={[
            mandatory({
              message: "Please enter a valid job title",
            }),
          ]}
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Phone number"
          placeholder="Enter phone number"
          name="phone_number"
          maxLength={10}
          rules={[
            {
              required: true,
              message: 'Please enter a phone number',
            },
            {
              validator: validatePhoneNumber,
            },
          ]}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onPaste={(event) => {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('Text');

            if (!/^\d+$/.test(pastedText)) {
                event.preventDefault();
            }
        }}
        />
      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Email"
          placeholder="Enter email"
          name="email"
          rules={[
            mandatory({
              message: "Please enter a valid email",
            }),
            validEmail({ message: "Please enter a valid email" })
          ]}
        />
      </Col>

      <Col xs={24} md={24}>
        <CheckBox
          label="This person is authorized to appear on plan documents"
          name="authorized_for_plan_document"
        />

      </Col>

      <Col xs={24} md={24}>
        <CheckBox
          label="Make primary contact a Super Admin"
          name="is_super_admin"
        />
      </Col>

      {!additionalContact && (
        <Col xs={24} md={24}>
          <Button
            className="button-wrapper-child"
            variant="secondary"
            onclickCallback={() =>{
              setAdditionalContact(true)
              dispatch(showAdditionalContactForm(true))
            }}
            label="Add additional contact"
          />
        </Col>
      )}
    </Row>
  )
};
