import React from "react";

import { Empty, Table } from "antd";
import { hideBankingData } from "../../../../utils";

function ExternalAccountSection({ data }) {
  const AccountColumns = [
    {
      title: "Institution name",
      dataIndex: "institution_name",
    },
    {
      title: "Accounts linked",
      dataIndex: "links",
      render: (links) => links?.join(", ")
    },
    {
      title: "Account number",
      dataIndex: "account_number",
      render: (number) => hideBankingData(number)
    },
    {
      title: "Routing number",
      dataIndex: "routing_number",
      render: (number) => hideBankingData(number)
    },
    {
      title: "External user name",
      dataIndex: "plaid_name",
    },
    {
      title: "Type",
      dataIndex: "plaid_type",
    },
    {
      title: "Sub type",
      dataIndex: "plaid_sub_type",
    },
  ];

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Table
        bordered
        columns={AccountColumns}
        dataSource={data}
        pagination={false}
        scroll={data?.length ? { x: 600 } : undefined}
        className="compact-table"
      />
    );
  }
}

export { ExternalAccountSection };
