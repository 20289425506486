import React, { useCallback, useEffect, useState } from "react";
import { Col, Empty, Form, Modal, Row } from "antd";
import { useRouter } from "../../../../../../utils/router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, NoData } from "../../../../../../design-systems";
import { clearFieldErrors, formatAddPlanData } from "../../../../../../utils";
import { addNewPlan, disableGlobalLoader, enableGlobalLoader, getCurrentEmployerDetailsByTab, getEmployeePlanData, getEmployerInfo, showSuccessMessage } from "../../../../../../redux/actions";
import { required } from "../../../../../../utils/validations";
import { NewPlanSteps } from "./NewPlanSteps";
import { SuccessModal } from "../../../../employer";
import { getExpensesTypes } from "../../../../../../redux/actions/expenses.actions";

export const AddNewPlan = ({ open, setOpen, getEmployeeData, plansData }) => {
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const empId = router.match.params.id;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [formData, setFormData] = useState({});
    const [openSuccess, setSuccessOpen] = useState(false)
    const { employerPlanInfo } = useSelector((state) => state.employer);
    const onFinishFailed = (error) => {
    };

    const customValidation = () => {
        if (
            !form.getFieldValue("plans") ||
            form.getFieldValue("plans")?.length < 1
        ) {
            return Promise.reject(
                "Please select at least one plan to continue.",
            );
        }
        return Promise.resolve();
    };

    const OnAddAnother = () => {
        setOpen(true)
        setSuccessOpen(false)
        setFormData({})
        setSelectedCheckboxes([])
        form.resetFields()
    }

    const onValuesChange = useCallback(
        (_, allValues) => {
            clearFieldErrors(form, allValues);
        },
        [form],
    );

    const onFinish = (values) => {
        const apiData = formatAddPlanData(values)
        setLoading(true)
        dispatch(addNewPlan(empId, apiData, (res) => {
            if (res instanceof Error) {
                if (typeof res?.response?.data?.error?.message === "string") {
                    setLoading(false)
                } else {
                    res?.response?.data?.forEach(err => {
                        err?.error?.forEach(codes => {
                            form.setFields([
                                {
                                    name: `${err?.account_type?.toLowerCase()}_${codes?.code}`,
                                    errors: [codes?.message],
                                },
                            ]);
                        })
                    })
                    setLoading(false);
                }
            } else {
                const id = router.match.params.id;
                getEmployeeData(false);
                dispatch(getEmployeePlanData(id, (res) => {
                    if (!(res instanceof Error)) {
                        setOpen(false);
                        setLoading(false);
                        setSuccessOpen(true);
                    }
                }))
            }
        }))
    }

    return (
        <>
            <Modal
                visible={open}
                onCancel={() => {
                    setOpen(false)
                    setFormData({})
                    setSelectedCheckboxes([])
                    form.resetFields()
                }}
                title={<Typography variant="subhead-2" label="Add new plan" />}
                width={700}
                footer=""
                className="employment-information-modal-wrapper"
            >
                <Form
                    className="employment-information-form"
                    layout="vertical"
                    name="basic"
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    onFinishFailed={onFinishFailed}
                >
                    <div className="form-wrapper">
                        <Row gutter={[16, 16]}>
                            {
                                plansData?.length > 0 ?
                                    <Col sm={24}>
                                        <Form.Item
                                            name="plans"
                                            className={`text-field-wrapper ${"large"}`}
                                            label="Select plan"
                                            rules={[{ validator: customValidation, required }]}

                                        >
                                            <NewPlanSteps
                                                plans={plansData || []}
                                                form={form}
                                                planInfo={employerPlanInfo}
                                                formData={formData}
                                                setFormData={setFormData}
                                                selectedCheckboxes={selectedCheckboxes}
                                                setSelectedCheckboxes={setSelectedCheckboxes}
                                            />
                                        </Form.Item>
                                    </Col> :
                                    <div className="d-flex justify-content-center m-auto mb-30">
                                        <Empty
                                            description={<span>No plan available.</span>}
                                        />
                                    </div>
                            }
                        </Row>
                    </div>
                    <div className="footer-wrapper">
                        <Button
                            variant="primary"
                            label="Add plan"
                            htmlType="submit"
                            disabled={!plansData?.length}
                            loading={loading}
                        />
                    </div>
                </Form>
            </Modal>
            <SuccessModal
                page="add-plan"
                form={form}
                onCancel={() => setSuccessOpen(false)}
                open={openSuccess}
                onSecondaryActionClick={() => {
                    setSuccessOpen(false)
                    setFormData({})
                    setSelectedCheckboxes([])
                    form.resetFields()
                    dispatch(getExpensesTypes(empId))
                }}
                plansData={plansData}
                onPrimaryActionClick={OnAddAnother}
                primaryLabel="Add more"
                secondaryLabel="Close"
            />
        </>
    )
}