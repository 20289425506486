import React, { useEffect } from "react";
import { Col, Form, Row } from "antd";
import { Select, TextField } from "../../../../../design-systems";
import { EditableTable } from "../../../../common/EditableTable";
import { mandatory, validateCopaysAmount } from "../../../../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeCopaysCategories } from "../../../../../redux/actions";

export const AddCopays = ({ form }) => {
  const dispatch = useDispatch()
  const plans = form.getFieldValue("plans")
  const copaysCategories = useSelector((state) => state.employer?.copaysCategories)
  useEffect(() => {
    dispatch(getEmployeeCopaysCategories(form))
  }, [plans, dispatch])
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      width: "40%",
      editable: true,
        additionNode: ({ editingKey }) => {
        return (
          <Select
            placeholder="Select category"
            name="add-category"
            options={copaysCategories}
            rules={[mandatory({ message: "Please select a valid category" })]}
            disabled={!!editingKey || !copaysCategories?.length}
          />
        )
      },
      editingNode: () => (
        <Select
          placeholder="Select category"
          name="category"
          options={copaysCategories}
          rules={[mandatory({ message: "Please select a valid category" })]}
        />
      ),
    },
    {
      title: "Copay amount",
      dataIndex: "copay_amount",
      width: "40%",
      editable: true,
      additionNode: ({ editingKey }) => (
        <TextField
          name="add-copay_amount"
          disabled={!!editingKey}
          prefix="$"
          type="number"
          rules={[mandatory({ message: "Please enter copay amount" }), validateCopaysAmount()]}
          placeholder="Enter copay amount"
        />
      ),
      editingNode: ({ editingKey }) => (
        <TextField
          name="copay_amount"
          prefix="$"
          type="number"
          rules={[mandatory({ message: "Please enter copay amount" }), validateCopaysAmount()]}
          placeholder="Enter copay amount"
        />
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col xs={24} md={24}>
        <Form.Item
          shouldUpdate
          name="copays"
          className="add-copay-table-wrapper"
        >
          <EditableTable
            columns={columns}
            data={form.getFieldValue("copays")}
            onDataChange={(copays) => form.setFieldsValue({ copays })}
            copaysCategories={copaysCategories}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
