import React from "react";

import { Empty, Table } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

function CardSection({ data }) {
  const cardColumns = [
    {
      title: "Card ID",
      dataIndex: "card_id",
    },
    {
      title: "Card pan",
      dataIndex: "card_pan",
    },
    {
      title: "Is primary",
      dataIndex: "is_primary",
      render: (value) => (Boolean(value) ? "Yes" : "No"),
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Date issued",
      dataIndex: "date_issued",
      render: (date) => date ? moment(date).format(DATE_FORMAT.FOR_UI_FIRST_MM) : null
    },
    {
      title: "First name",
      dataIndex: "first_name",
    },
    {
      title: "Middle name",
      dataIndex: "middle_name",
    },
    {
      title: "Last name",
      dataIndex: "last_name",
    },
    {
      title: "Is Active",
      dataIndex: "pan_block",
      render: (value) => (!Boolean(value) ? "Yes" : "No"),
    },
    {
      title: "PIN set",
      dataIndex: "pin_set",
      render: (value) => (Boolean(value) ? "Yes" : "No"),
    },
  ];

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Table
        bordered
        columns={cardColumns}
        dataSource={data}
        pagination={false}
        scroll={data?.length ? { x: 1200 } : undefined}
        className="compact-table"
      />
    );
  }
}

export { CardSection };
