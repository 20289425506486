import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { useSelector } from "react-redux";

import { useRouter } from "../../utils/router/customRouterHook";
import { ROLE_CONSTANT } from "../../utils/constants";
import { sideMenuRoutes } from "../../utils/router";
import { IdleTimer } from "../common/IdleTimer";
import { SideBar } from "../common/Sidebar";
import { adminRoutes } from "../../utils/router";
import { useMemo } from "react";

export const AdminLayout = () => {
  const router = useRouter();
  const role = useSelector((state) =>
    state.userDetails && state.userDetails.roleType
      ? state.userDetails.roleType
      : state?.userDetails?.user && state?.userDetails?.user
        ? state?.userDetails?.user?.role
        : ROLE_CONSTANT.ADMIN,
  );

  const [isValidRoute, setIsValidRoute] = useState(true);

  // eslint-disable-next-line
  useEffect(() => {
    let loc = router.pathname.slice(0, router.pathname.lastIndexOf("/"));

    let settingChild = [];

    let currRoute = sideMenuRoutes.filter((el) => {
      if (el.collapse) {
        el.views.forEach((v) => {
          if (
            `${v.layout}${v.path}` === router.pathname ||
            v.childRoutes.includes(loc)
          ) {
            settingChild.push(v);
          }
        });
      } else if (
        `${el.layout}${el.path}` === router.pathname ||
        el.childRoutes.includes(loc)
      ) {
        return el;
      }

      return false;
    });
    let currChildRoute = sideMenuRoutes.filter((el) =>
      el.childRoutes.includes(router.pathname),
    );

    if (currRoute.length) {
      return setIsValidRoute(currRoute[0].roles.includes(String(role)));
    }
    if (currChildRoute.length) {
      return setIsValidRoute(currChildRoute[0].roles.includes(String(role)));
    }
    if (settingChild.length) {
      return setIsValidRoute(settingChild[0].roles.includes(String(role)));
    }
    return setIsValidRoute(false);
  });
  const redirectRoute = useMemo(() => {
    if (role === ROLE_CONSTANT.ZENDA_ADMIN) {
      return "/admin/employers"
    } else {
      return "/admin/dashboard"
    }
  }, [role])
  return (
    <IdleTimer>
      <Layout style={{ minHeight: "100vh" }} className="loggedin-wrapper">
        <SideBar myRoutes={sideMenuRoutes} />
        {isValidRoute ? (
          <Switch>
            {adminRoutes.map((path, index) => (
              <Route key={index} {...path} />
            ))}
          </Switch>
        ) : (
          <Route component={() => <Redirect to={redirectRoute} />} />
        )}
      </Layout>
    </IdleTimer>
  );
};
