import React, { useState } from "react";
import { fixAmountFormat, getLocalTimezone, getTransactionsLabel } from "../../../../utils";

import { Col, Collapse, Empty, Row, Table } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

function TransactionsSection({ data }) {
  const { Panel } = Collapse;
  const renderChargesData = (charges) => {
    if (Array.isArray(charges)) {
      return (
        <>
          <Row>
            <Col xs={12}>
              <b>Account type</b>
            </Col>
            <Col xs={12} align="right">
              <b className="align-right">Charge</b>
            </Col>
          </Row>
          <Row>
            {
              charges?.map(charge => (
                <>
                  <Col xs={12}>
                    {charge?.account_type}
                  </Col>
                  <Col xs={12}>
                    <div className="align-right">{fixAmountFormat(charge?.charge)}</div>
                  </Col>
                </>
              ))
            }
          </Row>
        </>
      )
    } else {
      return null;
    }
  }

  const transactionColumns = [
    {
      title: "Txn timestamp",
      dataIndex: "txn_timestamp",
      render: (date) => date ?
        `${moment
          .utc(date)
          .local()
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right",
      className: "align-amount-fields",
    },
    {
      title: "Heading",
      dataIndex: "heading",
    },
    {
      title: "Subheading",
      dataIndex: "sub_heading",
    },
    {
      title: "Merchant name",
      dataIndex: "merchant_name",
    },
    {
      title: "Classification",
      dataIndex: "classification",
    },
    {
      title: "Charges",
      dataIndex: "charges",
      className: "charges_column",
      render: (charges) => renderChargesData(charges)
    },
    {
      title: "InComm Benefits guarantee",
      dataIndex: "zenda_guarantee",
    },
    {
      title: "Health expense status",
      dataIndex: "health_expense_status",
    },
    {
      title: "Travel expense status",
      dataIndex: "travel_expense_status",
    },
    {
      title: "Eligible amount",
      dataIndex: "hsa_eligible_amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Merchant category code",
      dataIndex: "merchant_category_code",
    },
    {
      title: "Mode",
      dataIndex: "mode",
    },
    {
      title: "Auth timestamp",
      dataIndex: "auth_timestamp",
      render: (date) => date ? `${moment
        .utc(date)
        .local()
        .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Settle timestamp",
      dataIndex: "settle_timestamp",
      render: (date) => date ? `${moment
        .utc(date)
        .local()
        .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
    },
    {
      title: "Expense ID",
      dataIndex: "expense_id",
    },
    {
      title: "TXN for",
      dataIndex: "txn_for",
    },
  ]
  const onChange = (key) => { };
  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <>
       <Collapse
          onChange={onChange}
          className="account-holder mb-15"
        >
          {Object.keys(data)?.length > 0 && Object.keys(data)?.map(key => (
            <Panel header={getTransactionsLabel[key]} key={key}>
              <Table
                bordered
                columns={transactionColumns}
                dataSource={data[key]}
                pagination={false}
                scroll={
                  data?.length ? { x: 1200 } : undefined
                }
                tableLayout="auto"
                className="compact-table mb-15 expenses-section-table"
              />
            </Panel>
          ))}
        </Collapse>
      </>
    );
  }
}

export { TransactionsSection };
