import { Col, Row } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, TextField } from "../../../../../design-systems";
import { showAdditionalContactForm } from "../../../../../redux/actions";
import { mandatory, validatePhoneNumber, validEmail } from "../../../../../utils/validations";

export const AdditionalContact =
  ({
    additionalContact,
    setAdditionalContact,
    form,
  }) => {
    const dispatch = useDispatch()
    const { showAdditionalContact } = useSelector(state => state.employer)
    useEffect(() => {
      setAdditionalContact(showAdditionalContact)
    }, [showAdditionalContact])
    const checkEmailsAndPhone = (rule, value, callback) => {
      const { additional_email, email, phone_number, additional_phone_number } = form.getFieldsValue();
      if (!value) {
        callback()
      } else {
        if (rule.field === "additional_email") {
          if (email === additional_email) {
            callback('Primary and additional email must not be the same.');
          } else {
            callback();
          }
        } else {
          if (phone_number === additional_phone_number) {
            callback('Primary and additional contact must not be the same.');
          } else {
            callback();
          }
        }
      }

    };
    return (
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <TextField
            label="Full name"
            placeholder="Enter full name"
            name="additional_full_name"
            rules={[
              mandatory({
                message: "Please enter a valid full name"
              }),
            ]}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextField
            label="Job title"
            placeholder="Enter job title"
            name="additional_job_title"
            rules={[
              mandatory({
                message: "Please enter a valid job title",
              }),
            ]}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextField
            label="Phone number"
            placeholder="Enter phone number"
            name="additional_phone_number"
            maxLength={10}
            rules={[
              {
                required: true,
                message: 'Please enter a phone number',
              },
              {
                validator: checkEmailsAndPhone
              },
              {
                validator: validatePhoneNumber,
              },
            ]}
            onPaste={(event) => {
              const clipboardData = event.clipboardData || window.clipboardData;
              const pastedText = clipboardData.getData('Text');

              if (!/^\d+$/.test(pastedText)) {
                event.preventDefault();
              }
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextField
            label="Email"
            placeholder="Enter email"
            name="additional_email"
            rules={[
              mandatory({
                message: "Please enter a valid email",
              }),
              {
                validator: checkEmailsAndPhone
              },
              validEmail({ message: "Please enter a valid email" })
            ]}
          />
        </Col>
        {additionalContact && (
          <Col xs={24} md={24}>
            <Button
              className="button-wrapper-child"
              variant="secondary"
              onclickCallback={() => {
                form.setFieldsValue({
                  additional_email: "",
                  additional_phone_number: "",
                  additional_job_title: "",
                  additional_full_name: "",
                });
                dispatch(showAdditionalContactForm(false))
                setAdditionalContact(false);
              }}
              label="Remove additional contact"
            />
          </Col>
        )}
      </Row>
    )
  };
