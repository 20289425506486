import React from "react";
import { useSelector } from "react-redux";
import { downloadEmployeesCsv, downloadContributionsCsv } from "../../utils";

import { Button, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Button as DesignButton,
} from "../../design-systems";

function ContributionInfoTooltip({ runKey }) {
  return (
    <div className="tooltip">
      <span>Use this table format:</span>
      <table>
        <tr>
          <th>{runKey}</th>
          <th>Tax Year</th>
          <th>Employee Contribution</th>
          <th>Employer Contribution</th>
          <th>Benefits Account Type</th>
        </tr>
        <tr>
          {runKey === "SSN" ? <td>XXXXX1234</td> : <td>604XXYYYY</td>}
          <td>YYYY</td>
          <td>00.00</td>
          <td>00.00</td>
          <td>DCFSA</td>
        </tr>
      </table>
      <span>or download an empty template</span>
    </div>
  );
}

function EmployeeInfoTooltip() {
  return (
    <div className="tooltip">
      <span>Download an empty file template</span>
    </div>
  );
}

function DownloadFileTemplateButton({ compact, contribution, employee, employeeList }) {
  const withDivisions =
    useSelector((state) => state.employer.employerDivisions)?.length !== 1;
  const runKey = useSelector(
    (state) => state.employer?.currentEmployer?.data?.contribution_run_key_column,
  );

  if (contribution) {
    return (
      <div className="file-example-download-block">
        <Tooltip
          title={<ContributionInfoTooltip runKey={runKey} />}
          color="white"
          placement="right"
        >
          <Button
            onClick={() => downloadContributionsCsv(runKey)}
            type="button"
            className="button-secondary-s"
          >
            {compact ? <DownloadOutlined /> : "Download template"}
          </Button>
        </Tooltip>
      </div>
    );
  }

  if (employee) {
    return (
      <div className="file-example-download-block">
        <Tooltip
          title={<EmployeeInfoTooltip />}
          color="white"
          placement="bottom"
        >
          <Button
            onClick={() => downloadEmployeesCsv(withDivisions)}
            type="button"
            className="button-secondary-s"
          >
            {compact ? <DownloadOutlined /> : "Download template"}
          </Button>
        </Tooltip>
      </div>
    );
  }

  if (employeeList) {
    return (
      <div className="file-example-download-block">
        <Tooltip
          title={<EmployeeInfoTooltip />}
          color="white"
          placement="bottom"
        >
          <DesignButton
            variant="secondary"
            onclickCallback={() => {
              downloadEmployeesCsv(withDivisions)
            }}
            label="Download template"
          />
           {/* <Button
            onClick={() => downloadEmployeesCsv(withDivisions)}
            type="button"
            className="button-secondary-s"
          >
            {compact ? <DownloadOutlined /> : "Download template"}
          </Button> */}
        </Tooltip>
      </div>
    );
  }

  return null;
}

export { DownloadFileTemplateButton };
