import { EMPLOYEES_CONSTANT } from "../../utils/constants";

const initialState = {
  employeesList: {},
  countries: [],
  coverages: [],
  accountStatuses: [],
  userStatuses: [],
  employmentStatuses: [],
  updateStatus: [],
  uploadedFiles: [],
  SourceStatusType: [],
  employeeDetails: {},
  employeePlans: [],
  success: null,
  employee_draft_id: null,
  selectedDivisionId: null
};

export function employees(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEES_CONSTANT.SET_EMPLOYEES_LIST:
      return { ...state, employeesList: action.payload };
    case EMPLOYEES_CONSTANT.SET_COUNTRIES_LIST:
      return { ...state, countries: action.payload.countries };
    case EMPLOYEES_CONSTANT.SET_COVERAGES_LIST:
      return { ...state, coverages: action.payload.coverages };
    case EMPLOYEES_CONSTANT.SET_ACCOUNT_STATUS:
      return { ...state, accountStatuses: action.payload.acountStatuses };
    case EMPLOYEES_CONSTANT.SET_USER_STATUS:
      return { ...state, userStatuses: action.payload.userStatuses };
    case EMPLOYEES_CONSTANT.SET_EMPLOYEMENT_STATUS:
      return {
        ...state,
        employmentStatuses: action.payload.employmentStatuses,
      };
    case EMPLOYEES_CONSTANT.SET_UPDATE_KYC_STATUS:
      return { ...state, SourceStatusType: action.payload };
    case EMPLOYEES_CONSTANT.SET_SWITCH_EMPLOYER_TYPE:
      return { switchType: action.payload };
    case EMPLOYEES_CONSTANT.SET_UPLOADED_FILES_TYPE:
      return { ...state, uploadedFiles: action.payload };
    case EMPLOYEES_CONSTANT.SAVE_SELECTED_EMPLOYEE_DATA:
      return { ...state, employeeDetails: action.payload }
    case EMPLOYEES_CONSTANT.SAVE_EMPLOYEE_PLANS_DATA:
      return { ...state, employeePlans: action.payload.data }
    case EMPLOYEES_CONSTANT.UPDATE_EMPLOYEE_DATA:
      const empDetails = {
        ...state.employeeDetails || {}
      }
      return {
        ...state,
        employeeDetails: empDetails
      }
    case EMPLOYEES_CONSTANT.SET_EMPLOYEE_ID:
      return { ...state, employee_draft_id: action.payload }
    case EMPLOYEES_CONSTANT.SET_EMPLOYEE_CREATE_SUCCESS:
      return { ...state, success: true }
    case EMPLOYEES_CONSTANT.SET_EMPLOYEE_CREATE_ERROR:
      return { ...state, success: false }
    case EMPLOYEES_CONSTANT.RESET_EMPLOYEES_CREATE_SUCCESS:
      return { ...state, success: null, }
    case EMPLOYEES_CONSTANT.SAVE_SELECTED_DIVISION_ID:
      return { ...state, selectedDivisionId: action.payload }
    case EMPLOYEES_CONSTANT.RESET_EMPLOYEES_DATA:
      return { ...state, employeeDetails: {}, employeesList: [], employeePlans: [], success: false }
    case EMPLOYEES_CONSTANT.RESET_EMPLOYEES_CREATE_DATA:
      return {
        ...state,
        success: null,
        employee_draft_id: null,
        selectedDivisionId: null
      }
    default:
      return state;
  }
}
