import { Collapse as AntCollapse } from "antd";
import { planInformationStep, validateFields } from "../../../../../utils";
import { PanelHeader } from "../PanelHeader";
import { useEffect, useMemo, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useRouter } from "../../../../../utils/router";
import { useSelector } from "react-redux";

const { Panel } = AntCollapse;

export const PlanInformation = ({ form,  formData, setFormData }) => {
  const router = useRouter()
  const { employerId } = useSelector((state) => state.employer)
  useEffect(() => {
    if (!employerId) {
      router.push('/admin/employer/add/1')
    }
  }, [employerId])
  const [expandedPanels, setExpandedPanels] = useState(
    planInformationStep.map(({ key }) => key),
  );
  const [plansExt, setPlansExt] = useState([]);
  const plans = form.getFieldValue("plans");

  const filteredPlanInformationStep = useMemo(() => {
    return planInformationStep.filter(
      ({ planKey }) => !planKey || (planKey && plans?.includes(planKey)),
    );
  }, [plans]);

  const onChange = async (updatedPanels) => {
    if (updatedPanels?.length < expandedPanels.length) {
      // try {
      //   const recentKey = expandedPanels.find(
      //     (key) => !updatedPanels.includes(key),
      //   );

      //   const values = await form?.validateFields(validateFields[recentKey]);
      //   if (Object.keys(values).length) {
      //   }
      // } catch (err) {}
      setExpandedPanels(updatedPanels);
    } else {
      setExpandedPanels(updatedPanels);
    }
  };

  return (
    <Collapse
      activeKey={expandedPanels}
      onChange={onChange}
      panels={filteredPlanInformationStep.map(({ content, title, key }) => (
        <Panel header={<PanelHeader label={title} />} key={key}>
          {content({ form, plansExt, setPlansExt,formData, setFormData })}
        </Panel>
      ))}
    />
  );
};
