import React from "react";

import { Descriptions, Empty } from "antd";

function ProfileSection({ data }) {
  const { Item } = Descriptions;

  const renderAddress = (a) => {
    return a ? (
      <span style={{ whiteSpace: "nowrap" }}>
        {a.address_line_1 && <span>{a.address_line_1}, </span>}
        {a.address_line_2 && <span>{a.address_line_2}, </span>}
        {a.city && <span>{a.city}, </span>}
        {a.state && <span>{a.state} </span>}
        {a.postal_code && <span>{a.postal_code}</span>}
      </span>
    ) : null;
  };

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Descriptions size="small" column={1} bordered>
        <Item label="InComm Benefits account number">{data.zenda_account_num}</Item>
        <Item label="Login email">{data.personal_email}</Item>
        <Item label="Work email">{data.work_email}</Item>
        <Item label="Account Status">{data.account_holder_status}</Item>
        <Item label="User address">{renderAddress(data.user_address)}</Item>
        <Item label="First name">{data.first_name}</Item>
        <Item label="Middle name">{data.middle_name}</Item>
        <Item label="Last name">{data.last_name}</Item>
        <Item label="Phone number">{data.phone_number}</Item>
        <Item label="Employee address">
          {renderAddress(data.employee_address)}
        </Item>
        <Item label="Braze ID">{data.braze_id}</Item>
        <Item label="Presignup hash">{data.presignup_hash}</Item>
      </Descriptions>
    );
  }
}

export { ProfileSection };
