import React from "react";
import { fixAmountFormat } from "../../../../utils";

import { Empty, Table } from "antd";

function ContributionsSection({ data }) {
  const YTDColumns = [
    {
      title: "Contribution year",
      dataIndex: "contribution_year",
    },
    {
      title: "Total contribution",
      dataIndex: "contribution_amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Contribution limit",
      dataIndex: "max_contribution",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
  ];

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Table
        bordered
        columns={YTDColumns}
        dataSource={data}
        pagination={false}
        scroll={data?.length ? { x: 600 } : undefined}
        className="compact-table"
      />
    );
  }
}

export { ContributionsSection };
