import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAccountHolderDetails } from "../../../../redux/actions";

import { SpinLoader } from "../../../common/SpinLoader";
import { KYCSection } from "./KYCSection";
import { CardSection } from "./CardSection";
import { ProfileSection } from "./ProfileSection";
import { ExpensesSection } from "./ExpensesSection";
import { InvestmentSection } from "./InvestmentSection";
import { TransactionsSection } from "./TransactionsSection";
import { ContributionsSection } from "./ContributionsSection";
import { AccountDetailsSection } from "./AccountDetailsSection";
import { ExternalAccountSection } from "./ExternalAccountSection";
import { PendingACHSection } from "./PendingACHSection";

import { Button, Collapse, Typography } from "antd";
import { ExpensesSectionV2 } from "./ExpenseSectionV2";
import { Edit } from "../../../../design-systems";
import { ProfileInformationForm } from "./ProfileForm";
import useShowSecondSection from "../../../../hooks/useShowSection";
import { SuccessModal } from "./SuccessModal";

function AccountHolder({ emailID }) {
  const dispatch = useDispatch();
  const { Panel } = Collapse;

  const [details, setDetails] = useState(null);
  const [editProfileInformation, setEditProfileInformation] = useState(false);
  const [success, setSuccess] = useState(false);
  useShowSecondSection();

  useEffect(() => {
    dispatch(
      getAccountHolderDetails(emailID, (res) => {
        setDetails(res);
      }),
    );

    //eslint-disable-next-line
  }, []);

  const onChange = (key) => { };

  const panelHeader = (
    <React.Fragment>
      <div className="d-flex justify-space-between">
        <div>Profile</div>
        {
          (details?.profile?.account_holder_status === "Activated" || details?.profile?.account_holder_status === "Closed") &&
          <div onClick={event => event.stopPropagation()}><Edit stroke="#296DB2" onClick={() => setEditProfileInformation(true)} className="edit-emp-card" /></div>
        }
      </div>
    </React.Fragment>
  )

  return (
    <SpinLoader>
      <ProfileInformationForm
        data={details?.profile ? {...details.profile, ...details?.mfa} : null}
        visible={editProfileInformation}
        setVisible={setEditProfileInformation}
        emailID={emailID}
        setDetails={setDetails}
        setSuccess={setSuccess}
      />
      <SuccessModal
        open={success}
        onPrimaryActionClick={() => setSuccess(false)}
      />
      <Collapse
        onChange={onChange}
        defaultActiveKey={["1"]}
        className="account-holder mb-15"
      >
        <Panel header={panelHeader} key="1">
          <ProfileSection data={details?.profile ? details.profile : null} />
        </Panel>
        <Panel header="KYC" key="2">
          <KYCSection data={details?.KYC ? details.KYC : null} />
        </Panel>
        <Panel header="Account balances" key="3">
          <AccountDetailsSection
            data={details?.account_balance ? details.account_balance : null}
          />
        </Panel>
        <Panel header="Cards" key="6">
          <CardSection data={details?.card ? details.card : null} />
        </Panel>
        <Panel header="External account" key="4">
          <ExternalAccountSection
            data={details?.external_accounts ? details.external_accounts : null}
          />
        </Panel>
        {details?.investment_account &&
          <Panel header="Investment" key="5">
            <InvestmentSection
              data={
                details?.investment_account ? [details.investment_account] : null
              }
            />
          </Panel>}

        {
          details?.payroll_contribution &&
          <Panel header="HSA contributions" key="7">
            <ContributionsSection
              data={
                details?.payroll_contribution
                  ? details.payroll_contribution
                  : null
              }
            />
          </Panel>
        }
        <Panel header="Expenses" key="8">
          <ExpensesSectionV2 data={details?.expense_counts ? details.expense_counts : null} zan={details?.profile?.zenda_account_num} />
        </Panel>
        <Panel header="Transactions" key="9">
          <TransactionsSection
            data={details?.transaction ? details.transaction : null}
          />
        </Panel>
        <Panel header="Pending ACH" key="10">
          <PendingACHSection
            data={details?.pending_ach_txns ? details.pending_ach_txns : null}
          />
        </Panel>
      </Collapse>
    </SpinLoader>
  );
}

export { AccountHolder };
