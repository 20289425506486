import { Button, Typography } from "../../../../design-systems";

export const Header = ({ onSaveProgress, steps, currentStep, nextStep }) => (
  <div className="step-header-container">
    <Typography variant="heading-3" label="Add employer" />

    <div className="step-header-wrapper">
      <Typography
        variant="subhead-2"
        label={`${steps[currentStep].name}`}
        className="current-step-title"
      />

      <div className="step-container">
        <div className="step-detail">
          <div className="step-count">
            <Typography variant="body-3" label={`Step ${nextStep}/5`} />
          </div>
          <div className="next-step">
            {steps[nextStep] && (
              <Typography
                variant="body-3"
                label={`Next Step: ${steps[nextStep]?.name}`}
              />
            )}
          </div>
        </div>

        <div className="stepper">
          {steps.map(({ isActive, isComplete }) => (
            <div
              className={`step-tab ${isComplete && "completed"} ${isActive && "active"
                }`}
            ></div>
          ))}
        </div>
      </div>
      {(currentStep !== 0 && currentStep !== 4) &&
        <Button
          variant="secondary"
          onclickCallback={onSaveProgress}
          label="Save Progress"
        />}
    </div>
  </div>
);
