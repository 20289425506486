import { Form, Popconfirm, Table, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../design-systems";
import { Close, Delete, Edit, FillCheck } from "../../design-systems/Icons";
import { langs } from "../../i18n";
import {
  deleteAdmin,
  disableGlobalLoader,
  enableGlobalLoader,
  getCurrentEmployerDetailsByTab,
  inviteAdmin,
  showErrorMessage,
  showSuccessMessage,
  updateEmployer,
  validateAdminEmail
} from "../../redux/actions";
import { clearBodyObject, roleKeysForTable, splitFullName } from "../../utils";
import { ADMIN_ACTIONS } from "../../utils/constants";
import { WarningDeleteAdminModal } from "../admin/settings/users";

const EditableCell = ({
  editing,
  children,
  editingNode,
  additionNode,
  record,
  editingKey,
  ...restProps
}) => {
  if (record && !Object.keys(record).length) {
    return <td {...restProps}>{additionNode({ editingKey, record })}</td>;
  }

  return <td {...restProps}>{editing ? editingNode({ editingKey, record }) : children}</td>;
};

export const EditableTable = ({ columns, data = [], onDataChange, makeApiCall, tabKey, copaysCategories, page = "" }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [isEditingMode, setIsEditingMode] = useState(false);

  const [dataExt, setData] = useState(
    data?.length && !Object.keys(data?.[0]).length ? [...data] : [{}, ...data],
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({})
  const isEditing = (record) => record.key === editingKey;
  const isAdminUserPage = page === "adminUsers"

  useEffect(() => {
    if (isAdminUserPage) {
      setData(data?.length && !Object.keys(data?.[0]).length ? [...data] : [{}, ...data]);
      // setEditingKey("")
    }
  }, [data])

  useEffect(() => {
    if (!isAdminUserPage) {
      onDataChange?.(dataExt);
    }
  }, [dataExt]);

  const deleteInvitedAdmin = () => {
    setShowWarningModal(false);

    dispatch(
      deleteAdmin(selectedRecord.email, (res) => {
        dispatch(showSuccessMessage(langs.messages.admin_was_deleted));
        dispatch(getCurrentEmployerDetailsByTab(tabKey, (res) => {
          if (!(res instanceof Error)) {
            setData((prev) => [...prev.filter(({ key }) => key !== selectedRecord.key)]);
            setSelectedRecord({})
          }
        }))

      }),
    );
  };

  const add = async () => {
    const val = form.getFieldsValue();

    const row = await form.validateFields([
      ...Object.keys(val).filter((v) => v.startsWith("add-")),
    ]);

    const keys = Object.keys(row);
    if (makeApiCall) {
      const fields = form.getFieldsValue();
      const {
        "add-name": name,
        "add-email": email,
        "add-role": role
      } = fields
      const payload = {
        adminEmailId: email,
        role: role,
      };
      if (name.length) {
        const { firstName, lastName } = splitFullName(name)
        payload.firstName = firstName
        payload.lastName = lastName
      }
      const requestData = clearBodyObject(payload);
      dispatch(
        validateAdminEmail(requestData.adminEmailId, (res) => {
          if (res instanceof Error) {
            dispatch(showErrorMessage(langs.messages.email_already_registered_as_an_admin));
          } else {
            dispatch(enableGlobalLoader())
            dispatch(
              inviteAdmin(payload, (res) => {
                dispatch(disableGlobalLoader())
                dispatch(showSuccessMessage(langs.messages.invitation_was_sent));
                dispatch(
                  getCurrentEmployerDetailsByTab(tabKey, (res) => {
                    if (res instanceof Error) {
                      dispatch(showErrorMessage(res.message));
                    } else {
                      const newData = [...dataExt];
                      newData.push(
                        {
                          ...res.data.administrative_users[res.data.administrative_users.length - 1],
                          key: res.data.administrative_users.length
                        }
                      );
                      setData(newData);

                      form.setFieldsValue({
                        ...keys.reduce(
                          (prev, current) => ({ ...prev, [current]: undefined }),
                          {},
                        ),
                      });
                    }
                  }))
              }),
            );
          }
        }),
      );
    } else {
      const newData = [...dataExt];
      newData.push(
        keys.reduce(
          (prev, current) => ({ ...prev, [current.split("-")[1]]: row[current] }),
          { key: newData.length },
        ),
      );
      setData(newData);

      form.setFieldsValue({
        ...keys.reduce(
          (prev, current) => ({ ...prev, [current]: undefined }),
          {},
        ),
      });
    }

  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
    setIsEditingMode(true);
  };

  const deleteRow = (record) => {
    setData((prev) => [...prev.filter(({ key }) => key !== record.key)]);
  };

  const deleteAdminUser = (record) => {
    setSelectedRecord(record)
    setShowWarningModal(true)
  }

  const cancel = () => {
    setEditingKey("");
    setIsEditingMode(false);
  };


  const save = async (key) => {
    try {
      const val = form.getFieldsValue();
      const row = await form.validateFields([
        ...Object.keys(val).filter((v) => !v.startsWith("add-")),
      ]);
      const newData = [...dataExt];
      const index = newData.findIndex((item) => key === item.key);

      if (makeApiCall) {
        const initialValues = { ...newData[index] }
        const fields = { ...val }

        const { firstName, lastName } = splitFullName(initialValues?.name);
        const initialValuesFirstName = firstName;
        const initialValuesLastName = lastName;

        const { firstName: fieldFirstName, lastName: fieldLastName } = splitFullName(fields?.name);
        const fieldsFirstName = fieldFirstName;
        const fieldsLastName = fieldLastName;

        const emailIsChanged = initialValues.email !== fields.email;
        const firstnameIsChanged = initialValuesFirstName !== fieldsFirstName;
        const lastnameIsChanged = initialValuesLastName !== fieldsLastName;
        const roleIsChanged = initialValues.role !== fields.role;
        const payload = {
          admin_email_id: initialValues.email?.toLowerCase(),
          upd_admin_email_id: emailIsChanged ? fields.email.toLowerCase() : null,
          first_name: firstnameIsChanged ? fieldsFirstName : null,
          last_name: lastnameIsChanged ? fieldsLastName : null,
          role: roleIsChanged ? fields.role : null,
          invite: emailIsChanged ? ADMIN_ACTIONS.UPDATE_EMAIL : null,
        };
        const requestData = clearBodyObject(payload)
        dispatch(updateEmployer(requestData, (res) => {
          if (res instanceof Error) {
          } else {
            dispatch(showSuccessMessage(langs.messages.admin_details_updated));
            if (index > -1) {
              const item = newData[index];
              newData.splice(index, 1, {
                ...item,
                ...row,
              });
              setData(newData);
              setEditingKey("");
            } else {
              newData.push(row);
              setData(newData);
              setEditingKey("");
            }
          }
        }))
      } else {
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);
          setEditingKey("");
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey("");
        }
      }
    } catch (errInfo) {
    }
  };

  const columnsExt = [
    ...columns,
    {
      title: "",
      dataIndex: "",
      render: (_, record) => {
        const editable = isEditing(record);
        // const showDeleteButton = makeApiCall && (record.status !== "Enabled" || record.status !== "Disabled") && !editable;

        if (!Object.keys(record).length) {
          return (
            <Button
              className="button-wrapper-child"
              variant="secondary"
              onclickCallback={add}
              label="Add"
              disabled={editingKey !== ""}
            />
          );
        }
        return (
          <div className="action-wrapper">
            {editable ? (
              <>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <Close height={18} width={18} />
                </Popconfirm>
                <Typography.Link onClick={() => save(record.key)}>
                  <FillCheck />
                </Typography.Link>
              </>
            ) : (
              <>
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <Edit />
                </Typography.Link>
                {makeApiCall && record?.status === "Invited" &&
                  <Typography.Link
                    disabled={editingKey !== ""}
                    onClick={() => deleteAdminUser(record)}
                  >
                    <Delete />
                  </Typography.Link>
                }{!makeApiCall &&
                  <Typography.Link
                    disabled={editingKey !== ""}
                    onClick={() => deleteRow(record)}
                  >
                    <Delete />
                  </Typography.Link>
                }
              </>
            )}
          </div>
        );
      },
    },
  ];
  const mergedColumns = useMemo(() => {
    return columnsExt.map((col, index) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          editingNode: col.editingNode,
          additionNode: col.additionNode,
          editingKey,
        }),
        render: (text, record) => {
          if (col?.dataIndex === 'category') {
            const category = copaysCategories?.find((cat) => {
              return cat.value === text
            });
            return category ? category.label : text;
          }

          if (col?.dataIndex === 'role') {
            return roleKeysForTable[text];
          }
          return text;
        },
      };
    });
  }, [editingKey, columnsExt, isEditingMode])

  return (
    <Form form={form} component={false}>
      <WarningDeleteAdminModal
        showWarningModal={showWarningModal}
        deleteAdmin={deleteInvitedAdmin}
        onCancel={() => setShowWarningModal(false)}
      />
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowKey={(row) => row?.email}
        dataSource={dataExt}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        className="editable-table"
      // pagination={{
      //   onChange: cancel,
      // }}
      />
    </Form>
  );
};