import { Card, Col, Row } from "antd";
import { CardTitle } from "./CardTitle";
import { Table, Typography } from "../../../../../../design-systems";
import { fixAmountFormat, getPlanInformationColumns, getYTDContributionsLabel, getYTDLabels } from "../../../../../../utils";
import { DownOutlined } from "@ant-design/icons";
import { PlanInformationForm } from "./forms";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddNewPlan } from "./AddNewPlan";
import { getCoverages, getEmployerInfo } from "../../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../../../utils/router";

const renderData = (plan, [label, value]) => {
  return (
    <Col sm={24} className="plan-information-data">
      <Typography label={label ? getYTDContributionsLabel(label, plan) : ""} variant="body-3" />
      <Typography className="align-right" label={value !== null ? `${fixAmountFormat(value)}` : "$0.00"} variant="body-2" />
    </Col>
  )
};

const expandedRowRender = (row) => {
  const { plan_name, ytd_contribution } = row || {};
  return (
    <Row gutter={[16, 16]}>
      {["HSA", "DCFSA"].includes(plan_name) && (
        <Col sm={12}>
          <Row gutter={[16, 16]}>
            <Col sm={24}>
              <Typography label="YTD Contributions" variant="body-2" />
            </Col>
            {Object.entries(ytd_contribution).map(([key, value]) => renderData(plan_name, [key, value]))}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export const PlanInformation = ({ planInfo, record, getEmployeeData, employee }) => {

  const dispatch = useDispatch();
  const router = useRouter()
  const [open, setOpen] = useState(false);
  const [planType, setPlanType] = useState("");
  const [visible, setVisible] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null);
  const [plansData, setPlansData] = useState([]);
  const [formData, setFormData] = useState({});
  const [changedValue, setChangedValues] = useState(null);
  const role = useSelector((state) => state?.userDetails?.roleType);
  const onExpand = (_, { plan_id }) => {
    expandedKey === plan_id ? setExpandedKey(null) : setExpandedKey(plan_id);
  }

  const id = router.match.params.id;

  useEffect(() => {
    dispatch(getCoverages());
  }, [])

  const { employerPlanInfo } = useSelector((state) => state.employer);
  const { employeePlans } = useSelector((state) => state.employees);

  useEffect(() => {
    const availablePlans = employeePlans.map(plan => plan?.plan_name);
    if (availablePlans?.length) {
      const displayPlans = employerPlanInfo?.plans_enrolled?.filter(plan => !availablePlans.includes(plan));
      setPlansData(displayPlans)
    }
  }, [employeePlans, employerPlanInfo])

  return (
    <Card
      title={<CardTitle
        title="Plan Information"
        addPlan={true}
        onAddPlanClick={() => setOpen(true)}
        plansData={plansData || []}
      />}
      bordered
      className="emp-detail-card plan-information-wrapper"
    >
      <Table
        expandIconColumnIndex={9}
        className={"activity-logs-table"}
        rowKey={(record) => record.plan_id}
        columns={getPlanInformationColumns({
          role,
          record,
          onEdit: (planType) => {
            setPlanType(planType);
            setVisible(true);
          },
        })}
        dataSource={employeePlans}
        pagination={false}
        expandable={{
          onExpand: onExpand,
          expandedRowKeys: [expandedKey],
          expandedRowRender,
          rowExpandable: (record) => (record.plan_name === 'DCFSA' || record.plan_name === 'HSA'),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record.plan_name === 'DCFSA' || record.plan_name === 'HSA') {
              return (
                <DownOutlined
                  className={`arrow-up-sorter ${expanded ? "expanded" : "collapsed"
                    }`}
                  onClick={() => onExpand(record)}
                />
              )
            } else {
              return null
            }
          },
          expandIconPosition: "right",
        }}
      />
      {visible && <PlanInformationForm
        id={employee?.employeeID?.value ?? id}
        visible={visible}
        planType={planType}
        onCancel={() => {
          setVisible(false);
          setPlanType("");
        }}
        getEmployeeData={getEmployeeData}
        formData={formData}
        setFormData={setFormData}
        changedValue={changedValue}
        setChangedValues={setChangedValues}
        planInfo={planInfo}
      />}

      <AddNewPlan
        open={open}
        setOpen={setOpen}
        getEmployeeData={getEmployeeData}
        plansData={plansData ?? []}
      />

    </Card>
  );
};
