import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Layout, Col, Row } from "antd";
import { IncommLoaderIcon } from "../../design-systems";

function AdminFooter({ customFooter }) {
  const { Footer } = Layout;

  if (customFooter) {
    return customFooter;
  }

  return (
    <Footer>
      <Row gutter={0}>
        <Col xs={24} sm={24} md={20} lg={20} className="">
          <div className="footer-left-block">
            <div className="copyright text-muted ml-2">
              <Link to="/">
                <div className="footer-logo" style={{ marginRight: 8 }}><IncommLoaderIcon color="white" /></div>
              </Link>
              <b>Copyright © {moment().year()}</b>
              <Link className="admin-footer" to={{ pathname: "https://incommbenefits.com/" }} target="_blank">
                <b>InComm Payments, Inc. All Rights Reserved.</b>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} className="text-center">
          <div className="footer-left-block">
            <div className="text-muted color-white d-flex justify-flex-end">Version 2.1.1</div>
          </div>
        </Col>
        {/* <Col xs={24} sm={24} md={8} lg={8} className="text-right">
          <ul className="nav-footer">
            <li >
              <Link
                to={{ pathname: "https://getzenda.com/about/" }}
                target="_blank"
                className="color-white"
              >
                About Us
              </Link>
            </li>
          </ul>
        </Col> */}
      </Row>
    </Footer>
  );
}
export { AdminFooter };
