import { Tag } from "antd";
import { EMPLOYER_STATUSES } from "../utils/constants";
import { BankAccount } from "../components/admin/employer/AddEmployer/BankAccount";
import { AddBankAccountDetails } from "../components/admin/employer/AddEmployer/BankAccount/AddBankAccountDetails";
import { CompanyInformation } from "../components/admin/employer/AddEmployer/CompanyInformation";
import { BusinessDetails } from "../components/admin/employer/AddEmployer/CompanyInformation/BusinessDetails";
import { CorporateAddress } from "../components/admin/employer/AddEmployer/CompanyInformation/CorporateAddress";
import { Divisions } from "../components/admin/employer/AddEmployer/CompanyInformation/Divisions";
import { MailingAddress } from "../components/admin/employer/AddEmployer/CompanyInformation/MailingAddress";
import { ContactInformation } from "../components/admin/employer/AddEmployer/ContactInformation";
import { AdditionalContact } from "../components/admin/employer/AddEmployer/ContactInformation/AdditionalContact";
import { PrimaryContact } from "../components/admin/employer/AddEmployer/ContactInformation/PrimaryContact";
import { Copays } from "../components/admin/employer/AddEmployer/Copays";
import { AddCopays } from "../components/admin/employer/AddEmployer/Copays/AddCopays";
import { PlanInformation } from "../components/admin/employer/AddEmployer/PlanInformation";
import { DCFSAPlanDetails } from "../components/admin/employer/AddEmployer/PlanInformation/DCFSAPlanDetails";
import { FSAPlanDetails } from "../components/admin/employer/AddEmployer/PlanInformation/FSAPlanDetails";
import { LFSAPlanDetails } from "../components/admin/employer/AddEmployer/PlanInformation/LFSAPlanDetails";
import { PlanYear } from "../components/admin/employer/AddEmployer/PlanInformation/PlanYear";
import { SelectPlan } from "../components/admin/employer/AddEmployer/PlanInformation/SelectPlan";
import moment from "moment";
import { AddAdditionalDetails } from "../components/admin/employer/AddEmployer/AdditionalDetails";

export const steps = [
  {
    name: "Company information",
    key: "company-information",
    isActive: true,
    isComplete: false,
  },
  {
    name: "Contact information",
    key: "contact-information",
    isActive: false,
    isComplete: false,
  },
  {
    name: "Plan information",
    key: "plan-information",
    isActive: false,
    isComplete: false,
  },
  {
    name: "Copays",
    key: "copays",
    isActive: false,
    isComplete: false,
  },
  {
    name: "Bank account",
    key: "bank-account",
    isActive: false,
    isComplete: false,
  },
];

export const roleOptionKeys = {
  superAdmin: "SUPER_ADMIN",
  admin: "ADMIN",
  zendaAdmin: "ZENDA_ADMIN"
}

export const roleKeysForTable = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  ZENDA_ADMIN: "Internal Admin",
}

export const companyInformationKeys = {
  BUSINESS_DETAILS: "business-details",
  CORPORATE_ADDRESS: "corporate-address",
  MAILING_ADDRESS: "mailing-address",
  ADD_DIVISIONS: "add-division",
};

export const contactInformationKeys = {
  PRIMARY_CONTACT: "primary-contact",
  ADDITIONAL_CONTACT: "additional-contact",
};

export const planInformationKeys = {
  PLAN_YEAR: "plan-year",
  SELECT_PLAN: "select-plan",
  FSA_PLAN_DETAIL: "fsa-plan-detail",
  DCFSA_PLAN_DETAIL: "dcfsa-plan-detail",
  LFSA_PLAN_DETAIL: "lfsa-plan-detail",
};

export const copayKeys = {
  ADD_COPAYS: "add-copays",
};

export const bankInformationKeys = {
  ADD_BANK_ACCOUNT_DETAILS: "add-bank-account-details",
  ADD_ADDITIONAL_DETAILS: "add-additional-details",
};

export const planOptions = [
  { label: "HSA", value: "HSA" },
  { label: "FSA", value: "FSA" },
  { label: "DCFSA", value: "DCFSA" },
  { label: "LFSA", value: "LFSA" },
];

export const irsValueToYearMap = {
  2023: {
    FSA: 3050,
    DCFSA: 5000,
    LFSA: 2850
  },
  2024: {
    FSA: 3200,
    DCFSA: 5000,
    LFSA: 3050
  },
}

export const companyInformationStep = [
  {
    title: "Business details",
    key: companyInformationKeys.BUSINESS_DETAILS,
    content: ({ form }) => <BusinessDetails form={form} />,
  },
  {
    title: "Corporate address",
    key: companyInformationKeys.CORPORATE_ADDRESS,
    content: ({ form }) => <CorporateAddress form={form} />,
  },
  {
    title: "Mailing address",
    key: companyInformationKeys.MAILING_ADDRESS,
    content: ({ form }) => <MailingAddress form={form} />,
  },
  {
    title: "Add divisions",
    key: companyInformationKeys.ADD_DIVISIONS,
    content: ({ form }) => <Divisions form={form} />,
  },
];

export const contactInformationStep = [
  {
    title: "Primary contact",
    key: contactInformationKeys.PRIMARY_CONTACT,
    content: ({ form, setAdditionalContact, additionalContact, allowPlanDocument, setAllowPlanDocument, isSuperAdmin, setIsSuperAdmin }) => (
      <PrimaryContact
        form={form}
        isSuperAdmin={isSuperAdmin}
        setIsSuperAdmin={setIsSuperAdmin}
        allowPlanDocument={allowPlanDocument}
        setAllowPlanDocument={setAllowPlanDocument}
        setAdditionalContact={setAdditionalContact}
        additionalContact={additionalContact}
      />
    ),
  },
  {
    title: "Additional contact",
    key: contactInformationKeys.ADDITIONAL_CONTACT,
    content: ({ form, setAdditionalContact, additionalContact }) => (
      <AdditionalContact
        form={form}
        setAdditionalContact={setAdditionalContact}
        additionalContact={additionalContact}
      />
    ),
  },
];

export const planInformationStep = [
  {
    title: "Select plan",
    key: planInformationKeys.SELECT_PLAN,
    content: ({ form, plansExt, setPlansExt }) => (
      <SelectPlan form={form} plansExt={plansExt} setPlansExt={setPlansExt} />
    ),
  },
  {
    title: "Plan year",
    key: planInformationKeys.PLAN_YEAR,
    content: ({ form, formData, setFormData }) => <PlanYear form={form} formData={formData} setFormData={setFormData} />,
  },
  {
    title: "FSA plan details",
    key: planInformationKeys.FSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData }) => <FSAPlanDetails form={form} formData={formData} setFormData={setFormData} />,
    planKey: planOptions.find(({ value }) => value === "FSA").value,
  },
  {
    title: "DCFSA plan details",
    key: planInformationKeys.DCFSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData }) => <DCFSAPlanDetails form={form} formData={formData} setFormData={setFormData} />,
    planKey: planOptions.find(({ value }) => value === "DCFSA").value,
  },
  {
    title: "LFSA plan details",
    key: planInformationKeys.LFSA_PLAN_DETAIL,
    content: ({ form, formData, setFormData }) => <LFSAPlanDetails form={form} formData={formData} setFormData={setFormData} />,
    planKey: planOptions.find(({ value }) => value === "LFSA").value,
  },
];

export const copayStep = [
  {
    title: "Add copays",
    key: copayKeys.ADD_COPAYS,
    content: ({ form }) => <AddCopays form={form} />,
  },
];

export const bankInformationStep = [
  {
    title: "Add bank account details",
    key: bankInformationKeys.ADD_BANK_ACCOUNT_DETAILS,
    content: ({ form }) => <AddBankAccountDetails form={form} />,
  },
  {
    title: "Add additional details",
    key: bankInformationKeys.ADD_ADDITIONAL_DETAILS,
    content: ({ form }) => <AddAdditionalDetails form={form} />,
  },
];

export const validateFields = {
  [companyInformationKeys.BUSINESS_DETAILS]: [
    "business_name",
    "employer_tax_id",
    "primary_employee_identifier",
  ],
  [companyInformationKeys.CORPORATE_ADDRESS]: [
    "corporate_address_line_1",
    "corporate_city",
    "corporate_state",
    "corporate_zip_code",
  ],
  [companyInformationKeys.MAILING_ADDRESS]: [
    "mailing_address_line_1",
    "mailing_city",
    "mailing_state",
    "mailing_zip_code",
  ],
  [companyInformationKeys.ADD_DIVISIONS]: [
    "divisions",
    "division_identifier",
    "description",
    "add-division_identifier",
    "add-description"
  ],
  [contactInformationKeys.PRIMARY_CONTACT]: [
    "full_name",
    "job_title",
    "phone_number",
    "email",
  ],
  [contactInformationKeys.ADDITIONAL_CONTACT]: [
    "additional_full_name",
    "additional_job_title",
    "additional_phone_number",
    "additional_email",
  ],
  [planInformationKeys.PLAN_YEAR]: ["start_date", "end_date"],
  [planInformationKeys.SELECT_PLAN]: ["plans", "plans_err"],
  [planInformationKeys.FSA_PLAN_DETAIL]: [
    "fsa_employee_minimum_contribution",
    "fsa_employee_maximum_contribution",
    "fsa_run_out_period",
    "fsa_terminated_employees_run_out",
    "fsa_allow_claims",
    "fsa_maximum_rollover",
    "fsa_rollover_or_grace_period",
  ],
  [planInformationKeys.DCFSA_PLAN_DETAIL]: [
    "dcfsa_employee_minimum_contribution",
    "dcfsa_employee_maximum_contribution",
    "dcfsa_run_out_period",
    "dcfsa_terminated_employees_run_out",
    "dcfsa_allow_claims",
    "dcfsa_maximum_rollover",
    "dcfsa_rollover_or_grace_period",
  ],
  [planInformationKeys.LFSA_PLAN_DETAIL]: [
    "lfsa_employee_minimum_contribution",
    "lfsa_employee_maximum_contribution",
    "lfsa_run_out_period",
    "lfsa_terminated_employees_run_out",
    "lfsa_allow_claims",
    "lfsa_maximum_rollover",
    "lfsa_rollover_or_grace_period",
  ],
  [copayKeys.ADD_COPAYS]: [],
  [bankInformationKeys.ADD_BANK_ACCOUNT_DETAILS]: [],
};

export const PrimaryEmployeeIdentifiers = [
  {
    label: "Employee ID",
    value: "employee-id",
  },
  {
    label: "Social Security Number",
    value: "ssn",
  },
];

export const renderSteps = ({ step, form, formData, setFormData }) => {
  switch (String(step)) {
    case "1":
      return <CompanyInformation form={form} formData={formData} setFormData={setFormData} />;
    case "2":
      return <ContactInformation form={form} formData={formData} setFormData={setFormData} />;
    case "3":
      return <PlanInformation form={form} formData={formData} setFormData={setFormData} />;
    case "4":
      return <Copays form={form} formData={formData} setFormData={setFormData} />;
    case "5":
      return <BankAccount form={form} formData={formData} setFormData={setFormData} />;
    default:
      return <></>;
  }
};

export const renderEmployerStatusTag = (tag) => {
  let color;
  let statusName;

  switch (tag) {
    case EMPLOYER_STATUSES.ACTIVE:
      color = "active";
      statusName = "Active";
      break;
    case EMPLOYER_STATUSES.INPROGRESS:
      color = "in-progress";
      statusName = "In Progress";

      break;
    case EMPLOYER_STATUSES.INACTIVE:
      color = "inactive";
      statusName = "Inactive";
      break;
    default:
      color = "inactive";
      statusName = "Inactive";
      break;
  }

  return <Tag className={`status-employer ${color}`}>{statusName}</Tag>;
};


export const formatDataForStep = (inputData, step, form) => {
  console.log({inputData});
  const commonData = {
    employer_id: null,
    step_id: step,
    is_save_progress: false,
  };
  switch (step) {
    case 1:
      let formattedData = {
        ...commonData,
        data: {
          business_details: {
            business_name: inputData.business_name,
            employer_tax_id: inputData.employer_tax_id,
            primary_employee_identifier: inputData.primary_employee_identifier,
          },
          corporate_address: {
            address_line_1: inputData.corporate_address_line_1,
            address_line_2: inputData.corporate_address_line_2,
            city: inputData.corporate_city,
            state: inputData.corporate_state,
            postal_code: inputData.corporate_zip_code,
          },
          same_as_corporate_address: inputData.same_as_corporate ?? true,
        },
      };
      if (!inputData?.add_division) {
        formattedData.data.divisions = []
      } else {
        formattedData.data.divisions =
          inputData.divisions.filter(item => Object.keys(item).length > 0).map((division) => ({
            identifier: division.division_identifier,
            description: division.description,
          }))
      }
      if (!inputData.same_as_corporate) {
        formattedData.data.mailing_address = {
          address_line_1: inputData.mailing_address_line_1,
          address_line_2: inputData.mailing_address_line_2,
          city: inputData.mailing_city,
          state: inputData.mailing_state,
          postal_code: inputData.mailing_zip_code,
        };
      } else {
        formattedData.data.mailing_address = { ...formattedData.data.corporate_address }
      }
      return {
        ...formattedData,
      };
    case 2:
      let formattedDataStep2 = {
        ...commonData,
        data: {
          primary_contact: {
            full_name: inputData.full_name,
            job_title: inputData.job_title,
            phone_no: inputData.phone_number,
            email: inputData.email,
            is_document_placeholder: inputData.authorized_for_plan_document ?? true,
            is_super_admin: inputData.is_super_admin ?? true
          },
        }
      }
      if (Object.keys(inputData).some(key => key.startsWith("additional"))) {
        formattedDataStep2.data.additional_contact_exist = true
        formattedDataStep2.data.additional_contact = {
          full_name: inputData.additional_full_name,
          job_title: inputData.additional_job_title,
          phone_no: inputData.additional_phone_number,
          email: inputData.additional_email
        }
      } else {
        formattedDataStep2.data.additional_contact_exist = false
      }
      return {
        ...formattedDataStep2
      }
    case 3:
      const planKeyMap = {
        fsa: {
          min_employee_contribution_amount: Number(inputData.fsa_employee_minimum_contribution) || 0,
          max_employee_contribution_amount: Number(inputData.fsa_employee_maximum_contribution) || 0,
          employer_contribution_amount: Number(inputData.fsa_employee_contribution_amount) || 0,
          run_out_period_in_days: Number(inputData.fsa_run_out_period) || 0,
          allow_claim_file_post_termination_within_service_date: false,
          terminated_employees_run_out: Number(inputData.fsa_terminated_employees_run_out) || 0,
          is_grace_period_enabled: inputData.fsa_rollover_or_grace_period === 'grace-period' ? true : false,
          is_rollover_enabled: inputData.fsa_rollover_or_grace_period === 'rollover',
          rollover_if_no_fsa_next_year: inputData.fsa_allow_rollover_not_elected_for_following_plan_year === "yes" ? true : false,
          max_rollover_amount: Number(inputData.fsa_maximum_rollover) || 0,
          min_rollover_amount: Number(inputData.fsa_minimum_rollover) || 0,
          grace_period_end_date: inputData.fsa_rollover_or_grace_period === 'grace-period' ? moment(inputData.fsa_grace_period_end_date).format('YYYY-MM-DD') : null
        },
        lfsa: {
          min_employee_contribution_amount: Number(inputData.lfsa_employee_minimum_contribution) || 0,
          max_employee_contribution_amount: Number(inputData.lfsa_employee_maximum_contribution) || 0,
          employer_contribution_amount: Number(inputData.lfsa_employee_contribution_amount) || 0,
          run_out_period_in_days: Number(inputData.lfsa_run_out_period) || 0,
          allow_claim_file_post_termination_within_service_date: false,
          terminated_employees_run_out: Number(inputData.lfsa_terminated_employees_run_out) || 0,
          is_grace_period_enabled: inputData.lfsa_rollover_or_grace_period === 'grace-period' ? true : false,
          is_rollover_enabled: inputData.lfsa_rollover_or_grace_period === 'rollover',
          grace_period_end_date: inputData.lfsa_rollover_or_grace_period === 'grace-period' ? moment(inputData.lfsa_grace_period_end_date).format('YYYY-MM-DD') : null,
          rollover_if_no_fsa_next_year: inputData.lfsa_allow_rollover_not_elected_for_following_plan_year === "yes" ? true : false,
          max_rollover_amount: Number(inputData.lfsa_maximum_rollover) || 0,
          min_rollover_amount: Number(inputData.lfsa_minimum_rollover) || 0
        },
        dcfsa: {
          min_employee_contribution_amount: Number(inputData.dcfsa_employee_minimum_contribution) || 0,
          max_employee_contribution_amount: Number(inputData.dcfsa_employee_maximum_contribution) || 0,
          employer_contribution_amount: Number(inputData.dcfsa_employee_contribution_amount) || 0,
          run_out_period_in_days: Number(inputData.dcfsa_run_out_period) || 0,
          allow_claim_file_post_termination_within_service_date: inputData?.dcfsa_allow_claims === 'yes' ? true : false,
          terminated_employees_run_out: Number(inputData.dcfsa_terminated_employees_run_out) || 0,
          is_grace_period_enabled: inputData.dcfsa_grace_period === 'yes' ? true : false,
          grace_period_end_date: inputData.dcfsa_grace_period === 'yes' ? moment(inputData.dcfsa_grace_period_end_date).format('YYYY-MM-DD') : null,
          is_rollover_enabled: false,
          rollover_if_no_fsa_next_year: false,
          max_rollover_amount: 0,
          min_rollover_amount: 0,
        }
      }
      let formattedDataStep3 = {
        ...commonData,
        data: {
          plan_year: {
            start_date: inputData.start_date ? moment(inputData.start_date).format('YYYY-MM-DD') : "",
          },
          selected_plans: inputData.plans,
        },
      }
      if (inputData.end_date) {
        formattedDataStep3.data.plan_year.end_date = moment(inputData.end_date).format('YYYY-MM-DD')
      } else {
        formattedDataStep3.data.plan_year.end_date = moment("9999-12-31").format('YYYY-MM-DD')
      }
      for (const plan of inputData.plans) {
        if (planKeyMap[plan.toLowerCase()]) {
          formattedDataStep3.data[plan.toLowerCase()] = planKeyMap[plan.toLowerCase()];
        }
      }
      return {
        ...formattedDataStep3
      }
    case 4:
      let formattedDataStep4 = {
        ...commonData,
        data: {
          copay_configuration: inputData.copays.filter(item => Object.keys(item).length > 0).map((copay) => ({
            copay_amount: Number(copay.copay_amount),
            category_id: copay.category,
          }))
        }
      }
      return {
        ...formattedDataStep4
      }
    case 5:
      const divisions = form.getFieldValue("divisions")
      let formattedDataStep5 = {
        ...commonData,
        data: {
          processor: inputData?.processor,
          is_single_bank_account_for_all: inputData.single_bank_account_for_all ? inputData.single_bank_account_for_all : false,
        }
      }
      if (divisions?.length) {
        const formattedDivisions = divisions.filter(item => Object.keys(item).length > 0)

        if (formattedDivisions.length === 1) {
          formattedDataStep5.data.bank_acc_details = [{
            routing_number: inputData.routing_number,
            account_number: inputData.account_number,
            name_of_account: inputData.name_of_account,
            division_identifier: formattedDivisions[0].division_identifier
          }]
        } else {
          if (inputData.single_bank_account_for_all) {
            formattedDataStep5.data.bank_acc_details = divisions.filter(item => Object.keys(item).length > 0).map(div => ({
              routing_number: inputData.routing_number,
              account_number: inputData.account_number,
              name_of_account: inputData.name_of_account,
              division_identifier: div.division_identifier
            }))
          } else {
            formattedDataStep5.data.bank_acc_details = inputData.bank_accounts ? inputData.bank_accounts.map(bank => ({
              routing_number: bank.routing_number,
              account_number: bank.account_number,
              name_of_account: bank.name_of_account,
              division_identifier: bank.division_identifier
            })) : []
          }
        }
      } else {
        formattedDataStep5.data.bank_acc_details = [
          {
            routing_number: inputData.routing_number,
            account_number: inputData.account_number,
            name_of_account: inputData.name_of_account,
            division_identifier: "default"
          }
        ]
        formattedDataStep5.data.is_single_bank_account_for_all = true
      }
      return {
        ...formattedDataStep5
      }
    default:
      return null;
  }
}

export const getMergedCopaysCategories = (data) => {
  const mergedData = data.reduce((result, item) => {
    const { expense_category, copay_amount } = item;
    if (!result[expense_category]) {
      result[expense_category] = [];
    }
    result[expense_category].push(copay_amount);
    return result;
  }, {});

  const mergedArray = Object.entries(mergedData).map(([key, value]) => ({ expense_category: key, copay_amount: value }));
  return mergedArray
}

export const splitFullName = (fullName = "") => {
  const nameParts = fullName.trim().split(/\s+/);

  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  return {
    firstName,
    lastName,
  };
}

export const handleKeyDown = (e) => {
  const isCtrlPressed = e.ctrlKey || e.metaKey; // For Mac users

  const allowedKeys = [
    /[0-9]/, 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', '/', 'Control'
  ];

  const isAllowed = allowedKeys.some(key => {
    if (typeof key === 'string') {
      return e.key === key;
    } else if (key instanceof RegExp) {
      return key.test(e.key);
    }
    return false;
  });

  if (!isAllowed || (isCtrlPressed && !allowedKeys.includes('Control'))) {
    e.preventDefault();
  }
};

export const disablePastDates = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set hours to 00:00:00:000 to compare dates without considering time
  return date >= today;
}