import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Typography, ZoomIn, ZoomOut } from "../../design-systems";
import { Divider } from "antd";
import "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.js'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PDFViewer = ({ pdfUrl = "" }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [thumbnails, setThumbnails] = useState([]);

  const containerRef = useRef(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setScale(1);
    setCurrentPage(newPage);
  };

  const zoomIn = () => setScale((prev) => prev + 0.2);

  const zoomOut = () => setScale((prev) => Math.max(prev - 0.2, 0.2));

  const generateThumbnails = async () => {
    const thumbPromises = [];
    for (let i = 1; i <= numPages; i++) {
      thumbPromises.push(
        new Promise(async (resolve) => {
          const pageData = await (
            await pdfjs.getDocument(pdfUrl).promise
          ).getPage(i);
          const viewport = pageData.getViewport({ scale: 0.2 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          const renderTask = pageData.render(renderContext);
          renderTask.promise.then(() => {
            const thumbnail = canvas.toDataURL("image/png");
            resolve(thumbnail);
          });
        })
      );
    }

    Promise.all(thumbPromises).then((thumbnails) => {
      setThumbnails([...thumbnails]);
    });
  };

  useEffect(() => {
    setNumPages(null)
    setThumbnails([])
  }, [pdfUrl])

  useEffect(() => {
    generateThumbnails();

    // eslint-disable-next-line
  }, [numPages]);

  useEffect(() => {
    const preventRightClick = (event) => {
      event.preventDefault();
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener('contextmenu', preventRightClick);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener('contextmenu', preventRightClick);
      }
    };
  }, [])

  return (
    <div className="pdf-viewer-wrapper" ref={containerRef}>
      <div className="pdf-viewer">
        <div className="pdf-container">
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {/* {[...Array(numPages)].map((_, index) => (
              <Page
                key={index}
                pageNumber={index + 1}
                width={600}
                scale={scale}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                _className="pdf-page"
              />
            ))} */}
            {
              <Page
                pageNumber={currentPage}
                width={600}
                scale={scale}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                _className="pdf-page"
              />
            }
          </Document>
        </div>
        {numPages && (
          <div className="controls-wrapper">
            <div className="controls">
              <Typography
                variant="body-3"
                label={`${currentPage} / ${numPages}`}
              />
              <Divider
                type="vertical"
                className="divider"
                style={{ borderColor: "#75777a", margin: 0 }}
              />
              <ZoomIn onClick={zoomIn} />
              <ZoomOut onClick={zoomOut} />
            </div>
          </div>
        )}
      </div>
      <div className="thumbnails">
        {thumbnails.map((thumbnail, index) => (
          <div
            key={index}
            className={`thumbnail ${index + 1 === currentPage ? "active" : ""}`}
            onClick={() => handlePageChange(index + 1)}
          >
            <img
              src={thumbnail}
              className="thumbnail_img"
              alt={`Thumbnail ${index + 1}`}
            />
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
};
