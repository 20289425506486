import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useRouter } from "../../../utils/router/customRouterHook";
import moment from "moment";
import {
  disableLoader,
  enableLoader,
  getContributionValidationReport,
} from "../../../redux/actions";
import { SpinLoader } from "../../../components/common/SpinLoader";
import {
  capitalizeFirstLetter,
  fixAmountFormat,
  getLocalTimezone,
  getReportURL,
  renderContrStatusLabel,
  renderContrStatusTag,
} from "../../../utils";
import {
  BENEFIT_ACCOUNT_TYPES,
  CONTRIBUTION_STATUSES,
  DATE_FORMAT,
} from "../../../utils/constants";
import { Row, Col, Card, Table } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import {
  ContributionActions,
  ContributionSummary,
} from "../../../components/admin/contribution";
import { Typography } from "../../../design-systems";

function ContributionProgress() {
  const dispatch = useDispatch();
  const router = useRouter();


  const [reportURL, setReportURL] = useState(null);
  const [runSummary, setRunSummary] = useState(null);
  const [divisionSummary, setDivisionSummary] = useState([]);
  const [employeeSummary, setEmployeeSummary] = useState([]);

  const id = router.match.params.id;
  const defaultPage = 1;
  const defaultLimit = 10;


  useEffect(() => {
    getReport(defaultPage, defaultLimit);

    // eslint-disable-next-line
  }, []);

  const runDetailsColumns = [
    {
      title: "Employee Name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      render: (row) => {
        const fullName = `${row.first_name} ${row.last_name}`;
        return (
          <Link className="label-name" to="#">
            {capitalizeFirstLetter(fullName)}
          </Link>
        );
      },
    },
    {
      title: "Emp. Id",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "SSN",
      dataIndex: "ssn",
      key: "employee_id",
    },
    {
      title: "Tax year",
      dataIndex: "tax_year",
      key: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.tax_year - b.tax_year,
    },
    {
      title: "Division",
      dataIndex: "division_name",
      key: "employee_id",
    },
    {
      title: "Employer $",
      dataIndex: "employer_contribution",
      key: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employer_contribution - b.employer_contribution,
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Employee $",
      dataIndex: "employee_contribution",
      key: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employee_contribution - b.employee_contribution,
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Total $",
      dataIndex: "total_contribution",
      key: "employee_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.total_contribution - b.total_contribution,
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Status",
      dataIndex: "contribution_status",
      key: "employee_id",
      render: (status) => renderContrStatusTag(status, "contrEmp"),
    },
  ];

  const getReport = (page, limit) => {
    dispatch(
      getContributionValidationReport(id, page, limit, (res) => {
        setRunSummary(res.data.contribution_run_summary);
        setDivisionSummary(res.data.contribution_run_division_summary);
        setEmployeeSummary(res.data.contribution_run_employee_summary);
      }),
    );

    checkReportURL();
  };

  const checkReportURL = async () => {
    dispatch(enableLoader());

    const reportURL = await getReportURL(id);
    setReportURL(reportURL);

    dispatch(disableLoader());
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const divisionColumns = [
    {
      title: "Division Name",
      dataIndex: "division_name",
      key: "division_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.division_name.localeCompare(b.division_name),
    },
    {
      title: "#Employees",
      dataIndex: "employee_count",
      key: "employee_count",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.employee_count - b.employee_count,
    },
    {
      title: () =>
        runSummary?.contribution_status === CONTRIBUTION_STATUSES.SUCCESSFUL
          ? "Distributed Division Total"
          : "Division Total",
      key: "division_name",
      render: (row) =>
        row.contribution_status === CONTRIBUTION_STATUSES.SUCCESSFUL
          ? fixAmountFormat(row.exec_summary.div_contribution_amt_distributed)
          : fixAmountFormat(row.div_contribution_amt_calculated),
    },
    {
      title: "Status",
      dataIndex: "contribution_status",
      key: "division_name",
      render: (status) => renderContrStatusTag(status, "contrDiv"),
    },
  ];

  const propertyList = [
    {
      title: "Name",
      value: capitalizeFirstLetter(runSummary?.contribution_name),
      visible: true,
    },
    {
      title: "Payroll Date",
      value:
        runSummary?.contribution_date &&
        `${moment(runSummary?.contribution_date).format(
          DATE_FORMAT.FOR_UI_FIRST_MM,
        )}`,
      visible: true,
    },
    {
      title: "Status",
      value: capitalizeFirstLetter(
        renderContrStatusLabel(runSummary?.contribution_status),
      ),
      visible: true,
    },
    {
      title: "Funds Transfer Reference",
      value: runSummary?.exec_summary.ach_refernce_id,
      visible: true,
    },
    {
      title: "Contribution Time",
      value:
        runSummary?.exec_summary.exec_end_time &&
        `${moment
          .utc(runSummary?.exec_summary.exec_end_time)
          .local()
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}`,
      visible: true,
    },
    {
      title: "Employee Count in File",
      value: runSummary?.employees_count,
      visible: true,
    },
    {
      title: "Employee Contributions Completed",
      value: runSummary?.exec_summary.completed_employees_count,
      visible:
        runSummary?.contribution_status !==
        CONTRIBUTION_STATUSES.EXEC_IN_PROGRESS,
    },
    {
      title: "Contribution Total in File",
      value: fixAmountFormat(runSummary?.total_contribution_amt),
      visible: true,
    },
    {
      title: "Contribution Total Successful",
      value: fixAmountFormat(
        runSummary?.exec_summary.contribution_amt_distributed,
      ),
      visible:
        runSummary?.contribution_status !==
        CONTRIBUTION_STATUSES.EXEC_IN_PROGRESS,
    },
    {
      title: "Contribution Retry Comment",
      value: runSummary?.exec_summary.retry_remarks,
      visible: runSummary?.exec_summary.retry_remarks !== "",
    },
  ];

  const contributionRunDetailFilteredColumns = useMemo(() => {
    let filteredColumns = [...runDetailsColumns];

    if (
      divisionSummary?.length === 1 &&
      divisionSummary[0].division_name === "default"
    ) {
      filteredColumns = filteredColumns.filter(
        (col) => col.dataIndex !== "division_name",
      );
    }

    if (runSummary?.benefits_account_type === BENEFIT_ACCOUNT_TYPES.DCFSA) {
      filteredColumns = filteredColumns.filter(
        (col) => col.dataIndex !== "tax_year",
      );
    }

    return filteredColumns;
  }, [runSummary?.benefits_account_type, divisionSummary]);

  return (
    <HeaderWithContent>
      <Row gutter={0}>
        <Col span={24}>
          <Card
            title=""
            bordered={false}
            className="contribution-validation-report data-grid employee-listing-v2"

          >
            <Typography
              label="Contribution Summary"
              variant="subhead-1"
              className="employee-listing-title section-gap"
            />
            <SpinLoader showContent={runSummary}>
              <ContributionSummary propertyList={propertyList} />

              <ContributionActions
                reportURL={reportURL}
                runSummary={runSummary}
                updateReport={getReport}
              />

              {divisionSummary.length ? (
                <div className="division-summary">
                  <h3 className="pl-space">Division Summary </h3>
                  <div className="division-summary-data-grid">
                    <Table
                      columns={divisionColumns}
                      dataSource={
                        Array.isArray(divisionSummary) ? divisionSummary : []
                      }
                      onChange={onChange}
                      bordered
                      scroll={
                        (Array.isArray(divisionSummary) ? divisionSummary : [])
                          ?.length
                          ? { x: 1200 }
                          : undefined
                      }
                    />
                  </div>
                </div>
              ) : null}

              <div className="contribution-run-detail-grid">
                <h3 className="pl-space">Contribution Run Details</h3>
                <div className="division-summary-data-grid contribution-run-detail-data-grid">
                  <Table
                    columns={contributionRunDetailFilteredColumns}
                    dataSource={
                      Array.isArray(employeeSummary) ? employeeSummary : []
                    }
                    onChange={onChange}
                    pagination={{
                      onChange: (page, limit) => getReport(page, limit),
                      total: runSummary ? runSummary.employees_count : 0,
                      defaultCurrent: defaultPage,
                      defaultPageSize: defaultLimit,
                    }}
                    bordered
                    scroll={
                      (Array.isArray(employeeSummary) ? employeeSummary : [])
                        ?.length
                        ? { x: 1200 }
                        : undefined
                    }
                    className="contribution-run-detail-table"
                  />
                </div>
              </div>
            </SpinLoader>
          </Card>
        </Col>
      </Row>
    </HeaderWithContent>
  );
}

export { ContributionProgress };
