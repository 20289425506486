import { Collapse as AntCollapse } from "antd";
import { copayStep } from "../../../../../utils";
import { PanelHeader } from "../PanelHeader";
import { useEffect, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useRouter } from "../../../../../utils/router";
import { useSelector } from "react-redux";

const { Panel } = AntCollapse;

export const Copays = ({ form }) => {
  const [expandedPanels] = useState(copayStep.map(({ key }) => key));
  const router = useRouter()
  const { employerId } = useSelector((state) => state.employer)
  useEffect(() => {
    if (!employerId) {
      router.push('/admin/employer/add/1')
    }
  }, [employerId])
  return (
    <Collapse
      activeKey={expandedPanels}
      panels={copayStep.map(({ content, title, key }) => (
        <Panel
          header={<PanelHeader label={title} />}
          key={key}
          showArrow={false}
        >
          {content({ form })}
        </Panel>
      ))}
    />
  );
};
