import { Col, Row } from "antd";
import { PersonalInformation } from "./PersonalInformation";
import { EmploymentInformation } from "./EmploymentInformation";
import { PlanInformation } from "./PlanInformation";

export const EmployeeDetails = ({ planInfo, record, getEmployeeData, employee }) => {

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12} lg={12}>
        <PersonalInformation getEmployeeData={getEmployeeData} />
      </Col>
      <Col xs={24} md={12} lg={12}>
        <EmploymentInformation getEmployeeData={getEmployeeData} />
      </Col>
      <Col xs={24}>
        <PlanInformation planInfo={planInfo} record={record} getEmployeeData={getEmployeeData} employee={employee} />
      </Col>
    </Row>
  );
};
