import { Form } from 'antd';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker2 = ({
    name,
    onChange,
    label,
    format = "DD-MM-YYYY",
    selected,
    rules,
    filterDate,
    disabled,
    readOnly = true,
    onKeyDown,
    onInput,
    onKeyPress,
    id,
    placeholder,
    isClearable,
    maxDate,
    showImportant = true,
    selectsStart,
    selectsEnd,
    startDate,
    minDate,
    endDate,
    selectsRange,
    inline,
    className,
    customClassName,
    showYearPicker,
    yearPickerHeadline = "Year",
    yearItemNumber = 9
}) => {
    const [years] = useState(() => {
        const startYear = 1900;
        const currentYear = 9999;
        return Array.from({ length: ((currentYear - startYear) + 1) }, (_, index) => startYear + index);
    });

    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    return (
        <Form.Item
            name={name}
            rules={rules}
            className={`date-picker-wrapper2 ${customClassName}`}
        >
            <label className='date-picker-label' htmlFor="date_of_birth">{label}
                {
                    showImportant &&
                    <span className="required-indicator">*</span>
                }
            </label>
            <DatePicker
                autoComplete="off"
                selectsRange={selectsRange}
                inline={inline}
                selectsStart={selectsStart}
                selectsEnd={selectsEnd}
                startDate={startDate}
                minDate={minDate}
                endDate={endDate}
                label={label}
                showIcon
                id={id}
                isClearable={isClearable}
                toggleCalendarOnIconClick
                className={`${className} date-picker-wrapper2`}
                selected={selected}
                disabled={disabled}
                placeholderText={placeholder}
                onChange={date => {
                    onChange(date);
                }}
                
                maxDate={maxDate}
                dateFormat={format}
                filterDate={filterDate}
                readOnly={readOnly}
                onKeyDown={ (e) => e.preventDefault() }
                onInput={onInput}
                onKeyPress={(e) => e.preventDefault()}
                showYearPicker={showYearPicker}
                yearItemNumber={yearItemNumber}
                renderCustomHeader={
                 !showYearPicker ? 
                                    ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();
                    const currentMonth = currentDate.getMonth();
                    const futureDateSelectionDisabled = maxDate && (currentYear > maxDate.getFullYear() || (currentYear === maxDate.getFullYear() && currentMonth >= maxDate.getMonth()));

                    return (
                        <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <select
                                value={date?.getFullYear()}
                                onChange={({ target: { value } }) => {
                                    const selectedYear = Number(value);
                                    const newDate = new Date(date);
                                    newDate.setFullYear(selectedYear);
                                    if (maxDate && selectedYear === maxDate.getFullYear() && date?.getMonth() > maxDate.getMonth()) {
                                        changeMonth(maxDate.getMonth());
                                    }
                                    changeYear(selectedYear);
                                }}
                            >
                                {years.map(option => (
                                    futureDateSelectionDisabled && option > currentYear ? null : (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    )
                                ))}
                            </select>
                            <select
                                value={date?.getMonth()}
                                onChange={({ target: { value } }) => {
                                    const selectedMonth = parseInt(value, 10);
                                    const currentYear = date?.getFullYear();
                                    const currentMonth = date?.getMonth();
                                    const currentYearMonth = currentDate.getFullYear() * 12 + currentDate.getMonth();
                                    const selectedYearMonth = currentYear * 12 + selectedMonth;
                                    if (!futureDateSelectionDisabled || currentYear < currentDate.getFullYear() || (currentYear === currentDate.getFullYear() && selectedYearMonth <= currentYearMonth)) {
                                        changeMonth(selectedMonth);
                                    } else {
                                        changeMonth(currentMonth);
                                    }
                                }}
                            >
                                {months.map((option, index) => {
                                    const monthYear = new Date(date.getFullYear(), index);
                                    if (!maxDate || monthYear <= maxDate) {
                                        if (!futureDateSelectionDisabled || (date.getFullYear() < currentYear || (date.getFullYear() === currentYear && index <= currentMonth))) {
                                            return (
                                                <option key={option} value={index}>
                                                    {option}
                                                </option>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </select>
                            <button
                                type="button"
                                onClick={() => {
                                    increaseMonth();
                                    const currentDate = date || new Date();
                                    const nextDate = new Date(currentDate);
                                    nextDate.setMonth(nextDate.getMonth() + 1);
                                }}
                                disabled={nextMonthButtonDisabled || (maxDate && date >= maxDate)}
                            >
                                {">"}
                            </button>
                        </div>
                    )
                } : (e) => {                    
                     return (  
                     <div style={{ margin: 10, display: "flex", justifyContent: "center" }}> 
                        <button type="button" onClick={e.decreaseYear} disabled={e.prevYearButtonDisabled}>{"<"}</button>
                           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}> {yearPickerHeadline} : {selected ? selected.getFullYear() : "Not selected"} </div> 
                        <button type="button" onClick={e.increaseYear} disabled={e.nextYearButtonDisabled}>{">"}</button>
                    </div>)}}
                icon={<div>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em" fill="#75777a" aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
                </div>}
            />
        </Form.Item>
    );
};

export default DatePicker2;
