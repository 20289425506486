import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from "antd";
import { useDispatch } from "react-redux";
import {
  TextField,
  DatePicker,
  Select,
} from "../../../../../design-systems";
import { required } from "../../../../../utils/validations";
import { getEmployeeRunoutOptions } from '../../../../../redux/actions';
import { handleKeyDown } from '../../../../../utils';
import DatePicker2 from '../../../../../design-systems/DatePicker2';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../utils/constants';

export const DCFSAEmpPlanDetails = ({ form, planInfo, formData, setFormData, page, isInputRequired }) => {
  const dispatch = useDispatch();
  const isAddPlanPage = page === "addPlanPage";

  useEffect(() => {
    if (!isAddPlanPage) {
      dispatch(getEmployeeRunoutOptions())
    }
  }, [])
  const planYear = useMemo(() => {
    const { plan_config_id, plan_end_date, plan_start_date } = planInfo || {};
    const yearInfo = []
    if (plan_config_id && plan_start_date && plan_end_date) {
      yearInfo.push({
        label: `${moment(plan_start_date).format(DATE_FORMAT?.FOR_UI_FIRST_MM)} - ${moment(plan_end_date).format(DATE_FORMAT?.FOR_UI_FIRST_MM)}`,
        value: plan_config_id
      });
    } else {
      yearInfo.push({
        label: 'No Data',
        value: null
      });
    }
    return yearInfo
  }, [planInfo])

  const isPlanYearNull = planYear.every(plan => plan?.value === null)

  const applyRequiredRule = isAddPlanPage ? isInputRequired : true;

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <DatePicker2
          customClassName={isAddPlanPage ? "transactions-datepicker" : ""}
          className={isAddPlanPage ? "transactions-datepicker" : ""}
          isClearable={true}
          id="dcfsa_plan_effective_date"
          selected={formData["dcfsa_plan_effective_date"]}
          name="dcfsa_plan_effective_date"
          showImportant={applyRequiredRule}
          rules={applyRequiredRule ? [required("Effective date")] : []}
          label="Effective date"
          placeholder="MM/DD/YYYY"
          format={['MM/dd/yyyy', 'M/d/yyyy']}
          readOnly={false}
          onKeyDown={handleKeyDown}
          onChange={(date) => {
            form.setFieldsValue({ 'dcfsa_plan_effective_date': date });
            setFormData(((prevData) => ({
              ...prevData,
              'dcfsa_plan_effective_date': date
            })))
          }}
        />
      </Col>
      {
        isAddPlanPage &&
        <Col xs={24} md={12}>
          <Select
            className="hsa-plan-coverage-type"
            disabled={isPlanYearNull}
            label="Plan year"
            placeholder="Select plan year"
            name="dcfsa_plan_config_id"
            options={planYear}
            rules={isInputRequired ? [
              required("Plan year"),
            ] : []}
          />
        </Col>
      }
      <Col xs={24} md={12}>
        <TextField
          label="Election amount"
          placeholder="00.00"
          name="dcfsa_employee_amount"
          rules={applyRequiredRule ? [required("Election amount")] : []}
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          onPaste={(event) => {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('Text');

            if (!/^\d+$/.test(pastedText)) {
              event.preventDefault();
            }
          }}
          prefix="$"
          type="text"
        />

      </Col>
      <Col xs={24} md={12}>
        <TextField
          label="Employer contribution "
          placeholder="00.00"
          name="dcfsa_employer_amount"
          // fsa_employer_amount
          prefix="$"
          type="text"
          onKeyPress={(event) => {
            if (!/[0-9.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onInput={(e) => {
            const value = e.target.value;
            if (value === "") return;
            if (!/^\d*\.?\d{0,2}$/.test(value)) {
              e.target.value = parseFloat(value).toFixed(2);
            }
          }}
          onPaste={(event) => {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('Text');

            if (!/^\d+$/.test(pastedText)) {
              event.preventDefault();
            }
          }}
        />
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          name="plan_plan_effective_date"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          name="dcfsa_account_type"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  )
};
