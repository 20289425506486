import { Checkbox, Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CheckBoxForGroup } from "../../../../../design-systems";
import { disableGlobalLoader, enableGlobalLoader, getCurrentEmployerDetailsByTab } from "../../../../../redux/actions";
import { planInformationEmpKeys, planOptions } from "../../../../../utils";

export const SelectPlanInfo = ({ form, setPlansExt }) => {
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(enableGlobalLoader())
    dispatch(getCurrentEmployerDetailsByTab(planInformationEmpKeys?.PLAN_INFO, (res) => {
      const plans = res?.data?.plan_information?.selected_plans?.map(plan =>  ({
        label: plan,
        value: plan
      }));
      setPlans(plans)
      dispatch(disableGlobalLoader())
    }))
  }, [])

  const customValidation = () => {
    if (
      !form.getFieldValue("plans") ||
      form.getFieldValue("plans")?.length < 1
    ) {
      return Promise.reject(
        "Please select at least one plan to continue.",
      );
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={16}>
      <Col xs={19} md={19} className="plan-cols">
        <Form.Item name="plans" className="plans-box">
          <Checkbox.Group>
            <Row>
              {" "}
              {plans.map(({ label, value }) => (
                <Col span={5}>
                  <CheckBoxForGroup
                    label={label}
                    value={value}
                    onChange={(e) => {
                      const plans = form.getFieldValue("plans") || [];

                      if (e.target.checked) {
                        setPlansExt([...plans, e.target.value]);
                      } else {
                        setPlansExt([
                          ...plans.filter((plan) => plan !== e.target.value),
                        ]);
                      }

                      const plansLength = e.target.checked
                        ? (form.getFieldValue("plans")?.length || 0) + 1
                        : (form.getFieldValue("plans")?.length || 0) - 1;

                    }}
                  />
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item
          rules={[{ validator: customValidation }]}
          name="plans_err"
          className="dummy-error should-update-form-item"
        ></Form.Item>
      </Col>
    </Row>
  );
};
