import axios from "./axiosInterceptors";
import defaultAxios from "axios";
import moment from "moment";
import Papa from "papaparse";
import { DATE_FORMAT, STATUS_CODES } from "../utils/constants";
import { formatDate } from "../utils";
import {
  backendCheckEmployees,
  duplicateCheck,
  errorInfo,
  getHeaderMappings,
  validateAll,
} from "../utils/validations";

function isValidEmployeesPayload(employees) {
  var payload = { employee: [] };
  for (let i = 0; i < employees.length; i++) {
    const { workEmail, employeeID, SSN } = employees[i];
    payload["employee"].push({
      work_email: workEmail.trim(),
      employee_id: employeeID.trim(),
      ssn: SSN,
    });
  }
  if (payload["employee"].length === 0) {
    payload["employee"].push({
      work_email: "someone@something.com",
      employee_id: "AAAAAAAAAAAAAAAA",
      ssn: 123456789,
    });
  }
  return payload;
}

export const getAllEmployeesWithFiltersService = async (data, signal) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/employee/summary",
      data,
      signal
    }

    const response = await axios(request);

    const returnData = {
      error: false,
      employees: response.data.data,
      current_page: response.data.current_page,
      total_records: response.data.total_records
    };
    return returnData;
  } catch (error) {
    throw error;
  }
}

export const getAllEmployeesService = async (data) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employee/summary",
      params: data
    };

    const response = await axios(request);

    const returnData = {
      error: false,
      employees: response.data.data,
      current_page: response.data.current_page,
      total_records: response.data.total_records
    };
    return returnData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEmployeePlanDataService = async (employeeID) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/employee/${employeeID}/plans`,
      params: { employee_id: employeeID },
    }
    const response = await axios(request);
    return response
  } catch (error) {
    throw error;
    // return { error: true, success: null };
  }
}

export const updatePersonalDetailsService = async (employeeID, data) => {
  try {
    const request = {
      method: "patch",
      url: `/employer/payroll/employee/${employeeID}/personal`,
      data
    }
    const response = await axios(request);
    return response
  } catch (error) {
    console.log(error, "89s 83");
    throw error;
    // return { error: true, success: null };
  }
}

export const updateEmploymentDetailsService = async (employeeID, data) => {
  try {
    const request = {
      method: "patch",
      url: `/employer/payroll/employee/${employeeID}/employment`,
      data
    }
    const response = await axios(request);
    return response
  } catch (error) {
    throw error;
    // return { error: true, success: null };
  }
}

export const getEmployeeService = async (employeeID) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/employee/${employeeID}/details`,
      params: { employee_id: employeeID },
    };

    const response = await axios(request);
    const data = response?.data?.data;

    const returnData = { error: false, employee: {} };

    returnData.employee.employeeID = {
      value: data.employee_id,
      canEdit: data.employee_id_editable,
    };
    returnData.employee.employeeDateOfJoining = {
      value: data.employment_start_date ? moment(
        data.employment_start_date,
        DATE_FORMAT.FOR_SERVER,
      ).format(DATE_FORMAT.FOR_UI_FIRST_MM) : null,
      canEdit: data.employment_start_date_editable,
    };
    returnData.employee.employmentStatus = {
      value: data.employment_status_id,
      canEdit: data.employment_status_id_editable,
    };
    returnData.employee.firstName = {
      value: data.first_name,
      canEdit: data.first_name_editable,
    };
    returnData.employee.middleName = {
      value: data.middle_name,
      canEdit: data.middle_name_editable,
    };
    returnData.employee.lastName = {
      value: data.last_name,
      canEdit: data.last_name_editable,
    };
    returnData.employee.addr1 = {
      value: data.residential_address_1,
      canEdit: data.residential_address_1_editable,
    };
    returnData.employee.addr2 = {
      value: data.residential_address_2,
      canEdit: data.residential_address_2_editable,
    };
    returnData.employee.city = {
      value: data.residential_city,
      canEdit: data.residential_city_editable,
    };
    returnData.employee.st = {
      value: data.residential_state,
      canEdit: data.residential_state_editable,
    };
    returnData.employee.postal = {
      value: data.residential_postal_code,
      canEdit: data.residential_postal_code_editable,
    };
    returnData.employee.country = {
      value: data.country,
      canEdit: data.country_editable,
    };
    returnData.employee.workEmail = {
      value: data.work_email,
      canEdit: data.work_email_editable,
    };
    returnData.employee.userStatus = {
      value: data.user_status,
      canEdit: data.user_status_editable,
    };
    returnData.employee.coverage = {
      value: data.coverage,
      canEdit: data.coverage_editable,
    };
    returnData.employee.HSAPlanEligibilityDate = {
      value: moment(
        data.hsa_plan_eligibility_dt,
        DATE_FORMAT.FOR_SERVER,
      ).format(DATE_FORMAT.FOR_UI_FIRST_MM),
      canEdit: data.hsa_plan_eligibility_dt_editable,
    };
    returnData.employee.coverageTerminationDate = {
      value: data.coverage_termination_date
        ? moment(data.coverage_termination_date, DATE_FORMAT.FOR_SERVER).format(
          DATE_FORMAT.FOR_UI_FIRST_MM,
        )
        : "",
      canEdit: data.coverage_termination_date_editable,
    };
    returnData.employee.coverageEffectiveDate = {
      value: data.coverage_effective_date
        ? moment(data.coverage_effective_date, DATE_FORMAT.FOR_SERVER).format(
          DATE_FORMAT.FOR_UI_FIRST_MM,
        )
        : "",
      canEdit: data.coverage_effective_date_editable,
    };
    returnData.employee.employmentTerminationDate = {
      value: data.employment_termination_date
        ? moment(
          data.employment_termination_date,
          DATE_FORMAT.FOR_SERVER,
        ).format(DATE_FORMAT.FOR_UI_FIRST_MM)
        : "",
      canEdit: data.employment_termination_date_editable,
    };
    returnData.employee.HSAPlanStartDate = {
      value: moment(data.hsa_start_dt, DATE_FORMAT.FOR_SERVER).format(
        DATE_FORMAT.FOR_UI_FIRST_MM,
      ),
      canEdit: data.hsa_start_dt_editable,
    };
    returnData.employee.mobileNum = {
      value: data.mobile_phone_num !== "-999" ? data.mobile_phone_num : null,
      canEdit: data.mobile_phone_num_editable,
    };
    returnData.employee.SSN = {
      value: data.ssn,
      canEdit: data.ssn_editable,
    };
    returnData.employee.DOB = {
      value: moment(data.date_of_birth, DATE_FORMAT.FOR_SERVER).format(
        DATE_FORMAT.FOR_UI_FIRST_MM,
      ),
      canEdit: data.date_of_birth_editable,
    };
    returnData.employee.socureReferenceID = {
      value: data.socure_reference_id,
      canEdit: data.socure_reference_id_editable,
    };
    returnData.employee.user_status_msg = {
      value: data.user_status_msg,
      canEdit: data.user_status_msg_editable,
    };
    returnData.employee.division_name = {
      value: data.division_id,
      canEdit: data.division_id_editable,
    };
    returnData.employee.divisionId = {
      value: data.division_id,
      canEdit: data.division_id_editable,
    };
    returnData.employee.userStatus = {
      value: data.user_status,
      canEdit: data.user_status_editable,
    };
    returnData.employee.userStatusId = {
      value: data.user_status_id,
      canEdit: data.user_status_id_editable,
    };
    returnData.employee.allowKYC = {
      value: response.data.data.allow_kyc,
    };
    returnData.employee.accountHolderStatus = {
      value: data.account_holder_status,
    };
    returnData.employee.kycStatus = {
      value: data.kyc_status,
    };
    returnData.employee.captureKyc = {
      value: data.capture_kyc,
    };
    return returnData;
  } catch (error) {
    throw error
  }
};

export const updateKYCStatusService = async (data) => {
  try {
    const response = await axios.patch(
      "/employer/payroll/employee/kyc/manual/internal",
      data,
    );
    return { error: false, data: response.data.body || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateSocureKYCStatusService = async (data) => {
  try {
    const response = await axios.patch(
      "/employer/payroll/employee/kyc/manual/socure",
      data,
    );

    return { error: false, data: response.data.body || [] };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateEmployeeService = async (data, status) => {
  try {
    const request = {
      method: "patch",
      url: "/employer/payroll/register/employees",
      data,
    };

    const response = await axios(request);

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCountries = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/country",
    };

    const response = await axios(request);

    const returnData = { error: false, countries: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { code_numeric, name } = response.data.body.data[i];
      returnData.countries[name] = code_numeric;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    return { error: true, countries: null };
  }
};

const getEmploymentStatuses = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employee/employmentstatus/all",
    };

    const response = await axios(request);

    const returnData = { error: false, employmentStatuses: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { id, code } = response.data.body.data[i];
      returnData.employmentStatuses[code] = id;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    return { error: true, employmentStatuses: null };
  }
};

const getCoverages = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/coveragetype",
    };

    const response = await axios(request);

    const returnData = { error: false, coverages: {} };
    for (let i = 0; i < response.data.body.data.length; i++) {
      const { id, code } = response.data.body.data[i];
      returnData.coverages[code] = id;
    }
    return returnData;
  } catch (error) {
    console.log(error);
    return { error: true, coverages: null };
  }
};

const validateEmployees = async (employees) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/is_valid/employees",
      data: isValidEmployeesPayload(employees),
    };

    const response = await axios(request);

    const returnData = { error: false, validate: [] };
    return prepareValidateResponse(returnData, response.data?.body);
  } catch (error) {
    console.log(error);
    const returnData = { error: true, validate: [] };
    return prepareValidateResponse(
      returnData,
      error.response?.data?.error?.message,
    );
  }
};

export const getEmployeesFromCSVService = async (file, divisionList) => {
  //Parse CSV to JSON
  const papaPromise = (importFile) =>
    new Promise((resolve, reject) => {
      const csvData = [];
      Papa.parse(importFile, {
        header: false,
        skipEmptyLines: true,
        error: function (err, file, inputElem, reason) {
          console.log(reason);
          reject(err);
        },
        step: function (results, parser) {
          csvData.push(results.data);
          // resolve(results)
        },
        complete: function (results) {
          resolve(csvData);
        },
      });
    });

  try {
    const [countries, employmentStatuses, coverages] = await Promise.all([
      getCountries(),
      getEmploymentStatuses(),
      getCoverages(),
    ]);
    if (countries.error || employmentStatuses.error || coverages.error) {
      return { error: true, employees: null };
    }

    const csvData = await papaPromise(file);
    const header = csvData[0];
    const { validHeaders2 } = getHeaderMappings(header);
    const numRecords = csvData.length - 1;
    const numColumns = csvData[0].length;
    let employees = [];

    for (let i = 1; i < numRecords + 1; i++) {
      const entry = {};
      for (let j = 0; j < numColumns; j++) {
        const key = validHeaders2[header[j].toLowerCase()];
        const value =
          key === "workEmail" ? csvData[i][j].toLowerCase() : csvData[i][j];
        entry[key] = value;
      }
      entry.errors = validateAll(entry, {
        countries: countries.countries,
        coverages: coverages.coverages,
        employmentStatuses: employmentStatuses.employmentStatuses,
        divisionList,
      });
      employees.push(entry);
    }

    employees = duplicateCheck(employees);
    let backendValidation = await validateEmployees(
      backendCheckEmployees(employees),
    );
    if (backendValidation === null) return { error: true, employees: null };

    const backendWorkEmailMap = {},
      backendEmployeeIDMap = {},
      backendSSNMap = {};
    for (let i = 0; i < backendValidation.validate.length; i++) {
      const entry = backendValidation.validate[i];
      const {
        employeeID,
        workEmail,
        employeeIDState,
        workEmailState,
        ssn,
        SSNState,
      } = entry;
      if (employeeID !== "AAAAAAAAAAAAAAAA") {
        if (employeeIDState !== "valid")
          backendEmployeeIDMap[employeeID] = employeeIDState;
      }
      if (workEmail !== "someone@something.com") {
        if (workEmailState !== "valid")
          backendWorkEmailMap[workEmail] = workEmailState;
      }
      if (ssn !== 123456789) {
        if (SSNState !== "valid") backendSSNMap[ssn] = SSNState;
      }
    }

    //Update employees by looping through backendValidation
    for (let i = 0; i < employees.length; i++) {
      employees[i].index = i;
      employees[i].name = employees[i].firstName + " " + employees[i].lastName;

      const { workEmail, employeeID, SSN } = employees[i];
      if (employees[i].errors.workEmailState === "valid") {
        if (workEmail in backendWorkEmailMap) {
          employees[i].errors.workEmailState = backendWorkEmailMap[workEmail];
        }
      }
      if (employees[i].errors.employeeIDState === "valid") {
        if (employeeID in backendEmployeeIDMap) {
          employees[i].errors.employeeIDState =
            backendEmployeeIDMap[employeeID];
        }
      }
      if (employees[i].errors.SSNState === "valid") {
        if (SSN in backendSSNMap) {
          employees[i].errors.SSNState = backendSSNMap[SSN];
        }
      }
    }
    let errorInf = errorInfo(employees);
    if (errorInf.allOK) {
      for (let i = 0; i < employees.length; i++) {
        employees[i].countryID = countries.countries[employees[i].country];
        employees[i].employmentStatusID =
          employmentStatuses.employmentStatuses[employees[i].employmentStatus];
        employees[i].coverageID = coverages.coverages[employees[i].coverage];
        employees[i].DOB = moment(
          employees[i].DOB,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER);
        employees[i].employeeDateOfJoining = moment(
          employees[i].employeeDateOfJoining,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER);
        employees[i].HSAPlanEligibilityDate = moment(
          employees[i].HSAPlanEligibilityDate,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER);
        employees[i].HSAPlanStartDate = moment(
          employees[i].HSAPlanStartDate,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER);
        employees[i].CoverageEffectiveDate = moment(
          employees[i].CoverageEffectiveDate,
          DATE_FORMAT.FOR_UI_FIRST_MM,
        ).format(DATE_FORMAT.FOR_SERVER);
      }
    }

    return { error: false, employees: employees, ...errorInf };
  } catch (error) {
    console.log(error);
    return { error: true, employees: null };
  }
};

const prepareValidateResponse = (returnData, source) => {
  const data = returnData;
  for (let i = 0; i < source?.length; i++) {
    const {
      employee_id,
      employee_id_validation,
      work_email,
      work_email_validation,
      ssn,
      ssn_validation,
    } = source[i];
    const employee = {
      employeeID: employee_id,
      workEmail: work_email,
      employeeIDState: employee_id_validation,
      workEmailState: work_email_validation,
      ssn: ssn,
      SSNState: ssn_validation,
    };

    if (employee_id_validation) {
      const splitEmployeeID = employee_id_validation.split(" : ");
      if (splitEmployeeID[splitEmployeeID.length - 1] === "Pass") {
        employee.employeeIDState = "valid";
      } else if (
        splitEmployeeID[splitEmployeeID.length - 1] === "Duplicate Record"
      ) {
        employee.employeeIDState = "Duplicate Employee ID.";
      } else if (splitEmployeeID[splitEmployeeID.length - 1]) {
        employee.employeeIDState = splitEmployeeID[splitEmployeeID.length - 1];
      }
    }
    if (work_email_validation) {
      const splitWorkEmail = work_email_validation.split(" : ");
      if (splitWorkEmail[splitWorkEmail.length - 1] === "Pass") {
        employee.workEmailState = "valid";
      } else if (
        splitWorkEmail[splitWorkEmail.length - 1] === "Duplicate Record"
      ) {
        employee.workEmailState = "Duplicate work email.";
      } else if (
        splitWorkEmail[splitWorkEmail.length - 1] === "Domain name mismatch"
      ) {
        employee.workEmailState = "Email domain name is mismatched.";
      } else if (splitWorkEmail[splitWorkEmail.length - 1]) {
        employee.workEmailState = splitWorkEmail[splitWorkEmail.length - 1];
      }
    }
    if (ssn_validation) {
      const splitSSN = ssn_validation.split(" : ");
      if (splitSSN[splitSSN.length - 1] === "Pass") {
        employee.SSNState = "valid";
      } else if (splitSSN[splitSSN.length - 1] === "Duplicate Record") {
        employee.SSNState = "Duplicate SSN.";
      } else if (splitSSN[splitSSN.length - 1]) {
        employee.SSNState = splitSSN[splitSSN.length - 1];
      }
    } else {
      data.error = true;
    }

    data.validate.push(employee);
  }
  return data;
};

function addEmployeesPayloadBYFile(
  employees,
  runkyc = 1,
  isFile = false,
  fileName = "NA",
  divisionList,
) {
  let file = fileName.employeesFile;

  const payload = {
    upload_type: isFile ? "file" : "payload",
    file_for: "employee",
    upload_file_name: file.name,
    run_kyc: Number(runkyc),
    payload: [],
  };
  for (let i = 0; i < employees.length; i++) {
    const {
      employeeID,
      employeeDateOfJoining,
      firstName,
      middleName,
      lastName,
      DOB,
      SSN,
      mobileNum,
      addr1,
      addr2,
      city,
      st,
      postal,
      workEmail,
      employmentStatusID,
      countryID,
      userStatusID,
      coverageID,
      division,
      CoverageEffectiveDate,
    } = employees[i];
    let divId = "";
    let isDefault = false;
    isDefault =
      Array.isArray(divisionList) &&
      divisionList.length === 1 &&
      divisionList[0].division_name === "default";

    if (isDefault) {
      divId = divisionList[0].division_id;
    } else {
      divisionList.forEach((el) => {
        if (el.division_name === division) {
          divId = el.division_id;
        }
      });
    }

    let entry = {
      employee_id: employeeID.trim(),
      employee_date_of_joining: employeeDateOfJoining,
      employment_status_id: employmentStatusID,
      first_name: firstName.trim(),
      middle_name: middleName ? middleName.trim() : "",
      last_name: lastName.trim(),
      date_of_birth: DOB,
      ssn: SSN,
      mobile_phone_num: mobileNum,
      address_line_1: addr1.trim(),
      address_line_2: addr2.trim(),
      city: city.trim(),
      state: st,
      postal_code: postal,
      country_code: countryID,
      work_email: workEmail.trim(),
      coverage_id: coverageID,
      hsa_plan_eligibility_dt: CoverageEffectiveDate,
      hsa_start_dt: CoverageEffectiveDate,
      account_type_id: 1,
      sent_at: formatDate(),
      division_id: divId ? divId : "",
      coverage_effective_date: CoverageEffectiveDate,
      employment_termination_date: null,
      coverage_termination_date: null,
    };

    if (userStatusID) entry["user_status_id"] = userStatusID;
    payload["payload"].push(entry);
  }
  let emPayload = { employee_payload: payload };
  const formData = new FormData();
  Object.keys(emPayload).forEach((key) => {
    if (typeof emPayload[key] == "object") {
      formData.append(key, JSON.stringify(emPayload[key]));
    } else {
      formData.append(key, emPayload[key]);
    }
  });
  formData.append("file_name", fileName.employeesFile);
  return formData;
}
export const addEmployeesService = async (
  employees,
  runkyc = 1,
  isFile = false,
  fileName = "NA",
  divisionList,
) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/register/employees",
      data: addEmployeesPayloadBYFile(
        employees,
        runkyc,
        isFile,
        fileName,
        divisionList,
      ),
    };

    const response = await axios(request);
    const returnData = {
      error: false,
      success: {
        inputs: response.data.body["Total Input Records"],
        added: response.data.body["New Employees Added"],
      },
    };
    return returnData;
  } catch (error) {
    console.log(error);
    return { error: true, success: null };
  }
};

export const getAllUploadedFilesService = async (data) => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/register/employees/file/list",
      params: data
    };

    const response = await axios(request);

    return { error: false, data: response.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUploadedFileDetailsService = async (fileID, page, limit) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/register/employees/file/${fileID}`,
      params: { file_id: fileID, page, limit },
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const cancelUploadedFileService = async (fileID) => {
  try {
    const request = {
      method: "patch",
      url: `/employer/payroll/register/employees/file/${fileID}/cancel`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadFileService = async (reqData) => {
  try {
    const request = {
      method: "post",
      url: "/employer/payroll/register/employees/file-upload",
      data: reqData,
    };

    const response = await axios(request);
    const xRayTraceId = response.headers['x-amzn-trace-id'];

    return { error: false, data: response.data.body || null, xRayTraceId };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const uploadFileS3Service = async (presignedUrl, file, traceId = "") => {
  try {
    const request = {
      method: "put",
      url: presignedUrl,
      data: file,
      headers: {
        "Content-Type": "text/csv",
        "Content-Security-Policy":
          "connect-src 'self' https://zenda-payroll-non-prod.s3.us-east-2.amazonaws.com/",
      },
    };
    if (traceId) {
      request.headers["x-amzn-trace-id"] = traceId;
    }

    await defaultAxios(request);

    return { error: false };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEmployeeDetailsFromFileService = async (fileID, userID) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/register/employee/file/${fileID}/${userID}`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateEmployeeDetailsFromFileService = async (
  fileID,
  userID,
  data,
) => {
  try {
    const request = {
      method: "put",
      url: `/employer/payroll/register/employee/file/${fileID}/${userID}`,
      data,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteEmployeeDetailsFromFileService = async (fileID, userID) => {
  try {
    const request = {
      method: "delete",
      url: `/employer/payroll/register/employee/file/${fileID}/${userID}`,
    };

    const response = await axios(request);

    return { error: false, data: response.data.data || null };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const executeUploadedFileService = async (fileID) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/register/employees/file/${fileID}/execute`,

      validateStatus: function (status) {
        return (
          status === STATUS_CODES.OK ||
          status === STATUS_CODES.REQUEST_TIMEOUT ||
          status === STATUS_CODES.GATEWAY_TIMEOUT
        );
      },
    };

    const response = await axios(request);

    return {
      error: false,
      status: response.status,
      data: response.data.data || null,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const employeeGlobalSearchService = async (keyword) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/employee/global/search`,
      params: { key_word: keyword },

      validateStatus: function (status) {
        return (
          status === STATUS_CODES.OK ||
          status === STATUS_CODES.REQUEST_TIMEOUT ||
          status === STATUS_CODES.GATEWAY_TIMEOUT
        );
      },
    };

    const response = await axios(request);

    return {
      error: false,
      status: response.status,
      data: response.data || null,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addNewEmployeeService = async (data, stepId) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/register/employee/validate/${stepId}`,
      data
    }
    const response = await axios(request)
    return response.data;
  } catch (error) {
    throw error
  }
}

export const updateWorkEmailService = async (data) => {
  try {
    const request = {
      method: "patch",
      url: "/zenda/support/active/user/email/update",
      data
    }
    const response = await axios(request)
    return response.data
  } catch (error) {
    throw error
  }
}

export const updateMFAService = async (data, action) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/support/mfa/${action}`,
      data
    }
    const response = await axios(request)
    return response.data
  } catch (error) {
    throw error
  }
}

export const addNewPlanService = async (id, data) => {
  try {
    const request = {
      method: "post",
      url: `/employer/payroll/employee/${id}/plans`,
      data
    }
    const response = await axios(request)
    return response.data
  } catch (error) {
    throw error
  }
}

export const editPlanService = async (id, data) => {
  try {
    const request = {
      method: "patch",
      url: `/employer/payroll/employee/${id}/plans`,
      data
    }
    const response = await axios(request)
    return response.data
  } catch (error) {
    throw error
  }
}

