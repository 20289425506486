import { DashboardIcon, Employee, Employer, ClaimsIcon, Search, Contribution, ReportIcon } from "../../design-systems";

// import { Employee } from "../../design-systems/Icons";

export const sideMenuRoutes = [
  {
    path: "/employers",
    name: "Employers",
    getIcon: () => <Employer className="sidebar-icon" />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN"],
    index: "1",
    childRoutes: ["/admin/employer/add"],
  },
  {
    path: "/claims",
    name: "Claims",
    getIcon: () => <ClaimsIcon className={""} />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN"],
    index: "2",
    childRoutes: ['/admin/claims/details'],
  },
  {
    path: "/search",
    name: "Search",
    getIcon: () => <Search className="sidebar-icon" />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN"],
    index: "3",
    childRoutes: [],
  },
  {
    path: "/employers1",
    name: "Current Employer",
    layout: "/admin",
    roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
    index: "9",
    childRoutes: ["/admin/employer-detail",],
    isCurrentEmployerRoute: true
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    mininame: "D",
    getIcon: () => <DashboardIcon />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
    index: "4",
    childRoutes: ["/admin/profile", "/admin/employer/ledger"],
  },
  {
    path: "/employees",
    name: "Employees",
    getIcon: () => <Employee />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
    index: "5",
    childRoutes: [
      "/admin/employee/add",
      "/admin/employees/employee-list",
      "/admin/add-employee",
      "/admin/employee-detail",
      "/admin/employees/upload-census-file",
      "/admin/employees/verification-census-file",
      "/admin/employees/edit-employee",
      "/admin/employee-detail",
      "/admin/employee-claims"],
  },
  {
    path: "/contributions/run-history",
    name: "Contributions",
    getIcon: () => <Contribution className="sidebar-contribution-icon" />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
    index: "6",
    childRoutes: [
      "/admin/create-contribution",
      "/admin/update-contribution",
      "/admin/contribution-progress",
      "/admin/contribution-validation-report",
    ],
  },
  // {
  //   path: "/reports1",
  //   name: "Reports Old",
  //   getIcon: () => <ReportIcon />,
  //   layout: "/admin",
  //   roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
  //   index: "11",
  //   childRoutes: [],
  // },
  {
    path: "/reports",
    name: "Reports",
    getIcon: () => <ReportIcon />,
    layout: "/admin",
    roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
    index: "7",
    childRoutes: [
      "/admin/reports/fsa",
      "/admin/reports/hsa",
    ],
    views: [],
    dynamicChildRoutes: true,
  },
  // {
  //   collapse: true,
  //   name: "Settings",
  //   icon: "ni ni-settings-gear-65",
  //   state: "settingsCollapse",
  //   roles: ["ZENDA_ADMIN", "SUPER_ADMIN"],
  //   index: "5",
  //   childRoutes: [],
  //   views: [
  //     {
  //       path: "/settings/employer",
  //       name: "Employers",
  //       miniName: "E",
  //       layout: "/admin",
  //       roles: ["ZENDA_ADMIN"],
  //       index: "5.1",
  //       childRoutes: [],
  //     },
  //     {
  //       path: "/settings/users-list",
  //       name: "Users",
  //       miniName: "U",
  //       layout: "/admin",
  //       roles: ["ZENDA_ADMIN", "SUPER_ADMIN", "ADMIN"],
  //       index: "5.2",
  //       childRoutes: ["/admin/settings/user-detail"],
  //     },
  //     {
  //       path: "/settings/banking",
  //       name: "Banking",
  //       miniName: "B",
  //       layout: "/admin",
  //       roles: ["ZENDA_ADMIN", "SUPER_ADMIN"],
  //       index: "5.3",
  //       childRoutes: [],
  //     },
  //   ],
  // },

  // {
  //   path: "/employers",
  //   name: "Employers",
  //   getIcon: () => <Employer className="sidebar-icon" />,
  //   layout: "/admin",
  //   roles: ["ZENDA_ADMIN"],
  //   index: "7",
  //   childRoutes: [ "/admin/employer/add"],
  // },
];
