import { useEffect, useState, useMemo } from "react";
import { Col, Form, Modal, Row } from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Select,
  TextField,
  Typography,
} from "../../../../../../../design-systems";
import { allowValidNumbers, mandatory, required } from "../../../../../../../utils/validations";
import { DATE_FORMAT } from "../../../../../../../utils/constants";
import DatePicker2 from "../../../../../../../design-systems/DatePicker2";
import { clearBodyObject, handleKeyDown } from "../../../../../../../utils";
import { editPlan, showSuccessMessage } from "../../../../../../../redux/actions";
import { langs } from "../../../../../../../i18n";

export const PlanInformationForm = ({
  id,
  visible,
  onCancel,
  planType,
  formData,
  setFormData,
  setChangedValues,
  getEmployeeData,
  planInfo
}) => {
  const isHsa = useMemo(() => planType === "HSA", [planType]);
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formChanged, setFormChanged] = useState(true);
  const { coverages, employeePlans } = useSelector((state) => state.employees);
  const coverageForSelect = useMemo(() => {
    if (coverages) {
      let coverage = Object.keys(coverages).map((cov) => ({
        label: cov,
        value: coverages[cov]
      }))
      return coverage
    }
  }, [coverages]);

  const validateEffectiveDate = (_, date) => {
    if (!date) return;
    if (moment(date).isBefore(selectedPlanDetails?.plan_effective_date, "day")) {
      return Promise.reject("Effective date cannot precede the current date");
    }
    return Promise.resolve("Valid")
  }

  const selectedPlanDetails = useMemo(() => {
    if (!employeePlans.length) return {}

    const plan = employeePlans.find(plan => plan?.plan_name === planType)
    if (plan !== null) return plan
  }, [employeePlans, planType])

  const onFinishFailed = (error) => {
  };

  const getCoverageTierValueFromLabel = (val) => {
    const selectedCoverage = coverageForSelect.find(cov => cov.label === val);
    if (selectedCoverage !== null) {
      return selectedCoverage?.value
    }
    return ""
  }

  const initialValues = {
    plan_effective_date: selectedPlanDetails?.plan_effective_date ? moment(selectedPlanDetails?.plan_effective_date).toDate() : null,
    plan_termination_date: selectedPlanDetails?.plan_termination_date ? moment(selectedPlanDetails?.plan_termination_date).toDate() : null,
    election_amount: Number(selectedPlanDetails?.election_amount) ?? 0,
    employer_amount: Number(selectedPlanDetails?.employer_amount) ?? 0,
    coverage_tier_id: getCoverageTierValueFromLabel(selectedPlanDetails?.coverage_tier)
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
      setFormData({
        ...formData,
        plan_effective_date: selectedPlanDetails?.plan_effective_date ? moment(selectedPlanDetails?.plan_effective_date).toDate() : null,
        plan_termination_date: selectedPlanDetails?.plan_termination_date ? moment(selectedPlanDetails?.plan_termination_date).toDate() : null
      });
    }
  }, [form, selectedPlanDetails, visible]);

  const onFinish = (values) => {
    const data = {
      id: selectedPlanDetails?.plan_id,
      account_type: selectedPlanDetails?.plan_name,
      ...values
    };
    const effectiveDateIsChanged = values?.plan_effective_date && moment(values?.plan_effective_date).isSame(initialValues?.plan_effective_date) ? false : true;
    const planEndDateIsChanged = values?.plan_termination_date ? values?.plan_termination_date && moment(values?.plan_termination_date).isSame(initialValues?.plan_termination_date) ? false : true : false;
    const electionAmountIsChanged = Number(initialValues?.election_amount) !== Number(values?.election_amount);
    const employerAmountIsChanged = Number(initialValues?.employer_amount) !== Number(values?.employer_amount);
    const coverageTierIsChanged = initialValues?.coverage_tier_id !== values?.coverage_tier_id;

    data.plan_effective_date = effectiveDateIsChanged ? moment(values?.plan_effective_date).format(DATE_FORMAT.FOR_SERVER) : null;
    data.coverage_tier_id = coverageTierIsChanged ? values?.coverage_tier_id : null;
    if (!isHsa) {
      data.plan_termination_date = planEndDateIsChanged ? moment(values?.plan_termination_date).format(DATE_FORMAT.FOR_SERVER) : null;
      data.election_amount = electionAmountIsChanged ? Number(values.election_amount) : null;
      data.employer_amount = employerAmountIsChanged ? Number(values?.employer_amount) : null;
    }

    const apiData = clearBodyObject(data);
    setLoading(true);
    dispatch(editPlan(id, apiData, (res) => {
      if (!(res instanceof Error)) {
        dispatch(showSuccessMessage(langs.messages.plan_updated_successfully));
        getEmployeeData();
        onCancel();
        setLoading(false);
      } else {
        setLoading(false);
      }
    }));
  }

  return (
    <Modal
      visible={visible}
      onCancel={(e) => {
        form.resetFields();
        setChangedValues(null);
        setFormData({});
        setFormChanged(true);
        onCancel(e);
      }}
      title={
        <Typography
          variant="subhead-2"
          label={`Edit ${planType} plan details`}
        />
      }
      width={700}
      footer=""
      className="plan-information-modal-wrapper"
    >
      <Form
        className="plan-information-form"
        layout="vertical"
        name="basic"
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onValuesChange={(values, allValues) => {
          const effectiveDateIsChanged = allValues?.plan_effective_date ? allValues?.plan_effective_date && moment(allValues?.plan_effective_date).isSame(initialValues?.plan_effective_date) ? false : true : false;
          const planEndDateIsChanged = allValues?.plan_termination_date ? allValues?.plan_termination_date && moment(allValues?.plan_termination_date).isSame(initialValues?.plan_termination_date) ? false : true : false;
          let employerAmountIsChanged = false, electionAmountIsChanged =false;
          if(!isHsa) {
            if (allValues?.employer_amount?.length) {
              employerAmountIsChanged = parseFloat(initialValues?.employer_amount) !== parseFloat(allValues?.employer_amount)
            } else {
              employerAmountIsChanged = initialValues.employer_amount !== allValues?.employer_amount;
            }
  
            if (allValues?.election_amount?.length) {
              electionAmountIsChanged = parseFloat(initialValues?.election_amount) !== parseFloat(allValues?.election_amount)
            } else {
              electionAmountIsChanged = initialValues.election_amount !== allValues?.election_amount;
            }
          }

          const coverageTierIsChanged = allValues?.coverage_tier_id ? (parseFloat(initialValues?.coverage_tier_id) !== parseFloat(allValues?.coverage_tier_id)) : false;

          if (electionAmountIsChanged || employerAmountIsChanged || coverageTierIsChanged || effectiveDateIsChanged || planEndDateIsChanged) {
            setFormChanged(false);
          } else {
            setFormChanged(true);
          }
        }}
        onFieldsChange={() => {
          setFormValid(
            form.getFieldsError().filter(({ errors }) => errors.length).length !== 0,
          );
        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="form-wrapper">
          <Row gutter={[16, 0]}>
            {isHsa && (
              <Col sm={12}>
                <Select
                  label="Select coverage type"
                  placeholder="Select coverage type"
                  name="coverage_tier_id"
                  options={coverageForSelect}
                  rules={[
                    mandatory({ message: "Please select a coverage type" }),
                  ]}
                />
              </Col>
            )}
            <Col sm={12}>
              <Form.Item
                className="date-form-item-wrappers"
                name="plan_effective_date"
                id="plan_effective_date"
                style={{ marginBottom: 0 }}
                rules={[
                  required("Effective date"),
                  // { validator: validateEffectiveDate }
                ]}
              >
                <DatePicker2
                  isClearable={true}
                  selected={formData["plan_effective_date"]}
                  showImportant={true}
                  label="Effective date"
                  placeholder="MM/DD/YYYY"
                  format={['MM/dd/yyyy', 'M/d/yyyy']}
                  readOnly={false}
                  onKeyDown={handleKeyDown}
                  onChange={(date) => {
                    form.setFieldsValue({ 'plan_effective_date': date });
                    setFormData(((prevData) => ({
                      ...prevData,
                      'plan_effective_date': date
                    })))
                  }}
                />
              </Form.Item>
            </Col>
            {!isHsa && (
              <>
                <Col sm={12}>
                  <Form.Item
                    className="date-form-item-wrappers"
                    id="plan_termination_date"
                    name="plan_termination_date"
                    style={{ marginBottom: 0 }}
                  >
                    <DatePicker2
                      maxDate={new Date(planInfo?.plan_end_date)}
                      isClearable={true}
                      selected={formData["plan_termination_date"]}
                      showImportant={false}
                      label="End date"
                      placeholder="MM/DD/YYYY"
                      format={['MM/dd/yyyy', 'M/d/yyyy']}
                      readOnly={false}
                      onKeyDown={handleKeyDown}
                      // filterDate={(date) => {
                      //   const currentDate = new Date();
                      //   const endDate = new Date(planInfo?.plan_end_date);
                      //   return date <= endDate
                      // }}
                      onChange={(date) => {
                        form.setFieldsValue({ 'plan_termination_date': date });
                        setFormData(((prevData) => ({
                          ...prevData,
                          'plan_termination_date': date
                        })))
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12}>
                  <TextField
                    label="Election amount"
                    placeholder="00.00"
                    name="election_amount"
                    rules={[mandatory({
                      message: "Please enter a valid election amount.",
                    }),
                    { validator: allowValidNumbers }
                    ]}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value === "") return;
                      if (!/^\d*\.?\d{0,2}$/.test(value)) {
                        const parsedValue = parseFloat(value);
                        if (!isNaN(parsedValue)) {
                          e.target.value = parsedValue.toFixed(2);
                        }
                      }
                    }}
                    onPaste={(event) => {
                      const clipboardData = event.clipboardData || window.clipboardData;
                      const pastedText = clipboardData.getData('Text');

                      if (!/^\d+$/.test(pastedText)) {
                        event.preventDefault();
                      }
                    }}
                    prefix="$"
                    type="text"
                  />
                </Col>
                <Col sm={12}>
                  <TextField
                    label="Employer contribution"
                    placeholder="00.00"
                    name="employer_amount"
                    rules={[
                      { validator: allowValidNumbers }
                    ]}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value;
                      if (value === "") return;
                      if (!/^\d*\.?\d{0,2}$/.test(value)) {
                        const parsedValue = parseFloat(value);
                        if (!isNaN(parsedValue)) {
                          e.target.value = parsedValue.toFixed(2);
                        }
                      }
                    }}
                    onPaste={(event) => {
                      const clipboardData = event.clipboardData || window.clipboardData;
                      const pastedText = clipboardData.getData('Text');

                      if (!/^\d+$/.test(pastedText)) {
                        event.preventDefault();
                      }
                    }}
                    prefix="$"
                    type="text"
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
        <div className="footer-wrapper">
          <Button
            variant="primary"
            label="Save"
            htmlType="submit"
            loading={loading}
            disabled={formChanged || formValid}
          />
        </div>
      </Form>
    </Modal>
  );
};
