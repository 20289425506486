import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCountries,
  getCoverages,
  getEmploymentStatuses,
  getAccountStatuses,
  getUserStatuses,
  getCurrentEmployer,
  getAllDivisions,
  getCurrentEmployerDetailsByTab,
  enableGlobalLoader,
  disableGlobalLoader,
  getReportByType,
} from "../../../redux/actions";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { TransactionHistory } from "../../../components/admin/dashboard";
import { EmployeeSummary } from "../../../components/admin/dashboard";

import { Row, Col, Card, Empty } from "antd";
import { Typography } from "../../../design-systems";
import useShowSecondSection from "../../../hooks/useShowSection";
import { planInformationEmpKeys } from "../../../utils";
import { useMemo } from "react";
import { ReportChart } from "../../../components/admin/dashboard/EmployeeSummary/Chart";

function DashBoard() {
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [data, setData] = useState([]);
  const payload = {
    page: 1,
    limit: 10,
  }

  const displayFsaAccounts =
    process.env.REACT_APP_PUBLIC_DISPLAY_FSA_ACCOUNTS_CARD === "true" ? true : false;
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCoverages());
    dispatch(getUserStatuses());
    dispatch(getAllDivisions());
    dispatch(getCurrentEmployer());
    dispatch(getAccountStatuses());
    dispatch(getEmploymentStatuses());
    dispatch(getCurrentEmployerDetailsByTab(planInformationEmpKeys?.PLAN_INFO, (res) => {
      const plans = res?.data?.plan_information?.selected_plans;
      setPlans(plans)
    }))
    dispatch(getReportByType("funding_summary", payload, (res) => {
      if (!(res instanceof Error)) {
        setData(res?.data?.data?.funding_details);
      }
    }))
  }, [dispatch]);

  useShowSecondSection();

  const planRegex = /FSA/i
  const hasHSAPlan = plans.includes("HSA");
  const hasFSAPlan = plans.some(plan => planRegex.test(plan));

  const allZero = data.every(item => item.employee_count === 0) || !data.length;

  return (
    <HeaderWithContent>
      <div className="dashboard-wrapper">
        <Row gutter={[20, 20]}>
          {
            hasHSAPlan &&
            <Col xs={24} sm={24} md={24} lg={12}>
              <Card
                title=""
                bordered={false}
                className="data-grid employee-details-wrapper height-600"
              >
                <div className="d-flex justify-space-between">
                  <Typography
                    label="Recent HSA activity"
                    variant="subhead-1"
                    className="employee-listing-title "
                  />
                  <div className="d-flex align-items-center">
                    <Link to="/admin/employer/ledger">Show more</Link>
                  </div>
                </div>
                <TransactionHistory />
              </Card>
            </Col>
          }
          <Col xs={24} sm={24} md={24} lg={12}>
            <Card
              title=""
              bordered={false}
              className="data-grid employee-details-wrapper height-600"
            >
              <Typography
                label="Employee summary"
                variant="subhead-1"
                className="employee-listing-title "
              />
              <EmployeeSummary hasHSAPlan={hasHSAPlan} />
            </Card>
          </Col>
          {
            hasFSAPlan &&
            <>
              {/* hiding fsa activty right now as we dnt have data for same */}
              {/* <Col xs={24} sm={24} md={24} lg={12}>
                <Card
                  title=""
                  bordered={false}
                  className="data-grid employee-details-wrapper"
                >
                  <div className="d-flex justify-space-between">
                    <Typography
                      label="Recent FSA activity"
                      variant="subhead-1"
                      className="employee-listing-title "
                    />
                  </div>
                  <TransactionHistory />
                </Card>
              </Col> */}
              {displayFsaAccounts &&
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Card
                    title=""
                    bordered={false}
                    className={`data-grid employee-details-wrapper ${!allZero ? '' : 'height-600'}`}
                  >
                    <Typography
                      label="FSA Plans"
                      variant="subhead-1"
                      className="employee-listing-title "
                    />
                    {!data.length ?
                      <Empty />
                      :
                    <ReportChart data={data} />
                  }
                  </Card>
                </Col>
              }
            </>
          }

        </Row>
      </div>
    </HeaderWithContent>
  );
}

export { DashBoard };
