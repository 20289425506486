import { ApiErrors } from "../../utils";
import { EMPLOYEES_CONSTANT } from "../../utils/constants";
import axios from "axios";
import {
  disableDataLoader,
  disableLoader,
  enableDataLoader,
  enableLoader,
  enableGlobalLoader,
  disableGlobalLoader
} from ".";
import {
  addEmployeeService,
  addEmployeesService,
  addNewEmployeeService,
  addNewPlanService,
  cancelUploadedFileService,
  deleteEmployeeDetailsFromFileService,
  editPlanService,
  executeUploadedFileService,
  getAccountStatusesService,
  getAllEmployeesService,
  getAllEmployeesWithFiltersService,
  getAllUploadedFilesService,
  getCountriesService,
  getCoveragesService,
  getEmployeeDetailsFromFileService,
  getEmployeePlanDataService,
  getEmployeeService,
  getEmployeesFromCSVService,
  getEmploymentStatusesService,
  getUploadedFileDetailsService,
  getUserStatusesService,
  updateEmployeeDetailsFromFileService,
  updateEmployeeService,
  updateEmploymentDetailsService,
  updateKYCStatusService,
  updateMFAService,
  updatePersonalDetailsService,
  updateSocureKYCStatusService,
  updateWorkEmailService,
  uploadFileS3Service,
  uploadFileService,
  validateEmployeesService,
} from "../../services";

export function getEmployeesList(data, callback) {
  const processName = "getEmployeesList";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getAllEmployeesService(data)
      .then((list) => {
        callback && callback(list);
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_EMPLOYEES_LIST,
          payload: list,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getEmployeesListWithFilters(data, callback, signal) {
  const processName = "getEmployeesListWithFilters";
  return dispatch => {
    dispatch(enableDataLoader(processName))
    getAllEmployeesWithFiltersService(data, signal)
      .then((list) => {
        callback && callback(list);
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_EMPLOYEES_LIST,
          payload: list,
        });
      })
      .catch((err) => {
        if(!axios.isCancel(err)) {
          ApiErrors(err, dispatch);
        }
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  }
}

export function getEmployeePlanData(id, callback) {
  return (dispatch) => {
    // dispatch(enableLoader());
    getEmployeePlanDataService(id)
      ?.then((resData) => {
        callback && callback(resData);
        dispatch({
          type: EMPLOYEES_CONSTANT.SAVE_EMPLOYEE_PLANS_DATA,
          payload: resData.data
        })
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        // dispatch(disableLoader());
      });
  };
}

export function updatePersonalInformation(id, data, callback) {

  return (dispatch) => {
    updatePersonalDetailsService(id, data)
      .then((resData) => {
        callback && callback(resData);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}

export function updateEmploymentInformation(id, data, callback) {

  return (dispatch) => {
    updateEmploymentDetailsService(id, data)
      .then((resData) => {
        callback && callback(resData);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}

export function getEmployeesData(id, callback) {
  const processName = "getEmployeesData";
  return (dispatch) => {
    dispatch(enableLoader(processName));
    getEmployeeService(id)
      ?.then((resData) => {
        callback && callback(resData);
        dispatch({
          type: EMPLOYEES_CONSTANT.SAVE_SELECTED_EMPLOYEE_DATA,
          payload: resData.employee
        })
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
        dispatch(disableLoader(processName));
      });
  };
}

export function getCountries() {
  return (dispatch) => {
    getCountriesService()
      .then((list) => {
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_COUNTRIES_LIST,
          payload: list,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function getEmploymentStatuses() {
  return (dispatch) => {
    getEmploymentStatusesService()
      .then((list) => {
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_EMPLOYEMENT_STATUS,
          payload: list,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function getCoverages() {
  return (dispatch) => {
    getCoveragesService()
      .then((list) => {
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_COVERAGES_LIST,
          payload: list,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function getAccountStatuses() {
  return (dispatch) => {
    getAccountStatusesService()
      .then((list) => {
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_ACCOUNT_STATUS,
          payload: list,
        });
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function getUserStatuses(filterStatusForKyc = false, callback) {
  return (dispatch) => {
    getUserStatusesService(filterStatusForKyc)
      .then((list) => {
        dispatch({ type: EMPLOYEES_CONSTANT.SET_USER_STATUS, payload: list });
        callback && callback(list);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function addEmployee(reqData, callback) {
  return (dispatch) => {
    addEmployeeService(reqData, reqData.runkyc)
      .then((resData) => {
        callback && callback(resData);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function validateEmployeesData(reqData, callback) {
  return (dispatch) => {
    validateEmployeesService(reqData)
      .then((list) => {
        callback && callback(list);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
      })
      .finally(() => { });
  };
}

export function updateKYCStatus(reqData, callback) {
  return (dispatch) => {
    // dispatch(enableLoader());
    updateKYCStatusService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
        callback && callback(err);
      })
      .finally(() => {
        // dispatch(disableLoader());
      });
  };
}

export function updateSocureKYCStatus(reqData, callback) {
  return (dispatch) => {
    // dispatch(enableLoader());
    updateSocureKYCStatusService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
        callback && callback(err);
      })
      .finally(() => {
        // dispatch(disableLoader());
      });
  };
}

export function updateEmployee(reqData, status, callback) {
  return (dispatch) => {
    dispatch(enableLoader());
    updateEmployeeService(reqData, status)
      .then((emp) => {
        callback && callback(emp);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}

export function uploadCsv(reqData, divisionList, callback) {
  const processName = "uploadCsv";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getEmployeesFromCSVService(reqData, divisionList)
      .then((emp) => {
        callback && callback(emp);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function confirmUploadCsv(
  employees,
  runkyc,
  isFile,
  fileName,
  divisionList,
  callback,
) {
  return (dispatch) => {
    dispatch(enableLoader());
    addEmployeesService(employees, runkyc, isFile, fileName, divisionList)
      .then((emp) => {
        callback && callback(emp);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableLoader());
      });
  };
}

export function getAllUploadedFiles(data, callback) {
  const processName = "getAllUploadedFiles";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    getAllUploadedFilesService(data)
      .then((res) => {
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_UPLOADED_FILES_TYPE,
          payload: res.data,
        });
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function getAllUploadedFilesWithoutLoader(data, callback) {
  return (dispatch) => {
    getAllUploadedFilesService(data)
      .then((res) => {
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_UPLOADED_FILES_TYPE,
          payload: res.data,
        });
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => { });
  };
}

export function getUploadedFileDetails(fileID, page, limit, callback) {
  const processName = "getUploadedFileDetails";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));
    setTimeout(() => {
      getUploadedFileDetailsService(fileID, page, limit)
        .then((res) => {
          callback && callback(res);
        })
        .catch((err) => {
          ApiErrors(err, dispatch, true);
        })
        .finally(() => {
          dispatch(disableDataLoader(processName));
        });
    }, 5000);
  };
}

export function cancelUploadedFile(fileID, callback) {
  return (dispatch) => {
    cancelUploadedFileService(fileID)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => { });
  };
}

export function uploadFile(reqData, callback) {
  return (dispatch) => {
    uploadFileService(reqData)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => { });
  };
}

export function uploadFileS3(presignedUrl, traceId, file, callback) {
  return (dispatch) => {
    uploadFileS3Service(presignedUrl, file, traceId)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => { });
  };
}

export function getEmployeeDetailsFromFile(fileID, userID, callback) {
  const processName = "getEmployeeDetailsFromFile";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));

    getEmployeeDetailsFromFileService(fileID, userID)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function updateEmployeeDetailsFrom(fileID, userID, data, callback) {
  const processName = "updateEmployeeDetailsFrom";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));

    updateEmployeeDetailsFromFileService(fileID, userID, data)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function deleteEmployeeDetailsFromFile(fileID, userID, callback) {
  const processName = "deleteEmployeeDetailsFromFile";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));

    deleteEmployeeDetailsFromFileService(fileID, userID)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function executeUploadedFile(fileID, callback) {
  const processName = "executeUploadedFile";

  return (dispatch) => {
    dispatch(enableDataLoader(processName));

    executeUploadedFileService(fileID)
      .then((res) => {
        callback && callback(res);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch, true);
      })
      .finally(() => {
        dispatch(disableDataLoader(processName));
      });
  };
}

export function resetEmployeeData() {
  return (dispatch) => {
    dispatch({
      type: EMPLOYEES_CONSTANT.RESET_EMPLOYEES_DATA
    })
  }
}

export function resetEmployeeCreateSuccess() {
  return (dispatch) => {
    dispatch({
      type: EMPLOYEES_CONSTANT.RESET_EMPLOYEES_CREATE_SUCCESS
    })
  }
}

export function saveSelectedDivisionId(id) {
  return dispatch => {
    dispatch({
      type: EMPLOYEES_CONSTANT.SAVE_SELECTED_DIVISION_ID,
      payload: id
    })
  }
}

export function addNewEmployee(data, stepId, callback) {
  return (dispatch) => {
    dispatch(enableGlobalLoader());
    addNewEmployeeService(data, stepId)
      .then((res) => {
        callback && callback(res);
        if (stepId === 1) {
          dispatch({
            type: EMPLOYEES_CONSTANT.SET_EMPLOYEE_ID,
            payload: res.employee_draft_id
          });
        }
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_EMPLOYEE_CREATE_SUCCESS,
        });
      }).catch((err) => {
        dispatch(disableGlobalLoader())
        callback && callback(err);
        ApiErrors(err, dispatch);
        dispatch({
          type: EMPLOYEES_CONSTANT.SET_EMPLOYEE_CREATE_ERROR,
        });
      })
      .finally(() => {
        dispatch(disableGlobalLoader())
      })
  }
}

export function resetEmployeeCreateData() {
  return (dispatch) => {
    dispatch({
      type: EMPLOYEES_CONSTANT.RESET_EMPLOYEES_CREATE_DATA
    })
  }
}

export function updateEmployeeLoginEmail(data, callback) {
  return (dispatch) => {
    updateWorkEmailService(data)
      .then((resData) => {
        callback && callback(resData);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}

export const updateMFAAction = (data, action, callback) => {
  return (dispatch) => {
    updateMFAService(data, action)
      .then((resData) => {
        callback && callback(resData);
      })
      .catch((err) => {
        callback && callback(err);
        ApiErrors(err, dispatch);
      })
      .finally(() => {
      });
  };
}

export function addNewPlan(id, reqData, callback) {
  return (dispatch) => {
    addNewPlanService(id, reqData)
      .then((emp) => {
        callback && callback(emp);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        callback && callback(err);
      })
      .finally(() => { });
  };
}

export function editPlan(id, data, callback) {
  return (dispatch) => {
    editPlanService(id, data)
      .then((emp) => {
        callback && callback(emp);
      })
      .catch((err) => {
        ApiErrors(err, dispatch);
        callback && callback(err);
      })
      .finally(() => { });
  }
}