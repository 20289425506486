import React from "react";
import { fixAmountFormat, getLocalTimezone } from "../../../../utils";

import { Empty, Table } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

function PendingACHSection({ data }) {
  const achColumns = [
    {
      title: "Txn timestamp",
      dataIndex: "create_timestamp",
      render: (date) => date ?
        `${moment
          .utc(date)
          .local()
          .format(DATE_FORMAT.FOR_UI_DATE_TIME)} ${getLocalTimezone()}` : ""
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Description",
      dataIndex: "indicator",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
    },
    {
      title: "Transaction ID",
      dataIndex: "ach_txn_id",
    },
  ];

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <>
        {
          <>
            <Table
              bordered
              columns={achColumns}
              dataSource={data}
              pagination={false}
              scroll={data?.length ? { x: 1200 } : undefined}
              tableLayout="auto"
              className="compact-table mb-15"
            />
          </>
        }
      </>
    );
  }
}

export { PendingACHSection };
