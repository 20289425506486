import { Col, Row } from "antd";
import React, { useMemo } from "react";
import moment from "moment"
import { Select, TextArea, TextField } from "../../../../design-systems";
import DatePicker2 from "../../../../design-systems/DatePicker2";
import { handleKeyDown } from "../../../../utils";
import { allowValidNumbers, amountGreaterThanZero, required } from "../../../../utils/validations";

export const ExpenseDetails = ({ form, formData, setFormData, Form, expensesTypes, setChangedValues }) => {
    const disableFutureDates = date => {
        const today = moment().startOf('day');
        return moment(date).isAfter(today) ? false : true
    };
    const expenseType = Form.useWatch("expense_type", form);
    const mileage = Form.useWatch("mileage", form);
    const otherTravelAmount = Form.useWatch("other_travel_amount", form);
    const isExpenseDependentCare = expenseType === "Dependent Care"

    const getSelectedExpenseDates = useMemo(() => {
        const selectedExpense = expensesTypes.find(exp => exp.value === expenseType);
        if (selectedExpense !== null) {
            return selectedExpense
        }
        return {}
    }, [expenseType, expensesTypes])

    return (<>
        <Row gutter={[16]}>
            <Col xs={24} md={12}>
                <Select
                    className="expense-type"
                    allowClear={false}
                    onChange={() => {
                        form.setFieldsValue({
                            health_expense_amount: "",
                            name_of_merchant: "",
                            mileage: 0,
                            other_travel_amount: 0,
                            start_date: null,
                            end_date: null,
                            expense_for: "",
                            notes: ""
                        });
                        setFormData({})
                    }}
                    label="Select expense type"
                    placeholder="Select expense type"
                    name="expense_type"
                    options={expensesTypes}
                    rules={[
                        required("Expense type"),
                    ]}
                />
            </Col>
        </Row>
        <Row gutter={[16]}>
            {
                expenseType &&
                <>
                    <Col xs={24} md={12}>
                        <TextField
                            label="Expense amount"
                            placeholder="00.00"
                            name="health_expense_amount"
                            rules={
                                [
                                    required("Expense amount"),
                                    { validator: amountGreaterThanZero },
                                    { validator: allowValidNumbers }
                                ]
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onInput={(e) => {
                                const value = e.target.value;
                                if (value === "") return;
                                if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                    const parsedValue = parseFloat(value);
                                    if (!isNaN(parsedValue)) {
                                        e.target.value = parsedValue.toFixed(2);
                                    }
                                }
                            }}
                            onPaste={(event) => {
                                const clipboardData = event.clipboardData || window.clipboardData;
                                const pastedText = clipboardData.getData('Text');

                                if (!/^\d+$/.test(pastedText)) {
                                    event.preventDefault();
                                }
                            }}
                            prefix="$"
                            type="text"

                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <TextField
                            label="Merchant"
                            placeholder="Merchant"
                            name="name_of_merchant"
                            rules={[
                                required("Merchant"),
                                { whitespace: true, message: "Merchant cannot be empty." }
                            ]}
                            type="text"
                        />
                    </Col>
                    {isExpenseDependentCare &&
                        <>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="start_date"
                                    className="form-item-field"
                                >
                                    <DatePicker2
                                        rules={[required("Start service date")]}
                                        maxDate={new Date()}
                                        isClearable={true}
                                        id="start_date"
                                        selected={formData["start_date"]}
                                        name="start_date"
                                        showImportant={true}
                                        filterDate={(currentDate) => {
                                            const planStartDate = moment(getSelectedExpenseDates?.plan_start_date);
                                            const planEndDate = moment(getSelectedExpenseDates?.plan_end_date);
                                            return moment(currentDate).isBetween(planStartDate, planEndDate, null, '[]');
                                        }}
                                        label="Start service date"
                                        placeholder="MM/DD/YYYY"
                                        format={['MM/dd/yyyy', 'M/d/yyyy']}
                                        readOnly={false}
                                        onKeyDown={handleKeyDown}
                                        onChange={(date) => {
                                            form.setFieldsValue({ 'start_date': date });
                                            setFormData(((prevData) => ({
                                                ...prevData,
                                                'start_date': date
                                            })))
                                            setChangedValues(((prevData) => ({
                                                ...prevData,
                                                'start_date': date
                                            })))
                                            if (formData['end_date']) {
                                                form.setFieldsValue({ end_date: "" });
                                                formData['end_date'] = "";
                                                setFormData(((prevData) => ({
                                                    ...prevData,
                                                    'end_date': ""
                                                })))
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="end_date"
                                    className="form-item-field"
                                >
                                    <DatePicker2
                                        maxDate={new Date()}
                                        isClearable={true}
                                        id="end_date"
                                        disabled={!isExpenseDependentCare}
                                        showImportant={isExpenseDependentCare}
                                        selected={formData["end_date"]}
                                        rules={isExpenseDependentCare ? [required("End service date")] : []}
                                        name="end_date"
                                        filterDate={(currentDate) => {
                                            const startDate = formData["start_date"];
                                            const today = moment().startOf('day');
                                            if (startDate) {
                                                const startDateMoment = moment(startDate);
                                                return !startDateMoment.isAfter(moment(currentDate)) && !(moment(currentDate).isAfter(today) && !moment(currentDate).isSame(today));
                                            } else {
                                                return !moment(currentDate).isAfter(today);
                                            }
                                        }}
                                        label="End service date"
                                        placeholder="MM/DD/YYYY"
                                        format={['MM/dd/yyyy', 'M/d/yyyy']}
                                        readOnly={false}
                                        onKeyDown={handleKeyDown}
                                        onChange={(date) => {
                                            form.setFieldsValue({ 'end_date': date });
                                            setChangedValues(((prevData) => ({
                                                ...prevData,
                                                'end_date': date
                                            })))
                                            setFormData(((prevData) => ({
                                                ...prevData,
                                                'end_date': date
                                            })))
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    }
                    <Col xs={24} md={12}>
                        <TextField
                            label="Who the expense is for"
                            placeholder="Who the expense is for"
                            name="expense_for"
                            rules={[required("Expense for"), { whitespace: true, message: "Expense for cannot be empty." }]}
                            type="text"
                        />
                    </Col>
                    {
                        !isExpenseDependentCare &&
                        <>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="transaction_date"
                                    className="form-item-field"
                                >
                                    <DatePicker2
                                        rules={[required("Date of service"),
                                        ]}
                                        maxDate={new Date()}
                                        isClearable={true}
                                        id="transaction_date"
                                        selected={formData["transaction_date"]}
                                        name="transaction_date"
                                        showImportant={true}
                                        filterDate={(currentDate) => {
                                            const planStartDate = moment(getSelectedExpenseDates?.plan_start_date);
                                            const planEndDate = moment(getSelectedExpenseDates?.plan_end_date);
                                            return moment(currentDate).isBetween(planStartDate, planEndDate, null, '[]');
                                        }}
                                        label="Date of service"
                                        placeholder="MM/DD/YYYY"
                                        format={['MM/dd/yyyy', 'M/d/yyyy']}
                                        readOnly={false}
                                        onKeyDown={handleKeyDown}
                                        onChange={(date) => {
                                            form.setFieldsValue({ 'transaction_date': date });
                                            setFormData(((prevData) => ({
                                                ...prevData,
                                                'transaction_date': date
                                            })))
                                            setChangedValues(((prevData) => ({
                                                ...prevData,
                                                'transaction_date': date
                                            })))
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <TextField
                                    label="Mileage"
                                    placeholder="0"
                                    name="mileage"
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    rules={[{ validator: allowValidNumbers }]}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        if (value === "") return;
                                        if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                            const parsedValue = parseFloat(value);
                                            if (!isNaN(parsedValue)) {
                                                e.target.value = parsedValue.toFixed(2);
                                            }
                                        }
                                    }}
                                    onPaste={(event) => {
                                        const clipboardData = event.clipboardData || window.clipboardData;
                                        const pastedText = clipboardData.getData('Text');

                                        if (!/^\d+$/.test(pastedText)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    type="text"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <TextField
                                    label="Other travel expenses"
                                    placeholder="0"
                                    name="other_travel_amount"
                                    onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    rules={[{ validator: allowValidNumbers }]}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        if (value === "") return;
                                        if (!/^\d*\.?\d{0,2}$/.test(value)) {
                                            const parsedValue = parseFloat(value);
                                            if (!isNaN(parsedValue)) {
                                                e.target.value = parsedValue.toFixed(2);
                                            }
                                        }
                                    }}
                                    onPaste={(event) => {
                                        const clipboardData = event.clipboardData || window.clipboardData;
                                        const pastedText = clipboardData.getData('Text');

                                        if (!/^\d+$/.test(pastedText)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    prefix="$"
                                    type="text"

                                />
                            </Col>
                        </>
                    }
                   

                    <Col xs={24}>
                        <TextArea
                            label="Notes"
                            placeholder="Enter notes here"
                            name="notes"
                            maxLength={512}
                        />
                    </Col>
                </>
            }
        </Row>
    </>
    )
}