import React from "react";

function Property({ title, value }) {
  console.log({title});
  return (
    <div className="contribution-summary-property">
      <div className="d-flex list">
        <h4>{title}:</h4>
        <p>{value !== "undefined" || value !== null ? value : ""}</p>
      </div>
    </div>
  );
}

function ContributionSummary({ propertyList }) {
  return (
    <div className="contribution-summary-info">
      {propertyList.map((property) =>
        property.visible ? (
          <Property title={property.title} value={property.value} />
        ) : null,
      )}
    </div>
  );
}

export { ContributionSummary };
