import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    Select,
    DatePicker,
    TextField,
} from "../../../../design-systems";
import { required } from "../../../../utils/validations";
import { getCoverages } from '../../../../redux/actions';
import { handleKeyDown } from '../../../../utils';
import { useMemo } from 'react';
import DatePicker2 from '../../../../design-systems/DatePicker2';
import { DATE_FORMAT } from '../../../../utils/constants';
import moment from 'moment';

export const HSANewAddPlan = ({ form, formData, planInfo, setFormData, isInputRequired }) => {
    const dispatch = useDispatch();
    const coverages = useSelector((state) => state.employees.coverages);
    useEffect(() => {
        dispatch(getCoverages());
    }, [])


    const planYear = useMemo(() => {
        const { plan_config_id, plan_end_date, plan_start_date } = planInfo || {};
        const yearInfo = []
        if (plan_config_id && plan_start_date && plan_end_date) {
            yearInfo.push({
                label: `${moment(plan_start_date).format(DATE_FORMAT?.FOR_UI_FIRST_MM)} - ${moment(plan_end_date).format(DATE_FORMAT?.FOR_UI_FIRST_MM)}`,
                value: plan_config_id
            });
        } else {
            yearInfo.push({
                label: 'No Data',
                value: null
            });
        }
        return yearInfo
    }, [planInfo])

    const isPlanYearNull = planYear.every(plan => plan?.value === null)
    const coverageForSelect = useMemo(() => {
        let coverage = Object.keys(coverages).map((cov) => ({
            label: cov,
            value: coverages[cov]
        }))
        return coverage
    }, [coverages])

    return (
        <Row gutter={[16, 0]}>
            <Col xs={24} md={12}>
                <Select
                    className="hsa-plan-coverage-type"
                    disabled={isPlanYearNull}
                    label="Select coverage type"
                    placeholder="Select coverage type"
                    name="hsa_coverage_type"
                    options={coverageForSelect}
                    rules={isInputRequired ? [
                        required("Coverage type"),
                    ] : []}
                />
            </Col>
            <Col xs={24} md={12}>
                <DatePicker2
                    isClearable={true}
                    showImportant={isInputRequired}
                    id="hsa_plan_effective_date"
                    selected={formData["hsa_plan_effective_date"]}
                    name="hsa_plan_effective_date"
                    rules={isInputRequired ? [required("Effective date")]: []}
                    label="Effective date"
                    placeholder="MM/DD/YYYY"
                    format={['MM/dd/yyyy', 'M/d/yyyy']}
                    readOnly={false}
                    onKeyDown={handleKeyDown}
                    onChange={(date) => {
                        form.setFieldsValue({ 'hsa_plan_effective_date': date });
                        setFormData(((prevData) => ({
                            ...prevData,
                            'hsa_plan_effective_date': date
                        })))
                    }}
                />
            </Col>
            <Col xs={24} md={12}>
                <Select
                    className="hsa-plan-coverage-type"
                    disabled={!coverageForSelect?.length}
                    label="Plan year"
                    placeholder="Select plan year"
                    name="hsa_plan_config_id"
                    options={planYear}
                    rules={isInputRequired ? [
                        required("Plan year"),
                    ] : []}
                />
            </Col>
            <Col xs={24} md={24}>
                <Form.Item
                    name="hsa_account_type"
                    className="dummy-error should-update-form-item"
                ></Form.Item>
            </Col>
        </Row>
    )
};
