import axios from "./axiosInterceptors";

export const getContributionReportsListService = async () => {
  try {
    const request = {
      method: "get",
      url: "/employer/payroll/employer/reports",
    };

    const response = await axios(request);

    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getContributionReportIDService = async (contributionRunID) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/employer/reports/report-id/${contributionRunID}`,
    };

    const response = await axios(request);

    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getContributionReportLinkService = async (reportId) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/employer/reports/download-url/${reportId}`,
    };

    const response = await axios(request);

    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getReportsLinkService = async (report_name, params) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/reports/download/${report_name}`,
      params
    };

    const response = await axios(request);
    const fileName = response.headers['filename'];
    return { data: response.data, fileName };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllAvailableReportsService = async () => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/reports`,
    };

    const response = await axios(request);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getReportByTypeService = async (type, payload) => {
  try {
    const request = {
      method: "get",
      url: `/employer/payroll/reports/${type}`,
      params: payload
    };

    const response = await axios(request);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};