import { Checkbox, Col, Collapse as AntCollapse, Row } from "antd";
import {
  companyInformationKeys,
  companyInformationStep,
} from "../../../../../utils";
import { PanelHeader } from "../PanelHeader";
import { useEffect, useState } from "react";
import { Collapse } from "../../../../../design-systems";
import { useSelector } from "react-redux";
import { useRouter } from "../../../../../utils/router";

const { Panel } = AntCollapse;

export const CompanyInformation = ({ form }) => {
  const router = useRouter()
  const { employerId } = useSelector((state) => state.employer)
  useEffect(() => {
    if (!employerId) {
      router.push('/admin/employer/add/1')
    }
  }, [employerId])
  // const [sameAsCorporate, setSameAsCorporate] = useState(true)
  const [expandedPanels, setExpandedPanels] = useState(
    companyInformationStep.map(({ key }) => key),
  );

  const onChange = async (updatedPanels) => {
    if (updatedPanels?.length < expandedPanels.length) {
      try {
        const recentKey = expandedPanels.find(
          (key) => !updatedPanels.includes(key),
        );

        if (
          recentKey === companyInformationKeys.MAILING_ADDRESS &&
          !!form?.getFieldValue("same_as_corporate")
        ) {
          setExpandedPanels(updatedPanels);
        }
        // else {
        //   const values = await form?.validateFields(validateFields[recentKey]);
        //   if (Object.keys(values).length) {
        //   }
        // }
        setExpandedPanels(updatedPanels);
      } catch (err) { }
    } else {
      setExpandedPanels(updatedPanels);
    }
  };

  return (
    <Collapse
      className="horizontal-collapse test"
      activeKey={expandedPanels}
      onChange={onChange}
      panels={companyInformationStep.map(({ content, title, key }) => (
        <Panel header={<div className="panel-head"><PanelHeader label={title} form={form} expandedPanels={expandedPanels} setExpandedPanels={setExpandedPanels} /></div>} key={key}>
          {content({ form })}
        </Panel>
      ))}
    />
  );
};
