import { Card, Col, Divider, Form, Row } from "antd";
import { HeaderWithContent } from "../../../../components/common/HeaderWithContent";
import { useForm } from "antd/lib/form/Form";
import { clearFieldErrors, renderEmployeeSteps, employeeSteps, trimFormValues, formatEmployeeDataForStep } from "../../../../utils";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    CancelModal,
    Footer,
    // Header,
    SuccessModal
} from "../../../../components/admin/employer";
import { Header } from "../../../../components/admin/employees/AddEmployee/Header";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../../utils/router";
import { addNewEmployee, getAllDivisions, resetEmployeeCreateData, resetEmployeeCreateSuccess, showErrorMessage } from "../../../../redux/actions";


const AddNewEmployee = () => {
    const initialValues = {};
    const [form] = useForm();
    const router = useRouter();
    const formRef = useRef();
    const dispatch = useDispatch()
    const { employee_draft_id, success, selectedDivisionId } = useSelector((state) => state.employees)
    const currentStep = +router.match.params.step;
    const [formValid, setFormValid] = useState(false);
    const [employerSteps, setEmployerSteps] = useState(employeeSteps);
    const [cancelModalOpened, setCancelModalOpened] = useState(false);
    const [successModalOpened, setSuccessModalOpened] = useState(false);
    const [divisionList, setDivisionList] = useState([]);
    const [formData, setFormData] = useState({})

    useEffect(() => {
        dispatch(
            getAllDivisions((res) => {
                setDivisionList(res.data);
            }),
        );
        return () => {
            dispatch(resetEmployeeCreateSuccess())
        }
    }, [])

    useEffect(() => {
        if (currentStep > 0 && currentStep <= employerSteps.length) {
            setEmployerSteps((prev) => [
                ...prev.map((step, index) => {
                    const currentIndex = currentStep - 1;
                    const isActive = currentIndex === index;
                    const isComplete = currentIndex > index;

                    return { ...step, isActive, isComplete };
                }),
            ]);
        } else {
            router.push("/admin/employees");
        }
    }, [currentStep, employerSteps.length, router]);

    const transFormMessages = (field, msg) => {
        switch (field) {
            // case "lfsa_employee_amount":
            // case "fsa_employee_amount":
            // case "dcfsa_employee_amount":
            //     return "Election amount must be between employee min and max contribution."
            // case "lfsa_employer_amount":
            // case "fsa_employer_amount":
            // case "dcfsa_employer_amount":
            //     return "Employer contribution along with election amount should not exceed IRS limit."
            case "HSA":
            case "hsa_plan_effective_date":
                return "HSA plan is not available for the given effective date.";
            case "FSA":
            case "fsa_plan_effective_date":
                return "FSA plan is not available for the given effective date.";
            case "LFSA":
            case "lfsa_plan_effective_date":
                return "LFSA plan is not available for the given effective date.";
            case "DCFSA":
            case "dcfsa_plan_effective_date":
                return "DCFSA plan is not available for the given effective date.";
            case "work_email":
                return "Work email is already in use."
            case "ssn":
                return "SSN is already in use."
            case "employee_id":
                return "Employee id is already in use."
            default:
                return msg
        }
    }


    const onFinish = useCallback(
        async (values) => {
            await form.validateFields();
            let apiData = formatEmployeeDataForStep(trimFormValues(values), currentStep, form)
            apiData.payload.employee_draft_id = currentStep === 1 && !employee_draft_id ? "" : employee_draft_id
            if (currentStep === 2) {
                apiData.payload.division_id = values.division ? values.division : selectedDivisionId
            }
            dispatch(addNewEmployee(apiData, currentStep, (res) => {
                if (res instanceof Error) {
                    res?.response?.data?.error?.errored_fields.forEach(err => {
                        const simplifiedFieldName = err?.field.replace(/^\./, "").replace(/\./g, "_").toLowerCase();
                        form.setFields([
                            {
                                name: simplifiedFieldName,
                                errors: [transFormMessages(simplifiedFieldName, err?.message)],

                            },
                        ]);
                    })
                } else {

                }
            }))
        },
        [currentStep, employerSteps.length, dispatch, router, success],
    );
    useEffect(() => {
        if (currentStep < employerSteps.length && success) {
            // if (operationFlag === 'saveForm') {
            // }
            router.push(`/admin/employee/add/${currentStep + 1}`);
        }
        if (success && currentStep === employerSteps.length) {
            setFormData({})
            setSuccessModalOpened(true)
        }
    }, [success])

    const onFinishFailed = useCallback((errorInfo) => {
    }, []);

    const onValuesChange =
        (_, allValues) => {
            clearFieldErrors(form, allValues);
            setFormValid(
                form.getFieldsError().filter(({ errors }) => errors.length).length !== 0,
            );
        };

    const onCancel = useCallback(() => {
        setCancelModalOpened(true);
    }, []);

    const onCancelConfirm = useCallback(() => {
        router.push('/admin/employees')
    }, []);

    const onPrevious = useCallback(() => {
        if (true && currentStep > 1) {
            // if form field valid
            dispatch(resetEmployeeCreateSuccess())
            router.push(`/admin/employee/add/${currentStep - 1}`);
        }
    }, [currentStep, router]);

    const OnAddAnother = () => {
        form.resetFields();
        dispatch(resetEmployeeCreateData())
        setSuccessModalOpened(false)
        router.push(`/admin/employee/add/1`);
    }

    const onSeeList = () => {
        form.resetFields();
        router.push(`/admin/employees`)
    }

    return (
        <>
            <HeaderWithContent
                customFooter={
                    <Footer
                        onNext={() => {
                            formRef?.current?.submit()
                            dispatch(resetEmployeeCreateSuccess())
                        }}
                        // formValid={form.getFieldsError().filter(({ errors }) => errors.length).length !== 0}
                        onCancel={onCancel}
                        onPrevious={onPrevious}
                        isFirstStep={currentStep === 1}
                        isLastStep={currentStep === employerSteps.length}
                        page="addEmployee"
                    />
                }
            >
                <Form
                    name="login"
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={onValuesChange}
                    layout="vertical"
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    ref={formRef}
                >
                    <Row gutter={0}>
                        <Col span={24}>
                            <Card
                                title={
                                    <Header
                                        steps={employerSteps}
                                        currentStep={currentStep - 1}
                                        nextStep={currentStep}
                                    />
                                }
                                bordered={false}
                                className="add-employer-card"
                            >
                                <Divider />
                                {renderEmployeeSteps({ form, step: currentStep, formData, setFormData })}
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </HeaderWithContent>
            <CancelModal
                open={cancelModalOpened}
                onCancel={() => setCancelModalOpened(false)}
                onOk={onCancelConfirm}
                page={"add-employee"}
            />

            <SuccessModal
                page="add-employee"
                form={form}
                onCancel={() => setSuccessModalOpened(false)}
                open={successModalOpened}
                onSecondaryActionClick={onSeeList}
                onPrimaryActionClick={OnAddAnother}
                primaryLabel="Add another employee"
                secondaryLabel="Close"
            />
        </>
    );
}

export default AddNewEmployee