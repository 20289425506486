import {
  CONTRIBUTION_STATUSES,
  LEDGER_TRANSACTION_STATUSES,
} from "../utils/constants";
import {
  getContributionReportIDService,
  getContributionReportLinkService,
} from "../services";

import { Popover, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export function renderContrStatusLabel(status) {
  switch (status?.toLowerCase()) {
    case CONTRIBUTION_STATUSES.SUCCESSFUL:
      return "Complete";
    case CONTRIBUTION_STATUSES.ENABLED:
      return "Contribution Set";
    case CONTRIBUTION_STATUSES.ERRORED:
      return "Incomplete";
    case CONTRIBUTION_STATUSES.CANCELLED:
      return "Canceled";
    case CONTRIBUTION_STATUSES.AMT_CREDITED:
      return "Transfer Complete";
    case CONTRIBUTION_STATUSES.ACH_FAILED:
      return "Transfer Failed";
    case CONTRIBUTION_STATUSES.ACH_IN_PROGRESS:
      return "Transfer Started";
    case CONTRIBUTION_STATUSES.EXEC_IN_PROGRESS:
      return "Executing";
    case CONTRIBUTION_STATUSES.VALIDATION_ERROR:
    case CONTRIBUTION_STATUSES.INCOMPLETE:
      return "Validation Error";
    case CONTRIBUTION_STATUSES.VALIDATION_IN_PROGRESS:
      return "Validation in Progress";
    case CONTRIBUTION_STATUSES.FAILED:
      return "Failed";
    case CONTRIBUTION_STATUSES.READY:
      return "File Loaded";

    default:
      return status;
  }
}
export function renderContrDivStatusLabel(status) {
  switch (status?.toLowerCase()) {
    case CONTRIBUTION_STATUSES.SUCCESSFUL:
      return "Complete";
    case CONTRIBUTION_STATUSES.ENABLED:
      return "Contribution Set";
    case CONTRIBUTION_STATUSES.ERRORED:
      return "Incomplete";
    default:
      return status;
  }
}
export function renderContrEmpStatusLabel(status) {
  switch (status?.toLowerCase()) {
    case CONTRIBUTION_STATUSES.SUCCESSFUL:
      return "Successful";
    case CONTRIBUTION_STATUSES.ENABLED:
      return "Contribution Set";
    case CONTRIBUTION_STATUSES.ERRORED:
      return "Held";
    default:
      return status;
  }
}

export function renderContrStatusTag(status, place) {
  let label;
  let color;

  switch (status?.toLowerCase()) {
    case CONTRIBUTION_STATUSES.SUCCESSFUL:
      color = "green";
      break;
    case CONTRIBUTION_STATUSES.ERRORED:
    case CONTRIBUTION_STATUSES.ACH_FAILED:
    case CONTRIBUTION_STATUSES.VALIDATION_ERROR:
      color = "volcano";
      break;
    default:
      color = "orange";
      break;
  }

  switch (place) {
    case "contrDiv":
      label = renderContrDivStatusLabel(status);
      break;
    case "contrEmp":
      label = renderContrEmpStatusLabel(status);
      break;
    default:
      label = renderContrStatusLabel(status);
      break;
  }

  return <Tag color={color}>{label.toUpperCase()}</Tag>;
}

export const renderContrFileStatusTag = (row) => {
  const content = (
    <div>
      {Array.isArray(row.error_list) && row.error_list?.length
        ? row.error_list[0].message
        : ""}
    </div>
  );

  return row.error_list?.length ? (
    <Popover content={content} overlayClassName="error-popover">
      <Tag color="volcano">ERROR</Tag>
    </Popover>
  ) : (
    <Tag color="green">SUCCESSFUL</Tag>
  );
};

export const renderLedgerTransactionStatusTag = (status) => {
  let color;

  switch (status?.toLowerCase()) {
    case LEDGER_TRANSACTION_STATUSES.POSTED:
      color = "green";
      break;
    case LEDGER_TRANSACTION_STATUSES.FAILED:
      color = "volcano";
      break;
    default:
      color = "orange";
      break;
  }

  return <Tag color={color}>{status.toUpperCase()}</Tag>;
};

export const renderErrorMessage = (errors) => {
  return errors.map((el) => {
    return (
      <div className="conform-contribution-error-msg ">
        <ExclamationCircleOutlined /> {el.message}
      </div>
    );
  });
};

export const downloadContributionsCsv = (runKey) => {
  const csvString = `${runKey},Tax Year,Employee Contribution,Employer Contribution,Benefits Account Type`;
  const blob = new Blob([csvString]);

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, "New_Contributions.csv");
  } else {
    const a = window.document.createElement("a");

    a.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
    a.target = "_blank";
    a.download = "New_Contributions.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const getReportURL = async (contributionRunID) => {
  const reportInfo = await getContributionReportIDService(contributionRunID);

  if (!reportInfo) return null;

  const reportLink = await getContributionReportLinkService(
    reportInfo.report_id,
  );

  return reportLink.url;
};
