import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Modal } from "antd";
import { useRouter } from "../../../../utils/router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, } from "../../../../design-systems";
import { clearFieldErrors, comparisonValues, formatAddExpenseData, modifiedTimestamp, removeDuplicates } from "../../../../utils";
import { SuccessModal } from "../../../../components/admin/employer";
import { ExpenseDetails } from "./ExpenseDetails";
import { UploadDocument } from "./UploadDocuments";
import Pdf from "../../../../assets/img/pdf-icon.png"
import { ReimbursmentSummary } from "./ReimbursementSummary";
import {
    createExpense,
    evaluateExpenseDraft,
    getExpensesTypes,
    resetExpenseData,
    saveExpenseAsDraft,
    saveUploadedPreSignedUrl,
    updateExpenseDraft,
    uploadDraftExpenseDocument
} from "../../../../redux/actions/expenses.actions";
import imageCompression from 'browser-image-compression'
import { getFileUploadStatus, getFileUploadUrl, showErrorMessage, showSuccessMessage, uploadFileS3 } from "../../../../redux/actions";
import { langs } from "../../../../i18n";

export const CreateExpense = ({ getEmployeeData, open, setOpen, }) => {
    const router = useRouter()
    const errorToastShownRef = useRef(false);
    const id = router.match.params.id;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [spinLoader, setSpinLoader] = useState(false);
    const [action, setAction] = useState("");
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploadStatus, setUploadStatus] = useState({});
    const [intervalId, setIntervalId] = useState(null);
    const [evaluationTriggered, setEvaluationTriggered] = useState(false);
    const [fileTypeError, setFileTypeError] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState(0);
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [changeValues, setChangedValues] = useState({});
    const [allValue, setAllValues] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileListCopy, setFileListCopy] = useState([]);
    const [formData, setFormData] = useState({});
    const [initialValues, setInitialValues] = useState(null);
    const [openSuccess, setSuccessOpen] = useState(false);
    const [shownToast, setShowToast] = useState(false);
    const mileage = Form.useWatch("mileage", form);
    const otherTravelAmount = Form.useWatch("other_travel_amount", form);


    const onFinishFailed = (error) => {
    };
    const { draftExpenseData, expensesTypes, presignedUrl, evaluateData } = useSelector((state) => state.expenses);
    const { expense_draft_id, is_attachments_mandatory } = draftExpenseData || {}
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
        setEvaluationTriggered(false);
        setChangedValues({})
        setInitialValues(form.getFieldsValue())
        setAllValues([])
    };

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                other_travel_expenses: 0,
                mileage: 0,
                display_hsa_radio: "yes"
            })
        }
    }, [form, open])

    const OnAddAnother = () => {
        setOpen(true)
        setSuccessOpen(false)
        form.resetFields()
    }

    const onValuesChange = useCallback(
        (values, allValues) => {
            setChangedValues(((prevData) => ({
                ...prevData,
                ...values
            })))
            setAllValues(allValues)
            clearFieldErrors(form, allValues);
        },
        [form],
    );

    const getKeyPath = (key) => {
        if (key === "mileage" || key === "other_travel_amount") {
            return `/travel_expense_details/${key}`
        }
        return `/${key}`
    }

    const getUpdateExpenseDraftData = (obj) => {
        const result = [];
        if (obj?.expense_type === "Dependent Care") {
            const keys = ["transaction_date"]
            keys.forEach(element => {
                result.push({
                    op: "replace",
                    path: `/${element}`,
                    value: ""
                })
            });
        } else if (initialValues.expense_type === "Dependent Care") {
            const keys = ["start_date", "end_date",]
            keys.forEach(element => {
                result.push({
                    op: "replace",
                    path: `/${element}`,
                    value: ""
                })
            });
        }

        for (const key in obj) {
            let value = obj[key];
            const dateKeys = ["start_date", "end_date", "transaction_date"]
            if (dateKeys.includes(key)) {
                value = modifiedTimestamp(value)
            }
            const travelKeys = ["mileage", "other_travel_amount"]
            if (travelKeys.includes(key)) {
                value = !value ? 0 : value
            }
            result.push({
                op: "replace",
                path: getKeyPath(key, value),
                value
            })
        }
        return removeDuplicates(result);
    }
    const fileNameToUpload = fileList.map(file => file?.name)
    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        const output = await imageCompression(file, options)
        return output;
    }

    const displayHSA = Form.useWatch("display_hsa_radio", form)

    const evaluateApi = () => {
        setLoading(true)
        const apiData = {
            expense_draft_id,
            evaluation_type: "DEFAULT"
        };
        dispatch(evaluateExpenseDraft(id, apiData, (res) => {
            if (!(res instanceof Error)) {
                handleNext(false);
                setLoading(false)
                setUploadedFiles(0)
                setFileList([])
                setEvaluationTriggered(true);
                setChangedValues({})
            } else {
                setLoading(false)
            }
        }));
    }

    useEffect(() => {
        if (currentStep === 2) {
            if (uploadedFiles > 0 && fileList.length > 0 && !evaluationTriggered) {
                if (uploadedFiles === fileList.length && action === "next") { // Check if action is "next"
                    evaluateApi()
                }
            }
        }
    }, [uploadedFiles, fileListCopy, evaluationTriggered]);

    const handleUploadToS3 = async (uploadUrl, file, fileName, data) => {
        if (file) {
            try {
                const response = await fetch(uploadUrl, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        "Content-Type": file.type,
                        "Content-Security-Policy":
                            "connect-src 'self' https://zenda-payroll-non-prod.s3.us-east-2.amazonaws.com/",
                    },
                });
                if (response.ok) {
                    pollUploadStatus(data?.file_id, data?.file_name);
                } else {
                    dispatch(showErrorMessage(langs.messages.file_upload_error));
                }
            } catch (error) {
                setLoading(false)
            }
        }
    };

    const getFileThumbnail = async (file) => {
        if (file.type === "application/pdf") {
            const image = new Image();
            image.src = Pdf;
            const blob = await fetch(image.src).then((res) => res.blob());
            return blob
        } else {
            return compressImage(file.originFileObj)
        }
    }
    const fileMap = {};
    const pollUploadStatus = (fileId, fileName) => {
        const intervalId = setInterval(() => {
            dispatch(getFileUploadStatus(fileId, (res) => {
                if (!(res instanceof Error)) {
                    const { status = "", progress_percentage, message } = res?.data?.data?.data;
                    setUploadProgress((prev) => ({
                        ...prev,
                        [fileName]: { status, progress_percentage },
                    }));

                    if (status !== "FILE_ACCEPTED") {
                        if (message?.length) {
                            dispatch(showErrorMessage(message));
                        }
                    }
                    if (status === "ERRORED_OUT") {
                        clearInterval(intervalId);
                        setLoading(false);
                    }
                    if (status === "FILE_REJECTED") {
                        if(!errorToastShownRef.current) {
                            dispatch(showErrorMessage(langs.validation_messages.file_reject));
                            errorToastShownRef.current = true;
                            setShowToast(true)
                        }
                        clearInterval(intervalId);

                        setLoading(false);
                    }
                    if (progress_percentage === 100 || status === "FILE_ACCEPTED") {
                        clearInterval(intervalId);
                        if (!fileName.includes("thumbnail")) {
                            setUploadedFiles(prevState => prevState + 1)
                        }
                        setLoading(false)
                        setChangedValues({})
                        setUploadStatus((prev) => ({
                            ...prev,
                            [fileName]: (status === 'SUCCESS' || status === 'FILE_ACCEPTED') ? 'success' : 'failed',
                        }));
                        setIntervalId((prev) => {
                            const { [fileName]: _, ...rest } = prev;
                            return rest;
                        });
                    } else if (progress_percentage < 100) {
                        setUploadStatus((prev) => ({
                            ...prev,
                            [fileName]: 'in-progress',
                        }));
                    }
                } else {
                    clearInterval(intervalId);
                    dispatch(showErrorMessage(langs.messages.file_upload_error));
                    setUploadStatus((prev) => ({
                        ...prev,
                        [fileName]: 'failed',
                    }));
                    setIntervalId((prev) => {
                        const { [fileName]: _, ...rest } = prev;
                        return rest;
                    });
                }
            }));
        }, 2000);

        setIntervalId((prev) => ({ ...prev, [fileName]: intervalId }));
    };
    const setFileThumbnailAndApiCall = () => {
        if (fileList?.length) {
            setShowToast(false);
            errorToastShownRef.current = false;
            for (const file of fileList) {
                fileMap[file.name] = file;
            }
            if (fileNameToUpload?.length) {
                setLoading(true)
                const payload = {
                    service: "admin-portal",
                    module: "admin-expense-draft",
                    payload: {
                        expense_draft_id,
                        employee_id: id
                    },
                    file_names: fileNameToUpload
                }
                setLoading(true)
                dispatch(getFileUploadUrl(payload, (res) => {
                    if (!(res instanceof Error)) {
                        dispatch(saveUploadedPreSignedUrl(res?.data?.data?.presigned_url))
                        if (res?.data?.data?.presigned_url?.length) {
                            res?.data?.data?.presigned_url?.forEach(data => {
                                const presignedUrl = data.url;
                                if (presignedUrl) {
                                    handleUploadToS3(data?.url, fileMap[data?.file_name]?.originFileObj, data.file_name, data);
                                }
                            });
                        }
                    } else {
                        setLoading(false)
                    }
                }));
            }
        } else {
            evaluateApi()
        }
    }
    const onFinish = (values) => {
        switch (currentStep) {
            case 1:
                const handleResponse = (res) => {
                    setLoading(false);
                    if (!(res instanceof Error)) {
                        setChangedValues({});
                        if (action === "next" && currentStep < 3) {
                            handleNext();
                        }
                    }
                };
                setLoading(true);
                if (initialValues && expense_draft_id) {
                    if (comparisonValues(initialValues, allValue)) {
                        setLoading(false);
                        handleNext();
                    } else {
                        if (!Object.keys(changeValues)?.length) {
                            handleResponse();
                            return
                        }
                        const apiData = getUpdateExpenseDraftData(changeValues);
                        dispatch(updateExpenseDraft(id, expense_draft_id, apiData, handleResponse));
                    }
                } else {
                    if (expense_draft_id) {
                        handleResponse();
                        return
                    }
                    const apiData = formatAddExpenseData(currentStep, values);
                    dispatch(saveExpenseAsDraft(id, apiData, handleResponse));
                }
                break;
            case 2:
                async function processFiles() {
                    if (is_attachments_mandatory && (!values?.files?.fileList?.length)) {
                        form.setFields([
                            {
                                name: 'files',
                                errors: ["Document is required"],
                            },
                        ]);
                        form.setFieldValue("files", undefined)
                        return;
                    }
                    if ((!fileList?.length && presignedUrl?.length) || !is_attachments_mandatory) {
                        setLoading(true)
                        evaluateApi()
                    }
                    else {
                        setFileThumbnailAndApiCall()
                    }
                }
                processFiles();
                break;

            case 3:
                setLoading(true)
                const data = {
                    "expense_draft_id": expense_draft_id,
                    "evaluation_type": evaluateData?.evaluation_type ?? "",
                    "reimburse_to": values?.to,
                    "is_reimburse_now": false
                }
                dispatch(createExpense(id, data, async (res) => {
                    setLoading(false)
                    if (!(res instanceof Error)) {
                        const claimId = res?.data?.data?.claim_id
                        await dispatch(
                            showSuccessMessage(
                                res?.data?.message || langs.messages.expense_created_successfully
                            ),
                        );
                        getEmployeeData()
                        dispatch(getExpensesTypes(id))
                        resetStateToDefaultValues();
                        setOpen(false);
                        const newTab = window.open(`/admin/claims/details/${claimId}`, '_blank');
                        if (newTab !== null) {
                            newTab.propValue = "addExpense";
                        }
                    }
                }))
        }
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <ExpenseDetails
                    form={form}
                    Form={Form}
                    formData={formData}
                    setFormData={setFormData}
                    expensesTypes={expensesTypes}
                    setChangedValues={setChangedValues}
                />;
            case 2:
                return <UploadDocument
                    id={id}
                    form={form}
                    loading={loading}
                    fileList={fileList}
                    setFileList={setFileList}
                    fileListCopy={fileListCopy}
                    fileTypeError={fileTypeError}
                    uploadProgress={uploadProgress}
                    setUploadProgress={setUploadProgress}
                    setFileListCopy={setFileListCopy}
                    setUploadedFiles={setUploadedFiles}
                    setFileTypeError={setFileTypeError}
                    expense_draft_id={expense_draft_id}
                    is_attachments_mandatory={is_attachments_mandatory}
                />;
            case 3:
                return <ReimbursmentSummary
                    id={id}
                    form={form}
                    setLoading={setLoading}
                    displayHSA={displayHSA}
                    loading={loading}
                    spinLoader={spinLoader}
                    setSpinLoader={setSpinLoader}
                    expense_draft_id={expense_draft_id}
                />;
            default:
                return null;
        }
    };

    const resetStateToDefaultValues = () => {
        setOpen(false)
        setEvaluationTriggered(false);
        setFileTypeError(null);
        setUploadedFiles(0);
        setCurrentStep(1);
        setLoading(false);
        setChangedValues({});
        setFormData({});
        setFileList([]);
        setAllValues([]);
        setFileListCopy([]);
        setInitialValues(null);
        form.resetFields()
    }

    return (
        <>
            <Modal
                visible={open}
                maskClosable={false}
                onCancel={() => {
                    form.resetFields()
                    resetStateToDefaultValues()
                    dispatch(resetExpenseData())
                }}
                title={<Typography variant="subhead-2" label="Create expense" />}
                width={700}
                footer=""
                className="employment-information-modal-wrapper"
            >
                <Form
                    className="employment-information-form"
                    layout="vertical"
                    name="basic"
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="form-wrapper">
                        {renderStep()}
                    </div>
                    <div className="footer-wrapper">
                        {currentStep > 1 && (
                            <Button
                                variant="primary"
                                // htmlType="submit"
                                key="prev"
                                type="primary"
                                label="Previous"
                                disabled={loading}
                                onclickCallback={() => {
                                    setAction("prev")
                                    if (currentStep > 1) {
                                        handlePrev()
                                    }
                                }}
                            />
                        )}
                        {currentStep < 3 && (
                            <>
                                {currentStep === 2 && !is_attachments_mandatory ? (
                                    <Button
                                        variant="primary"
                                        key="skip"
                                        type="primary"
                                        loading={loading}
                                        label="Skip"
                                        onclickCallback={() => {
                                            // handleNext();
                                            setAction("next");
                                            setLoading(true)
                                            evaluateApi()
                                        }}
                                    />
                                ) : (
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                        key="next"
                                        type="primary"
                                        loading={loading}
                                        label={"Next"}
                                        onclickCallback={() => setAction("next")}
                                    />
                                )}
                            </>
                        )}
                        {currentStep === 3 && (
                            <Button
                                variant="primary"
                                htmlType="submit"
                                key="submit"
                                loading={loading}
                                type="primary"
                                disabled={spinLoader}
                                label="Submit"
                                onclickCallback={() => setAction("submit")}
                            />
                        )}
                    </div>

                </Form>
            </Modal>
            <SuccessModal
                page="add-plan"
                form={form}
                onCancel={() => setSuccessOpen(false)}
                open={openSuccess}
                onSecondaryActionClick={() => {
                    setSuccessOpen(false)
                    setFormData({})
                    form.resetFields()
                    setFileTypeError("")
                }}
                onPrimaryActionClick={OnAddAnother}
                primaryLabel="Add more"
                secondaryLabel="Close"
            />
        </>
    )
}