import { Tabs as AntTabs } from "antd";
const TabPane = AntTabs.TabPane;

const renderTitle = ({ title, suffix, prefix }) => {
  return (
    <div className="tab-title-render">
      {prefix ? prefix : ""}
      {title}
      {suffix ? suffix : ""}
    </div>
  );
};

export const Tabs = ({
  defaultActiveKey = "",
  onTabChange,
  tabs = [],
  className,
  postfixTabContent,
  activeKey,
  destroyInactiveTabPane = false
}) => {
  return (
    <AntTabs
    destroyInactiveTabPane={destroyInactiveTabPane}
      defaultActiveKey={defaultActiveKey}
      onChange={onTabChange}
      className={`tabs-wrapper ${className}`}
      activeKey={activeKey}
    >
      {tabs.map((tab) => {
         if (!tab) {
          return null;
        }
    
        const { content, title, key, suffix, prefix } = tab;
        return (
          <TabPane destroyInactiveTabPane={destroyInactiveTabPane} tab={renderTitle({ title, suffix, prefix })} key={key}>
            {content}
          </TabPane>
        )
      }
      )}
      {/* {postfixTabContent} */}
    </AntTabs>
  );
};
