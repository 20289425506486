import { CONTRIBUTION } from "../../utils/constants";

const initialState = {
  benefitAccountTypes: [],
};

export function contribution(state = initialState, action) {
  switch (action.type) {
    case CONTRIBUTION.SET_BENEFIT_ACCOUNT_TYPE:
      return { ...state, benefitAccountTypes: action.payload.plans };

    default:
      return state;
  }
}
