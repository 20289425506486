import { Card, Col, Divider, Form, Row } from "antd";
import { HeaderWithContent } from "../../../components/common/HeaderWithContent";
import { useForm } from "antd/lib/form/Form";
import { clearFieldErrors, renderSteps, steps } from "../../../utils";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CancelModal,
  Footer,
  Header,
  SuccessModal
} from "../../../components/admin/employer";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../utils/router";
import { formatDataForStep } from "../../../utils";
import { addNewEmployer, resetEmployerAddData, resetEmployerApiSuccess } from "../../../redux/actions";

const Add = () => {
  const initialValues = {};
  const [form] = useForm();
  const router = useRouter();
  const formRef = useRef();
  const dispatch = useDispatch()
  const { employerId, success, operationFlag } = useSelector((state) => state.employer)
  const currentStep = +router.match.params.step;
  const [formData, setFormData] = useState({})
  const [employerSteps, setEmployerSteps] = useState(steps);
  const [cancelModalOpened, setCancelModalOpened] = useState(false);
  const [successModalOpened, setSuccessModalOpened] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetEmployerAddData())
      dispatch(resetEmployerApiSuccess())
    }
  }, [])

  useEffect(() => {
    if(currentStep === 1) {
      form.setFieldsValue({
        same_as_corporate: true,
        authorized_for_plan_document: true,
        is_super_admin: true,
        processor: "Galileo",
        fsa_employee_contribution_amount: 0,
        lfsa_employee_contribution_amount: 0,
        dcfsa_employee_contribution_amount: 0,
      })
    }
  }, [router])

  useEffect(() => {
    if (currentStep > 0 && currentStep <= employerSteps.length) {
      setEmployerSteps((prev) => [
        ...prev.map((step, index) => {
          const currentIndex = currentStep - 1;
          const isActive = currentIndex === index;
          const isComplete = currentIndex > index;

          return { ...step, isActive, isComplete };
        }),
      ]);
    } else {
      router.push("/employer");
    }
  }, [currentStep, employerSteps.length, router]);

  const onFinish = useCallback(
    async (values) => {
      let apiData = formatDataForStep(values, currentStep, form)
      apiData.employer_id = currentStep === 1 && !employerId ? null : employerId
      dispatch(addNewEmployer(apiData, 'saveForm', (res) => {
        if (res instanceof Error) {
          if(res?.response?.data?.error?.errored_fields?.length) {
            res?.response?.data?.error?.errored_fields.forEach(err => {
                const simplifiedFieldName = err?.field.replace(/^\./, "").replace(/\./g, "_").toLowerCase();
                form.setFields([
                    {
                        name: simplifiedFieldName,
                        errors: [err?.message],

                    },
                ]);
            })
          }
      }
      }))
    },
    [currentStep, employerSteps.length, dispatch, router, success],
  );
  useEffect(() => {
    if (currentStep < employerSteps.length && success) {
      if (operationFlag === 'saveForm') {
        router.push(`/admin/employer/add/${currentStep + 1}`);
      }
    }
    if (success && currentStep === employerSteps.length) {
      setSuccessModalOpened(true)
    }
  }, [success])
  const onFinishFailed = useCallback((errorInfo) => {
  }, []);

  const onValuesChange = useCallback(
    (_, allValues) => {
      clearFieldErrors(form, allValues);
    },
    [form],
  );

  const onCancel = useCallback(() => {
    setCancelModalOpened(true);
  }, []);

  const onCancelConfirm = useCallback(() => {
    router.push('/admin/employers')
  }, []);

  const onSaveProgress = useCallback(async () => {
    try {
      const values = await form.validateFields();
      let apiData = formatDataForStep(values, currentStep, form)
      apiData.employer_id = employerId
      apiData.is_save_progress = true
      dispatch(addNewEmployer(apiData, 'saveProgress'))
    } catch (err) {
    }
  }, [form, currentStep]);

  const onPrevious = useCallback(() => {
    if (true && currentStep > 1) {
      // if form field valid
      dispatch(resetEmployerApiSuccess())
      router.push(`/admin/employer/add/${currentStep - 1}`);
    }
  }, [currentStep, router]);

  const OnAddAnother = () => {
    form.resetFields();
    setSuccessModalOpened(false)
    dispatch(resetEmployerAddData())
    setFormData({})
    router.push("/admin/employer/add/1");
  }

  const onSeeList = () => {
    form.resetFields();
    setSuccessModalOpened(false)
    setFormData({})
    router.push(`/admin/employers`)
  }

  return (
    <>
      <HeaderWithContent
        customFooter={
          <Footer
            onNext={() => {
              formRef?.current?.submit()
              dispatch(resetEmployerApiSuccess())
            }}
            onCancel={onCancel}
            onPrevious={onPrevious}
            isFirstStep={currentStep === 1}
            isLastStep={currentStep === employerSteps.length}
          />
        }
      >
        <Form
          name="login"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          layout="vertical"
          autoComplete="off"
          validateTrigger="onSubmit"
          ref={formRef}
        >
          <Row gutter={0}>
            <Col span={24}>
              <Card
                title={
                  <Header
                    steps={employerSteps}
                    currentStep={currentStep - 1}
                    nextStep={currentStep}
                    onSaveProgress={onSaveProgress}
                  />
                }
                bordered={false}
                className="add-employer-card"
              >
                <Divider />

                {renderSteps({ form, step: currentStep, formData, setFormData })}
              </Card>
            </Col>
          </Row>
        </Form>
      </HeaderWithContent>
      <CancelModal
        open={cancelModalOpened}
        onCancel={() => setCancelModalOpened(false)}
        onOk={onCancelConfirm}
        page={"add-employer"}
      />

      <SuccessModal
        page="add-employer"
        form={form}
        onCancel={() => setSuccessModalOpened(false)}
        open={successModalOpened}
        onSecondaryActionClick={onSeeList}
        onPrimaryActionClick={OnAddAnother}
        primaryLabel="Add another"
        secondaryLabel="See employer list"
      />
    </>
  );
};

export default Add;
