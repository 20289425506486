import { REPORTS_CONSTANT } from "../../utils/constants";

const initialState = {
    reportsData: {}
}

export function reports(state = initialState, action){
    switch(action.type) {
        case REPORTS_CONSTANT.GET_ALL_REPORTS:        
            return {
                ...state,
                reportsData: action.payload
            }
        default:
            return state;
    }
}