import React from "react";
import { SectionHeader } from "./SectionHeader";
import { Typography } from "../../../../design-systems";
import { FSA_LABELS } from "../../../../utils/constants";
import moment from "moment";
import { Empty } from "antd";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { SpinLoader } from "../../../common/SpinLoader";
import { fixAmountFormat } from "../../../../utils";
export function getFSALabels(data) {
  let label;
  if (FSA_LABELS[data]) label = FSA_LABELS[data];

  return label;
}

export const PlanInfo = ({ data }) => {
  const { copay_configuration, plan_information } = data || {};
  const { runOutOptions } = useSelector(
    (state) => state.employer,
  );

  const getRolloverOrGracePeriod = (plan) => {
    if (Number(plan?.is_rollover_enabled) === 1) {
      return "Rollover"
    } else if (Number(plan?.is_grace_period_enabled) === 1) {
      return "Grace"
    } else {
      return "None"
    }
  }

  const renderPlanInfo = (plan, title) => (
    <div className="column-wrapper-left">
      <Typography
        className={"section-title"}
        variant={"body-2"}
        label={title}
      />
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Minimum contribution"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${fixAmountFormat(plan?.min_employee_contribution_amount)}`}
        />
      </div>
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3 "}
          label={"Maximum contribution"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${fixAmountFormat(plan?.max_employee_contribution_amount)}`}
        />
      </div>
      {/* <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3 "}
          label={"Employer contribution"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${fixAmountFormat(plan?.employer_contribution_amount)}`}
        />
      </div> */}

      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Runout period"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${plan?.run_out_period_in_days} ${Number(plan?.run_out_period_in_days) > 1 ? "days" : "day"}`}
        />
      </div>
      {/* commenting right now. keeping for future need */}
      {/* <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Date of service through"}
        />
        <Typography
          className={"plan-data "}
          variant={"plan-3"}
          label={moment(plan_information?.plan_year?.plan_start_date).format("MMM DD, YYYY")}
        />
      </div> */}
      {
        title === "DCFSA" &&
        <div className="row-wrapper section-margin">
          <Typography
            className={"plan-data-label "}
            variant={"plan-3"}
            label={"Allow claim to be filed with a date of service after termination"}
          />
          <Typography
            className={"plan-data align-right mr-3"}
            variant={"plan-3"}
            label={plan?.allow_claim_file_post_termination_within_service_date === 1 ? "Yes" : "No"}
          />
        </div>
      }
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Runout period for terminated employee"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={`${plan?.run_out_period_in_days} ${Number(plan?.run_out_period_in_days) > 1 ? "days" : "day"}`}
        />
      </div>
      <div className="row-wrapper section-margin">
        <Typography
          className={"plan-data-label "}
          variant={"plan-3"}
          label={"Days following by"}
        />
        <Typography
          className={"plan-data align-right mr-3"}
          variant={"plan-3"}
          label={runOutOptions?.find(opt => opt.value === plan?.terminated_employees_run_out)?.label || "-"}
        />
      </div>
      {
        title !== "DCFSA" &&
        <>
          <div className="row-wrapper section-margin">
            <Typography
              className={"plan-data-label "}
              variant={"plan-3"}
              label={"Rollover or grace period"}
            />
            <Typography
              className={"plan-data align-right mr-3"}
              variant={"plan-3"}
              label={getRolloverOrGracePeriod(plan)}
            />
          </div>
          {
            getRolloverOrGracePeriod(plan) === "Rollover" &&
            <>
              <div className="row-wrapper section-margin">
                <Typography
                  className={"plan-data-label "}
                  variant={"plan-3"}
                  label={"Minimum rollover"}
                />
                <Typography
                  className={"plan-data align-right mr-3"}
                  variant={"plan-3 align-right mr-3"}
                  label={`${fixAmountFormat(plan?.min_rollover_amount)}`}
                />
              </div>
              <div className="row-wrapper section-margin">
                <Typography
                  className={"plan-data-label "}
                  variant={"plan-3"}
                  label={"Maximum rollover"}
                />
                <Typography
                  className={"plan-data align-right mr-3"}
                  variant={"plan-3"}
                  label={`${fixAmountFormat(plan?.max_rollover_amount)}`}
                />
              </div>
            </>
          }
        </>
      }
      {
        getRolloverOrGracePeriod(plan) === "Grace" &&
        <>
          <div className="row-wrapper section-margin">
            <Typography
              className={"plan-data-label"}
              variant={"plan-3"}
              label={"Date of ending grace period"}
            />
            <Typography
              className={"plan-data align-right mr-3"}
              variant={"plan-3"}
              label={moment(plan?.grace_period_end_date).format("MMM DD, YYYY")}
            />
          </div>
        </>
      }
    </div>
  )

  const isHSASelected = useMemo(() => {
    if (plan_information?.selected_plans.length === 1 && plan_information?.selected_plans.includes("HSA")) {
      return true
    }
    return false
  }, [plan_information?.selected_plans]
  )

  return (
    <div className="content">
      <SpinLoader showContent={!!data}>
        <SectionHeader name={"Plan & date"} />
        <div className="plan-date">
          <div className="column-wrapper-left">
            <Typography
              className={"section-title"}
              variant={"body-2"}
              label={"Selected plans"}
            />
            <Typography
              className={"section-data section-margin"}
              variant={"body-3"}
              label={plan_information?.selected_plans?.join(", ")}
            />
          </div>

          <div className="column-wrapper-left">
            <Typography
              className={"section-title"}
              variant={"body-2"}
              label={"Start date"}
            />
            <Typography
              className={"section-data section-margin"}
              variant={"body-3"}
              label={moment(plan_information?.plan_year?.plan_start_date).format("MMM DD, YYYY")}
            />
          </div>
          {
            !isHSASelected &&
            <div className="column-wrapper-left">
              <Typography
                className={"section-title"}
                variant={"body-2"}
                label={"End date"}
              />
              <Typography
                className={"section-data section-margin"}
                variant={"body-3"}
                label={moment(plan_information?.plan_year?.plan_end_date).format("MMM DD, YYYY")}
              />
            </div>
          }
        </div>
        <div className="plan-date header-margin">
          {plan_information?.selected_plans?.includes("FSA") && (
            renderPlanInfo(plan_information?.fsa, "FSA")
          )}
          {plan_information?.selected_plans?.includes("LFSA") && (
            renderPlanInfo(plan_information?.lfsa, "LFSA")
          )}
          {plan_information?.selected_plans?.includes("DCFSA") && (
            renderPlanInfo(plan_information?.dcfsa, "DCFSA")
          )}
        </div>
        {
          !isHSASelected &&
          <>
            <SectionHeader name={"Copays"} />
            <div class="copays">
              {copay_configuration?.map((copay) => (
                <div class="copays-child column-wrapper-left">
                  <Typography
                    className={"section-title copay-label"}
                    variant={"body-2"}
                    label={copay.expense_category}
                  />
                  {copay?.copay_amount?.map((copayData) => (
                    <Typography
                      className={"section-data section-margin copay-data"}
                      variant={"body-3"}
                      label={copayData}
                    />
                  ))}
                </div>
              ))}
              {copay_configuration?.length === 0 &&
                <div className="empty-container">
                  <Empty />
                </div>
              }
            </div>
          </>
        }
      </SpinLoader>
    </div>
  );
};
