import React, { useState } from "react";
import { fixAmountFormat, getExpenseGroupLabel } from "../../../../utils";

import { Empty, Table, Collapse, Spin } from "antd";
import loaderLogo from "../../../../assets/img/incomm-loader.svg"
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { getExpenseGroupDetails } from "../../../../redux/actions";
import { useEffect } from "react";
import { SpinLoader } from "../../../common/SpinLoader";
import { Link } from "react-router-dom";

function ExpensesSectionV2({ data, zan }) {
  const { Panel } = Collapse;
  const dispatch = useDispatch()
  const [activeKey, setActiveKey] = useState([]);
  const [expenseData, setExpenseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedPanelKey, setExpandedPanelKey] = useState("")
  const [expenses, setExpenses] = useState({
    "TAKE_ACTION": [],
    "SETTLED": [],
    "CANCELLED": [],
    "AWAITING_REVIEW": [],
    "DENIED": [],
    "REIMBURSE": []
  })

  const handleClaimClick = (e, id) => {
    e.preventDefault(); // Prevent default link behavior
    const newTab = window.open(`/admin/claims/details/${id}`, '_blank');
    if (newTab !== null) {
      newTab.propValue = "accountHolder";
    }
  }

  const renderClaimIDLink = (id) => {
    if (!id) return ""
    return <Link
      to={{ pathname: `/admin/claims/details/${id}` }}
      target="_blank"
      onClick={(e) => handleClaimClick(e, id)}
    >
      {id}
    </Link>
  }

  const columns = [
    {
      title: "Expense ID",
      dataIndex: "expense_id",
    },
    {
      title: "Claim ID",
      dataIndex: "claim_id",
      render: (id) => renderClaimIDLink(id)
    },
    {
      title: "Claim status",
      dataIndex: "claim_status",
    },
    {
      title: "Expense amount",
      dataIndex: "expense_amount",
      render: (amount) => fixAmountFormat(amount),
      align: "right"
    },
    {
      title: "Merchant",
      dataIndex: "merchant",
    },
    {
      title: "Transaction date",
      dataIndex: "updated_on",
      render: (date) => date ? moment(date).format("MM/DD/YYYY") : null
    },
  ]

  useEffect(() => {
    if (data !== null) {
      const filteredExpensesCount = Object.fromEntries(
        Object.entries(data)?.filter(([key, value]) => value !== 0)
      );
      setExpenseData(filteredExpensesCount)
    }
  }, [data])

  const handlePanelChange = async (key) => {
    setLoading(true);
    const expandedPanelKey = key.find((k) => !activeKey.includes(k));
    setExpandedPanelKey(expandedPanelKey);
    if (expandedPanelKey) {
      const apiData = {
        zan: zan,
        expense_group: expandedPanelKey
      }
      dispatch(getExpenseGroupDetails(apiData, (res => {
        if (!(res instanceof Error)) {
          setLoading(false)
          const exp = { ...expenses };
          exp[expandedPanelKey] = res
          setExpenses(exp)
        }
      })))
    }

    setActiveKey(key);
  };

  const customLoader = (
    <>
      <Spin
        spinning={loading}
        tip={<img src={loaderLogo} alt="Incomm Benefits-logo" className="loader-logo" />}
        className="page-loader-expense"
        size="large"
      >
      </Spin>
    </>
  )
  if (
    !expenseData || !Object.keys(expenseData)?.length
  ) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <>
        <div className="expense-section-wrapper">
          <SpinLoader>
            <Collapse
              activeKey={activeKey}
              onChange={handlePanelChange}
              className="account-holder mb-15"
            >
              {Object.keys(expenseData)?.length > 0 && Object.keys(expenseData)?.map(key => (
                <Panel header={`${getExpenseGroupLabel[key]} (# Expenses: ${Number(data[key])})`} key={key}>
                  <Table
                    bordered
                    columns={columns}
                    dataSource={expenses[key]}
                    pagination={false}
                    scroll={
                      data?.length ? { x: 1200 } : undefined
                    }
                    loading={
                      {
                        indicator: customLoader,
                        spinning: loading && key === expandedPanelKey
                      }
                    }
                    tableLayout="auto"
                    className="compact-table mb-15 expenses-section-table"
                  />
                </Panel>
              ))}
            </Collapse>
          </SpinLoader>
        </div>
      </>
    );
  }
}

export { ExpensesSectionV2 };
