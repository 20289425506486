import { getMergedCopaysCategories } from "../../utils";
import { EMPLOYER_CONSTANT } from "../../utils/constants";

const initialState = {
  switchType: [],
  employerType: [],
  employerLedger: {},
  employerDivisions: [],
  currentEmployer: null,
  contributionIdentifiers: [],
  runOutOptions: [],
  copaysCategories: [],
  employerId: "",
  success: false,
  showAdditionalContact: false,
  sameAsCorpAddress: false,
  operationFlag: '',
  employerDetails: {},
  currentEmployerDetails: {
    "biz-info": {},
    "plan-info": {},
    "bank-info": {},
    "admin-users-info": {}
  },
  showSection2: false,
  employerPlanInfo: {}
};

export function employer(state = initialState, action) {
  switch (action.type) {
    case EMPLOYER_CONSTANT.SET_EMPLOYER_TYPE:
      return { ...state, employerType: action.payload };
    case EMPLOYER_CONSTANT.SET_SWITCH_EMPLOYER_TYPE:
      return { ...state, switchType: action.payload };
    case EMPLOYER_CONSTANT.SET_CURRENT_EMPLOYER_TYPE:
      return { ...state, currentEmployer: action.payload };
    case EMPLOYER_CONSTANT.SET_EMPLOYER_LEDGER:
      return { ...state, employerLedger: action.payload };
    case EMPLOYER_CONSTANT.SET_EMPLOYER_DIVISIONS:
      return { ...state, employerDivisions: action.payload };
    case EMPLOYER_CONSTANT.SET_EMPLOYEE_CONTRIBUTION_IDENTIFIERS:
      return { ...state, contributionIdentifiers: action.payload };
    case EMPLOYER_CONSTANT.SET_EMPLOYEE_RUNOUT_OPTIONS:
      return { ...state, runOutOptions: action.payload }
    case EMPLOYER_CONSTANT.SET_EMPLOYEE_COPAYS_CATEGORIES:
      return { ...state, copaysCategories: action.payload }
    case EMPLOYER_CONSTANT.SET_EMPLOYER_ID:
      return { ...state, employerId: action.payload }
    case EMPLOYER_CONSTANT.SET_EMPLOYER_CREATE_SUCCESS:
      return { ...state, success: true, operationFlag: action.payload }
    case EMPLOYER_CONSTANT.SET_EMPLOYER_CREATE_ERROR:
      return { ...state, success: false }
    case EMPLOYER_CONSTANT.SET_EMPLOYER_DATA:
      return { ...state, employerDetails: action.payload }
    case EMPLOYER_CONSTANT.RESET_EMPLOYER_DATA:
      return {
        ...state,
        success: false
      }
    case EMPLOYER_CONSTANT.SET_SAME_AS_CORPORATE_ADDRESS:
      return {
        ...state,
        sameAsCorpAddress: action.payload
      }
    case EMPLOYER_CONSTANT.SET_SHOW_ADDITIONAL_CONTACT:
      return { ...state, showAdditionalContact: action.payload, }
    case EMPLOYER_CONSTANT.SET_PRIMARY_CONTACT_INFO:
      return {
        ...state,
        [action.payload.checkboxName]: action.payload.value,
      };
    case EMPLOYER_CONSTANT.SET_EMPLOYER_DATA_BY_TAB:
      const { tabName, payload } = action;
      const updatedEmployerDetails = { ...state.currentEmployerDetails };
      if (tabName === "plan-info") {
        let data = { ...payload }
        const { copay_configuration } = data || {}
        const mergedArray = getMergedCopaysCategories(copay_configuration)
        updatedEmployerDetails[tabName] = { ...payload, copay_configuration: mergedArray };
      } else if (tabName === "admin-users-info") {
        let data = { ...payload }
        const { administrative_users } = data || {}
        const updatedAdminUsers = administrative_users.map((user, index) => ({
          ...user,
          key: index
        }))
        updatedEmployerDetails[tabName] = { ...payload, administrative_users: updatedAdminUsers }
      }
      else {
        updatedEmployerDetails[tabName] = payload;
      }
      return {
        ...state,
        currentEmployerDetails: updatedEmployerDetails
      };
    case EMPLOYER_CONSTANT.SET_EMPLOYER_INFO:
      return {
        ...state,
        employerPlanInfo: action.payload
      }
    case EMPLOYER_CONSTANT.SET_SHOW_SECTION2:
      return {
        ...state,
        showSection2: action.payload
      }
    case EMPLOYER_CONSTANT.RESET_CURRENT_EMPLOYER_DATA:
      return {
        ...state,
        currentEmployerDetails: {
          "biz-info": {},
          "plan-info": {},
          "bank-info": {},
          "admin-users-info": {}
        }
      }
    case EMPLOYER_CONSTANT.RESET_EMPLOYER_ADD_DATA:
      return {
        ...state,
        contributionIdentifiers: [],
        runOutOptions: [],
        copaysCategories: [],
        employerId: "",
        success: false,
        showAdditionalContact: false,
        sameAsCorpAddress: false,
        operationFlag: '',
      }
    default:
      return state;
  }
}