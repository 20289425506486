import React from "react";
import {
  ContributionProgress,
  ContributionRunHistory,
  ContributionValidationReport,
  CreateContribution,
} from "../../pages/admin/contributions";
import { DashBoard } from "../../pages/admin/dashboard";
import {
  AddEmployee,
  EditEmployeeFromFile,
  EmployeeList,
  Employees,
  UploadNewEmployeeCensusFile,
  VerificationEmployeeCensusFile,
  List as EmployeeListV2,
} from "../../pages/admin/employee";
import { Reports } from "../../pages/admin/reports";
import { Search } from "../../pages/admin/search";
import {
  AccountActivity,
  AddNewUser,
  Banking,
  EmployeeDetail,
  Employer,
  UserDetails,
  UsersList,
} from "../../pages/admin/settings";
import Add from "../../pages/admin/employer/Add";
import { ViewEmployer, EmployerList } from "../../components/admin/employer";
import { List as ClaimList, View as ClaimView } from "../../pages/admin/claims";
import { EmployeeDetails } from "../../pages/admin/employee/EmployeeDetails";
import { EditEmployeeCensusUpload } from "../../pages/admin/employee/EditEmployeeCensusUpload";
import { ReportsList } from "../../pages/admin/reports/v2/Reports";
import AddNewEmployee from "../../pages/admin/employee/AddEmployee/AddEmployee";

export const adminRoutes = [
  {
    path: "/",
    render: () => <DashBoard />,
    exact: true,
  },
  {
    path: "/admin/employer-detail",
    render: () => <ViewEmployer />,
    exact: true,
  },
  {
    path: "/admin/employers",
    render: () => <EmployerList />,
    exact: true,
  },

  {
    path: "/admin/dashboard",
    render: () => <DashBoard />,
    exact: true,
  },
  {
    path: "/admin/employees/verification-census-file/:id",
    render: () => <VerificationEmployeeCensusFile />,
  },
  {
    path: "/admin/employees/upload-census-file",
    render: () => <UploadNewEmployeeCensusFile />,
  },
  {
    path: "/admin/employees/employee-list",
    render: () => <EmployeeList />,
  },
  {
    path: "/admin/employees/edit-employee",
    render: () => <EditEmployeeCensusUpload />
  },
  {
    path: "/admin/employees",
    render: () => <EmployeeListV2 />,
  },
  {
    path: "/admin/employee-claims/:id",
    render: () => <ClaimView page="employee-claims" />,
  },
  {
    path: "/admin/add-employee",
    render: () => <AddEmployee />,
  },
  {
    path: "/admin/settings/banking",
    render: () => <Banking />,
  },
  {
    path: "/admin/settings/users-list",
    render: () => <UsersList />,
  },
  {
    path: "/admin/settings/user-detail/new-user",
    render: () => <AddNewUser />,
  },
  {
    path: "/admin/settings/user-detail/:email",
    render: () => <UserDetails />,
  },
  {
    path: "/admin/profile",
    render: () => <UserDetails />,
  },
  {
    path: "/admin/settings/employer",
    render: () => <Employer />,
  },
  // {
  //   path: "/admin/employee-detail/:id",
  //   render: () => <EmployeeDetail />,
  // },
  {
    path: "/admin/employee-detail/:id",
    render: () => <EmployeeDetails />,
  },
  {
    path: "/admin/employer/ledger",
    render: () => <AccountActivity />,
  },
  {
    path: "/admin/contributions/run-history",
    render: () => <ContributionRunHistory />,
  },
  {
    path: "/admin/create-contribution",
    render: () => <CreateContribution />,
  },
  {
    path: "/admin/contribution-progress/:id",
    render: () => <ContributionProgress />,
  },
  {
    path: "/admin/contribution-validation-report/:id",
    render: () => <ContributionValidationReport />,
  },
  {
    path: "/admin/update-contribution/:id",
    render: () => <CreateContribution />,
  },
  // {
  //   path: "/admin/reports",
  //   render: () => <Reports />,
  // },
  {
    path: "/admin/reports/:id/:type",
    render: () => <ReportsList />,
  },
  {
    path: "/admin/search",
    render: () => <Search />,
  },
  {
    path: "/admin/employer/add/:step",
    render: () => <Add />,
  },
  {
    path: "/admin/employee/add/:step",
    render: () => <AddNewEmployee />,
  },
  {
    path: "/admin/claims/details/:id",
    render: () => <ClaimView page="claims" />,
  },
  {
    path: "/admin/claims",
    render: () => <ClaimList />,
  },
  {
    path: "/admin/employees",
    render: () => <EmployeeListV2 />,
  },
];
