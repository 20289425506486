export const UPLOADED_FILE_STATUSES = {
  SUCCESS: "success",
  CANCELLED: "cancelled",
  VALIDATION_IN_PROGRESS: "validation_in_progress",
  VALIDATION_ERROR: "validation_error",
};

export const UPLOADED_FILE_EMPLOYEE_STATUSES = {
  SUCCESS: "success",
  ALREADY_EXIST: "already-exists",
  ERROR: "error",
};

export const PLAN_STATUSES = {
  ACTIVE: "active",
  SUSPENDED_CARD_ONLY: "suspended - card only",
};

export const mfaActionsMap = {
  DISABLED: "disable",
  ENABLED: "enable",
  PROMPT_TEMP_SKIPPED: "prompt_temp_skipped",
  PROMPT_PERM_SKIPPED: "prompt_perm_skipped",
  prompt_perm_skipped: "prompt_perm_skipped",
  prompt_temp_skipped: "prompt_temp_skipped",
  enable: "enable",
  disable: "disable",
  enabled: "enable",
  disabled: "disable"
}