// EXPENSES_CONSTANT.GET_EXPENSES_TYPES

import { EXPENSES_CONSTANT } from "../../utils/constants"

const initialState = {
    expensesTypes: [],
    draftExpenseData: {},
    presignedUrl: [],
    evaluateData: null
}


export function expenses(state = initialState, action) {
    switch (action.type) {
        case EXPENSES_CONSTANT.GET_EXPENSES_TYPES:
            return { ...state, expensesTypes: action.payload }
        case EXPENSES_CONSTANT.SAVE_EXPENSES_AS_DRAFT:
            return { ...state, draftExpenseData: action.payload }
        case EXPENSES_CONSTANT.SAVE_UPLOADED_PRESIGNED_URL:
            const url = state.presignedUrl;
            return { ...state, presignedUrl: [...url, ...action.payload]}
        case EXPENSES_CONSTANT.RESET_EXPENSE_DATA:
            return {
                ...state,
                draftExpenseData: {},
                presignedUrl: [],
                evaluateData: null
            }
        case EXPENSES_CONSTANT.UPDATE_SAVED_PRESIGNED_URL:
            let presignedUrl = [...state.presignedUrl];
            presignedUrl = presignedUrl.filter(url => !url?.file_name?.includes(action?.payload))
            return { ...state, presignedUrl: presignedUrl }
        case EXPENSES_CONSTANT.SAVE_EVALUATE_EXPENSE_DATA:
            return { ...state, evaluateData: action.payload }
        default:
            return state
    }
}