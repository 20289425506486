import React, { useMemo, useState } from 'react';
import { CheckBox } from '../../../../../../design-systems';
import { addNewPlanSteps } from '../../../../../../utils';
import { Collapse as AntCollapse } from "antd";
import { Collapse } from "../../../../../../design-systems";
const { Panel } = AntCollapse;
export const NewPlanSteps = ({ plans, form, planInfo, formData, setFormData, selectedCheckboxes, setSelectedCheckboxes }) => {
    const [expandedPanels, setExpandedPanels] = useState([]);

    const filteredPlanInformationStep = useMemo(() => {
        return addNewPlanSteps.filter(
            ({ planKey }) => !planKey || (planKey && plans?.includes(planKey)),
        );
    }, [plans]);

    const onChange = (key) => {
        setExpandedPanels(key);
    };

    const clearUncheckedCheckboxData = (uncheckedPlan) => {
        const updatedFormData = { ...formData };
        updatedFormData[uncheckedPlan] = null;
        updatedFormData[`${uncheckedPlan}_plan_effective_date`] = null;
        updatedFormData[`${uncheckedPlan}_plan_year`] = null;
        updatedFormData[`${uncheckedPlan}_employee_amount`] = null;
        updatedFormData[`${uncheckedPlan}_employer_amount`] = null;
        updatedFormData[`${uncheckedPlan}_plan_config_id`] = null;
        if (uncheckedPlan === "hsa") {
            updatedFormData['hsa_coverage_type'] = null;
        }
        form.setFieldsValue(updatedFormData);
        setFormData(updatedFormData);
        form.setFields([
            `${uncheckedPlan}_plan_effective_date`,
            `${uncheckedPlan}_plan_year`,
            `${uncheckedPlan}_employee_amount`,
            `${uncheckedPlan}_employer_amount`,
            uncheckedPlan === "hsa" ? "hsa_coverage_type" : null
        ].map(field => ({ name: field, errors: [] })));
    };

    const handleCheckBoxClick = (key, event) => {
        const updatedPanels = Array.isArray(expandedPanels) ? expandedPanels : [];
        const updatedCheckboxes = [...selectedCheckboxes]
        const index = updatedPanels?.indexOf(key);
        const checkboxIndex = updatedCheckboxes?.indexOf(key);
        if (event.target.checked) {
            if (index === -1) {
                updatedPanels.push(key)
            }
            if (checkboxIndex === -1) {
                updatedCheckboxes.push(key)
            }
        } else {
            form.setFields([{
                name: key.toLowerCase(),
                errors: [],
            }]);
            if (index !== -1) {
                updatedPanels?.splice(index, 1);
                clearUncheckedCheckboxData(key.toLowerCase());
            }
            if (checkboxIndex !== -1) {
                updatedCheckboxes?.splice(checkboxIndex, 1);
            }
        }
        setExpandedPanels([...updatedPanels]);
        setSelectedCheckboxes([...updatedCheckboxes]);
        form.setFieldsValue({
            plans: [...updatedPanels]
        })
    };

    return (
        <Collapse
            activeKey={expandedPanels}
            expandIconPosition={"end"}
            accordion={false}
            onChange={onChange}
            className="add-plans-collapse"
            panels={filteredPlanInformationStep.map(({ content, title, key, }) => {
                return (
                    <Panel
                        header={<CheckBox
                            onChange={(event) => handleCheckBoxClick(key, event)}
                            label={key}
                            name={title}
                        />
                        }
                        showArrow={false} // Hide the default arrow icon
                        key={key}>
                        <div onClick={(e) => e.stopPropagation()}>
                            {content({ form, formData, planInfo, setFormData, isInputRequired: selectedCheckboxes?.includes(key) })}
                        </div>
                    </Panel>
                )
            }
            )}
        />
    )
};