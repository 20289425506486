import React from "react";

import { Col, Empty, Row, Table } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../../utils/constants";

function KYCSection({ data }) {
  const renderSocureResp = (resp) => {
    if (!resp) {
      return ("")
    }
    return (
      <pre className="socure-resp custom-scroll">
        {resp ? JSON.stringify(resp, null, 2) : ""}
      </pre>
    )

  }
  const KYCColumns = [
    {
      title: "Reference id",
      dataIndex: "reference_id",
    },
    {
      title: "Name",
      dataIndex: "",
      render: (record) =>
        `${record?.first_name && record.first_name} ${record?.last_name && record.last_name
        }`,
    },
    {
      title: "Decision",
      dataIndex: "decision",
    },
    {
      title: "KYC type",
      dataIndex: "kyc_type",
    },
    {
      title: "Processing time",
      dataIndex: "created_timestamp",
      render: (date) => moment(date).format(DATE_FORMAT.FOR_UI_DATE_TIME)
    },
    {
      title: "KYC response",
      dataIndex: "socure_response",
      width: "100px",
      render: (socureResponse) => renderSocureResp(socureResponse),
    },
  ];

  if (!data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Table
        bordered
        columns={KYCColumns}
        dataSource={data}
        pagination={false}
        scroll={data?.length ? { x: 1200 } : undefined}
        className="compact-table"
      />
    );
  }
}

export { KYCSection };
